import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IUnidadTransporteCompleto } from '../models/unidadtransportecompleto';
import { IUnidadTransporte } from '../models/unidadTransporte';
import { IDataEstatus } from '../models/dataEstatus';
import { IUnidadesEstatus } from '../models/rpt_unidades_estatus';
import { IDataHerramientas } from '../models/data_herramientas';
import { IUnidadTransporteHistorico } from '../models/unidad_transporte_historico';
@Injectable({
  providedIn: 'root'
})
export class UnidadService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Unidad';
  }

  getListadoTodasLasUnidades(num_opcion: number): Observable<IUnidadTransporteCompleto[]> {
    return this._http
      .get<IUnidadTransporteCompleto[]>(this.apiUrl + '/GetListadoTodas/' + num_opcion)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoTodasLasUnidadesTDO(num_opcion: number,num_mes: number,num_anio:number): Observable<IUnidadesEstatus[]> {
    const valores = {
      params: new HttpParams().
        set('num_opcion', num_opcion).
        set('num_mes', num_mes).
        set('num_anio', num_anio)
    };
    return this._http
      .get<IUnidadesEstatus[]>(this.apiUrl + '/GetListadoTodasEstatus',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHistorico(num_opcion: number,num_anio:number,num_mes:number): Observable<IUnidadTransporteHistorico[]> {
    const valores = {
      params: new HttpParams().
        set('num_opcion', num_opcion).
        set('num_anio', num_anio).
        set('num_mes', num_mes)
    };
    return this._http
      .get<IUnidadTransporteHistorico[]>(this.apiUrl + '/GetListadoHistorico',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public editUnidad(data: IUnidadTransporte ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl + '/updateUnidad/',body,{'headers':headers});
   }

   public editUnidadEstatus(data: IUnidadTransporte ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl + '/updateUnidadStd/',body,{'headers':headers});
   }

   public saveUnidad(data: IUnidadTransporte ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/saveUnidad/',body,{'headers':headers});
   }


  getExisteNumeroEconomico(numero_economico: string): Observable<boolean> {
    return this._http
      .get<boolean>(this.apiUrl + '/getExisteNumeroEconomico/' + numero_economico)
      .pipe(retry(1), catchError(this.handleError));
  }

  public editUnidadGestionEstatus(data: IDataEstatus ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.put<any>(this.apiUrl + '/updateUnidadSubStd',body,{'headers':headers});
   }

   public editUnidadGestionHerramientas(data: IDataHerramientas):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/saveCardRecursos',body,{'headers':headers});
   }

  getrptListadoTodasLasUnidadesGeneral(): Observable<any> {
  return this._http
    .get<any>(this.apiUrl + '/GetrptListadoTodas/')
    .pipe(retry(1), catchError(this.handleError));
  }

  getRptDiasPrestadosUnidadesFechas(fecha: string,fecha_fin: string): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this._http
      .get<any>(this.apiUrl + '/GetrptDiasPrestadosRangoFechas',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getRptTotalServiciosDetalladoFechas(fecha: string,fecha_fin: string): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this._http
      .get<any>(this.apiUrl + '/GetrptTaseaDeOcupacionDetallado',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getRptHistoricoEstatusUnidadesFechas(fecha: string,fecha_fin: string): Observable<any> {
    const valores = {
      params: new HttpParams().
        set('num_opcion',1).
        set('fecha', fecha).
        set('fecha_fin', fecha_fin)
    };
    return this._http
      .get<any>(this.apiUrl + '/GetrptHistoricoEstatusRangoFechas/',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
