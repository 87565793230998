import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IRuta } from '../models/ruta';
import { RutaService } from '../services/ruta.service';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ClienteService } from '../services/cliente.service';
import { CiudadService } from '../services/ciudad.service';
import { ProyectoService } from '../services/proyecto.service';
import { TipotransporteService } from '../services/tipotransporte.service';
import { IProyecto } from '../models/proyecto';
import { ICiudad } from '../models/ciudad';
import { ICliente } from '../models/cliente';
import { ITipoTransporte } from '../models/tipotransporte';
import { IResultadoGeneral } from '../models/resultadogeneral';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
import { ISituacion } from '../models/situacion';
import { ConfunidadService } from '../services/confunidad.service';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { IRutaEspecial } from '../models/ruta_especial';
import { IDataGuardarFolio } from '../models/data_folio';
import { IMes } from '../models/mes';
import { IProveedor } from '../models/proveedor';
import { IEstado } from '../models/estado';
import { ITipoDiasTrabajados } from '../models/tipoDiasTrabajados';
import { IMetodoPagoProveedor } from '../models/metodoPagoProveedor';
import { ITipoRenta } from '../models/tipoRenta';
import { IAdmProveedor } from '../models/admProveedor';
import { AdminProveedoresService } from '../services/admproveedores.service';
import { CostounidadService } from '../services/costounidad.service';
import { EstadosService } from '../services/estados.service';
import { IDataHerramientas } from '../models/data_herramientas';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admprovedores',
  templateUrl: './admprovedores.component.html',
  styleUrls: ['./admprovedores.component.scss']
})
export class AdmprovedoresComponent implements OnInit{
  @ViewChild('dt1') dt!: Table;
  lstProveedores: IProveedor[] = [];
  dspAddEditProveedor: boolean = false;
  fecha_hoy: any;
  headerText: string = '';
  loading: boolean = false;
  num_estado_filter: number = 0;
  provEdit: boolean = false;
  loading_reporte1: boolean = false;
  ButtonExportExcel: boolean = false;
  num_estado_reporte: number = 0;
  dbsReportes: boolean = false;
  proveedorName: string = '';
  provRazonSocial: string = '';
  

  provForm: FormGroup = new FormGroup({
    clave_Proveedor: new FormControl(0),
    nom_proveedor: new FormControl('', [Validators.required]),
    desc_razon_social: new FormControl('', [Validators.required]),
    num_estado: new FormControl(0, [Validators.required]),
    id_tipo_renta: new FormControl(0, [Validators.required]),
    id_dias_trabajados: new FormControl(0, [Validators.required]),
    clv_kim_adicionales: new FormControl(0, [Validators.required]),
    imp_kim_adicional: new FormControl(0, [Validators.required]),
    id_tipo_pago_proveedor: new FormControl(0, [Validators.required])
  });

  lstEstados: IEstado[] = [{clave_Estado: '0', nombre: 'Todos Los Estados',fk_clave_Pais:''}];
  lstEstados_Filter: IEstado[] = [];
  lstTipoPago: IMetodoPagoProveedor[] = []
  //   { idx:1,nombre:'Broxel'}
  //   ,{idx:2,nombre:'Transferencia'}
  // ];
  lstDiasTrabajados: ITipoDiasTrabajados[] = []
  //   {idx:1,nombre:'Mensual'}
  //   ,{idx:2,nombre:'Lunes a Sábado'}    
  //   ,{idx:3,nombre:'Lunes a Domingo'}
  // ];
  lstTipoRenta: ITipoRenta[] = []
  //   {idx: 1,nombre: 'Mensual'}
  //   ,{idx: 2,nombre: 'Diario'}
  // ];
  lstKimAdicional: ISituacion[] = [
    { id: 0, nombre: 'NO' }
    ,{ id: 1, nombre: 'SI' }
  ];
  lstEstatus: ISituacion[] = [
    { id: 0, nombre: 'INACTIVO' }
    ,{ id: 1, nombre: 'ACTIVO' }
  ];

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  }
 
  /*Colores*/
  color_rojo_especial: string = '#FF0000';
  color_gris_deshabilitadas: string = '#D9D9D9';
  
  user: ICredencialUsuario = {};

  dadmproveedor: boolean = false;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private ciudadService: CiudadService,
    private admProveedoresService: AdminProveedoresService,
    private cstUnidadService: CostounidadService,
    private estadosService: EstadosService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.inicializarFechas();
    
  }
  ngOnInit(): void {
    this.getEstados();
    this.getListaDeTipoRenta();
    this.getListaTipoDiasTrabajados();
    this.getListadoTipoPago();
  }

  changedpclvKim(event: any)
  {
      if (event.value == 1 && this.provForm.controls['clv_kim_adicional']?.value != event.value) {
        this.provForm.controls['imp_kim_adicional'].enable();
      } else if (event.value == 0 && this.provForm.controls['clv_kim_adicional']?.value != event.value){
        this.provForm.controls['imp_kim_adicional'].disable();
        this.provForm.controls['imp_kim_adicional'].setValue(0);
      }   
  }

  changedpIdRenta(event: any)
  {
    if (event.value == 1 ) {
      this.provForm.controls['id_dias_trabajados'].setValue(1);
      this.provForm.controls['id_dias_trabajados'].disable();
    } else{
      this.provForm.controls['id_dias_trabajados'].enable();
      this.provForm.controls['id_dias_trabajados'].setValue(2)
    }    
  }
  resetAdmnProveedor(): IAdmProveedor
  {
    let data_enviar: IAdmProveedor = 
    {
      clave_Proveedor: 0,
      nombre: '',
      fecha_InicioContrato: new Date(1900,1,1),
      fecha_FinContrato: new Date(1900,1,1),
      fk_clave_Estado: '',
      desc_razon_social: '',
      id_tipo_renta: 0,
      id_dias_trabajados: 0,
      clv_kim_adicional: 0,
      imp_kim_adicional: 0,
      id_metodo_pago: 0,
      cod_usuario: '',
    }
    return data_enviar
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }
  getListadoTipoPago() {
    this.cstUnidadService.getListadoTipoPago().subscribe((resp) => {
      this.lstTipoPago = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Conf. de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeTipoRenta() {
    this.cstUnidadService.getListadoTipoRenta().subscribe((resp) => {
      this.lstTipoRenta = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaTipoDiasTrabajados() {
    this.cstUnidadService.getListadoTipoDiasTrabajados().subscribe((resp) => {
      this.lstDiasTrabajados = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getEstados() {
    //this.lstEstados = [];
    this.lstEstados_Filter = [];
    let estados: IEstado[] = [];
    this.estadosService.getListEstados().subscribe((resp) => {
      this.lstEstados_Filter = resp;
      this.lstEstados_Filter.map(x => this.lstEstados.push(x));
      //this.lstEstados_Filter.push({clave_Estado: '0', nombre: 'Todos Los Estados',fk_clave_Pais:''})
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Clientes>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  getListaDeProveedores() {
    this.lstProveedores = [];
    this.loading = true;
    this.num_estado_filter = this.num_estado_filter == null ? 0: this.num_estado_filter;
    this.admProveedoresService.getLstProveedores(this.num_estado_filter).subscribe((resp) => {
      this.lstProveedores = resp;
      setTimeout(() => {
        this.loading = false;
      },
        800);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Proveedores');
        this.loading = false;
      });
  }

  /*redireccionarNueva(num_operacion:number) {
    this.num_operacion=num_operacion;
  }*/

  redireccionarNueva() {
    this.router.navigateByUrl("/prove");
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  //metodo para validaar si existe
  //-------------hacer un get que reciba la info  o implemntarlo al  que esta ctualmente
  deshabilitarTodos(){}

  addProveedor(){
    this.resetearFormulario();
    this.dspAddEditProveedor = true;
    this.headerText = 'Agregar Proveedor';
    this.proveedorName = '';
    this.provRazonSocial = '';
    this.provForm.controls['clave_Proveedor'].setValue(0);
    this.provForm.controls['id_tipo_renta'].setValue(1);
    this.provForm.controls['id_dias_trabajados'].disable();
    this.provForm.controls['id_dias_trabajados'].setValue(1);
    // this.provForm.controls['nom_proveedor'].setValue('');
    // this.provForm.controls['desc_razon_social'].setValue(this.anio);
    // this.provForm.controls['num_estado'].setValue(ruta.clave_ruta);
    // this.provForm.controls['id_tipo_renta'].setValue('');
    // this.provForm.controls['id_dias_trabajados'].setValue('');
    // this.provForm.controls['clv_kim_adicionales'].setValue('');
    // this.provForm.controls['imp_kim_adicional'].setValue('');
    // this.provForm.controls['id_tipo_pago_proveedor'].setValue('');
  }

  editProveedor(data: IProveedor){
    this.resetearFormulario();
    this.provEdit = true;
    this.dspAddEditProveedor = true;
    this.headerText = 'Editar Proveedor';
    this.proveedorName = data.nombre;
    this.provRazonSocial = data.desc_razon_social;
    this.provForm.controls['clave_Proveedor'].setValue(data.clave_Proveedor);
    this.provForm.controls['nom_proveedor'].setValue(data.nombre);
    this.provForm.controls['desc_razon_social'].setValue(data.desc_razon_social);
    this.provForm.controls['num_estado'].setValue(data.fk_clave_Estado);
    this.provForm.controls['id_tipo_renta'].setValue(data.id_tipo_renta);
    this.provForm.controls['id_dias_trabajados'].setValue(data.id_dias_trabajados);
    this.provForm.controls['clv_kim_adicionales'].setValue(data.clv_kim_adicional);
    this.provForm.controls['id_tipo_pago_proveedor'].setValue(data.id_metodo_pago);
    this.provForm.controls['imp_kim_adicional'].setValue(data.imp_kim_adicional);

    // if(data.clv_kim_adicional == 1)
    // {
    //   this.provForm.controls['imp_kim_adicional'].enable();
      
    // }
    // else 
    //   this.provForm.controls['imp_kim_adicional'].disable();
    
  }

  confirmarDesactivar(clave_Proveedor: number,prov: IProveedor) {
    // let estatus_original = ruta.clv_disponible;
    let texto_desactivar: string = prov.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = prov.clv_activo == 1 ? 'Desactivar' : 'Activar';
    let data_enviar: IAdmProveedor = this.resetAdmnProveedor();
     
    data_enviar.clave_Proveedor = clave_Proveedor;
        
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> al proveedor <b>' + prov.nombre.toUpperCase() + '</b> ?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
       // ruta.clv_disponible = ruta.clv_disponible == 1 ? 0 : 1;
        this.admProveedoresService.cambiarEstatusProveedor(data_enviar).subscribe(resp => {
          this.resultado = resp;
          if(resp.clv_estatus == -2)
          {
            setTimeout(() => {
              this.messageService.add({ severity: 'warn', summary: 'Atención', detail: resp.messageDetails });
            }, 800);
          }
          else
          {
            setTimeout(() => {
              this.getListaDeProveedores();
              this.messageService.add({ severity: 'success', summary: 'Cambio de estatus', detail: 'El cambio de realizo de manera correcta' });
            }, 800);
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            //prov.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Cancelado' });
            break;
          case ConfirmEventType.CANCEL:
            //prov.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionProveedor() {

    //Se valida informacion de Formulario
    let prov_enviar: IAdmProveedor = {
      clave_Proveedor: this.provForm.get('clave_Proveedor')?.value,
      nombre: this.provForm.get('nom_proveedor')?.value,
      fecha_InicioContrato: new Date(1900,1,1),
      fecha_FinContrato: new Date(1900,1,1),
      fk_clave_Estado: this.provForm.get('num_estado')?.value,
      desc_razon_social: this.provForm.get('desc_razon_social')?.value,
      id_tipo_renta: this.provForm.get('id_tipo_renta')?.value,
      id_dias_trabajados: this.provForm.get('id_dias_trabajados')?.value,
      clv_kim_adicional: this.provForm.get('clv_kim_adicionales')?.value,
      imp_kim_adicional: this.provForm.get('imp_kim_adicional')?.value,
      id_metodo_pago: this.provForm.get('id_tipo_pago_proveedor')?.value,
      cod_usuario: this.user.cod == undefined ? '0999':this.user.cod,
    };
    //Se manda llamar el servicio
    this.admProveedoresService.AddProveedor(prov_enviar).subscribe((resp) => {
      this.resultado = resp;
      if(resp.clv_estatus == -2)
      {
        setTimeout(() => {
          this.messageService.add({ severity: 'warn', summary: 'Atención', detail: resp.messageDetails });
          this.cancelarMostrarAddEditProveedor();
        }, 800);
      }
      else
      {
        setTimeout(() => {
          this.getListaDeProveedores();
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.cancelarMostrarAddEditProveedor();
        }, 800);
      }
    }, (error) => {
      let headMssg = this.provForm.get('clave_Proveedor')?.value == 0 ? 'Error en <Registrar Proveedor>':'Error en <Editar Proveedor>'
      let mssg = this.provForm.get('clave_Proveedor')?.value == 0 ? 
        'Error en registrar el proveedor '+this.provForm.get('nom_proveedor')?.value
        :'Error en editar proveedor '+this.provForm.get('nom_proveedor')?.value;
      this.messageService.add({ severity: 'error', summary: headMssg, detail: mssg });
    });
  }



  cancelarMostrarAddEditProveedor() {
    this.dspAddEditProveedor = false;
    //Reiniciar formulario ruta especial
    this.resetearFormulario();
  }

  exportarExcel() {
    this.loading_reporte1 = true;
    if (this.num_estado_filter == undefined) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Estado</b> de Operación a Filtrar');
    } else {
      this.admProveedoresService.getRptEstatusProveedor(this.num_estado_filter,0)
        .subscribe((resp) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo de Proveedores para el estado '+this.lstEstados.find( x => Number(x.clave_Estado) == this.num_estado_filter)?.nombre.toUpperCase()+'(Excel)');
            this.loading_reporte1 = false;
          } else {

            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });
              this.saveAsExcelFile(excelBuffer, "Proveedores");
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Proveedores.' });
          });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  resetearFormulario(){
    this.provForm.reset();
    this.proveedorName = '';
    this.provRazonSocial = '';
    this.provEdit=false;

  }
  abrirModalReportes() {
    this.dbsReportes = true;
    this.num_estado_reporte = this.num_estado_filter;
  }
  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.num_estado_reporte = 0;
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    //Reportes
    this.fecha_hoy = dateFormat;
  }

}
