<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="container-fluid" style="border-color: blue; border: 5px ; width: 90%;">
    <div class="row">
        <div class="col-md-12">
            <div class="card card-shadow sinborde">
                <div class="card-header sinborde" style="background: none; ">
                    <div class="cardicono rounded">
                        <i class="pi pi-history mt-3" style="font-size: 2rem; color: #fff;"></i>
                    </div>
                    <header style="left: 11%; top: 5%;">Operación Diaria
                        <div style="color:blueviolet; display: inline;">Servicios Extras</div>
                    </header>
                    <div class="row d-flex justify-content-end">
                        <div class="col-10">
                            <div class="form-group row mt-5">
                                <div class="col-5">
                                    <label for="lblligero" class="col-sm-4 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Fecha</label>
                                    <div class="col-sm-8">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                            [(ngModel)]="fecha_dia">
                                        <hr class="my-0">
                                    </div>
                                </div>
                                <div class="col-2 d-flex align-items-end">
                                    <button pButton pRipple type="button"
                                        (click)="cargarInformacionPorFecha(fecha_dia,'1900-01-01');" label="Filtrar"
                                        icon="pi pi-filter" [style]="{'width':'100%'}" iconPos="left"
                                        class="info"></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center">
                            <button *ngIf="mostrarbtnLimpiarFiltro" pButton pRipple type="button"
                                (click)="LimpiarFiltro();" label="Limpiar Filtro"
                                class="p-button-raised p-button-warning" icon="pi pi-filter-slash" iconPos="left"
                                pTooltip="Reiniciar Filtro" tooltipPosition="top"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <p-table #dt1 [value]="lstMovimientos" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[150,200,300,500]" [showCurrentPageReport]="true"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
            [globalFilterFields]="['num_economico','nombre_chofer','nombre_completo_adicional1','nombre_completo_adicional2']"
            [loading]="loading">
            <ng-template pTemplate="caption">
                <div class="flex justify-content-between">
                    <button type="button" pButton pRipple class="p-button-success ml-5"
                        pTooltip="Exportar TODOS los Movimientos a Excel" tooltipPosition="right"
                        (click)="exportarExcel();">
                        <i class="pi pi-file-excel">Exportar</i>
                    </button>
                    <div class="col-2">
                        <button type="button" class="btn btn-warning" style="width: 100%;"
                            (click)="abrirModalReportes()" pTooltip="Abrir Ventana" tooltipPosition="left">
                            Reportes <i class="pi pi-file-excel"></i>
                        </button>
                    </div>
                    <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                            placeholder="Buscar Nombre Chofer,Numero Economico" />
                    </span>
                </div>
                <div class="row d-flex justify-content-end mt-5 mb-5">
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_ruta" placeholder="Ruta"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_cliente" placeholder="Cliente"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_proyecto" placeholder="Proyecto"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_ciudad" placeholder="Ciudad"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 100px;">#</th>
                    <th style="width: 200px; min-width: 200px;" pSortableColumn="desc_ruta">Ruta<p-sortIcon
                            field="desc_ruta"></p-sortIcon></th>
                    <th style="width: 300px;" pSortableColumn="desc_tiporuta">Tipo de Ruta<p-sortIcon
                            field="desc_tiporuta"></p-sortIcon></th>
                    <th style="width: 150px;min-width: 150px;">Ciudad</th>
                    <th style="width: 100px;" pSortableColumn="num_economico">#Economico<p-sortIcon
                            field="num_economico"></p-sortIcon></th>
                    <th style="width: 120px;min-width: 120px;">Colaborador</th>
                    <th style="width: 100px;">Fecha</th>
                    <th style="width: 100px;"></th>
                    <th style="width: 100px;"></th>
                    <th style="width: 100px;"></th>
                    <th style="width: 100px;"></th>
                    <th style="width: 100px;"></th>
                    <th style="width: 100px;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                    <td style="width: 100px;">{{ (rowIndex+1) }}</td>
                    <td style="width: 300px;" [ngClass]="{'col-header-sp': data.clv_especial==1}">{{data.desc_ruta}}
                        <p style="display: inline-block; color: green !important;font-weight: bold;">
                            {{ data.desc_folio}}</p>
                    </td>
                    <td style="width: 300px;" [ngClass]="{'col-header-sp': data.clv_especial==1}">{{data.desc_tiporuta}}
                        <p *ngIf="data.clv_cancelado==1" class="col-orange col-md-6 mt-2"
                            pTooltip="La ruta se marco como Cancelada por Operaciones">CANCELADA</p>
                    </td>
                    <td style="width: 150px;min-width: 150px;">{{data.desc_ciudad}}</td>
                    <td style="width: 100px; color: green; font-weight: bold;">{{data.num_economico}}</td>
                    <td style="width: 120px;min-width: 120px; color: blueviolet; font-weight: bold; font-size: 12px;">
                        <ol>
                            <li>{{'(Principal) '+data.nombre_chofer}}</li>
                            <li *ngIf="data.id_puesto1!=0">{{'('+data.desc_puesto_adicional1+') '+data.nombre_completo_adicional1}}</li>
                            <li *ngIf="data.id_puesto2!=0">{{'('+data.desc_puesto_adicional2+') '+data.nombre_completo_adicional2}}</li>
                        </ol>
                    </td>
                    <td>
                        <div>{{data.fecha | date: 'dd/MM/yyyy'}}</div>
                        <div class="col-hora text-center">{{data.hora}}</div>
                    </td>
                    <td *ngIf="data.clv_operacion==3 || data.clv_operacion==5 || data.clv_operacion==6">
                        <div class="d-flex justify-content-center">
                            <div>
                                <p-button pTooltip="Monitoreo" tooltipPosition="left" icon="pi pi-globe"
                                styleClass="p-button-secondary"
                                (onClick)="mostrarModalGestion(data)">
                            </p-button>
                            <p style="font-size: 10px; color: green; font-weight: bold;" class="d-block mt-1" *ngIf="data.clv_fin_gestion==1">FINALIZADA</p>
                            <p style="font-size: 10px; color: orange;font-weight: bold;" class="d-block mt-1" *ngIf="data.clv_fin_gestion==0">EN PROCESO</p> 
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <p-button pTooltip="Maniobras" tooltipPosition="top" icon="pi pi-users"
                                styleClass="p-button-secondary"
                                (onClick)="mostrarModalManiobras(data)"></p-button>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <p-button pTooltip="Tienda Adicional" tooltipPosition="top" icon="pi pi-shopping-cart"
                                styleClass="p-button-secondary" 
                                (onClick)="mostrarModalTienda(data)"></p-button>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <p-button pTooltip="Extensión de Ruta" tooltipPosition="top" icon="pi pi-plus"
                                styleClass="p-button-secondary"
                                (onClick)="mostrarModalExtension(data)"></p-button>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <p-button pTooltip="Extensión de Ruta Fuera de Semana" tooltipPosition="top" icon="pi pi-plus"
                                styleClass="p-button-warning"
                                (onClick)="mostrarModalExtensionFueraSemana(data)" (disabled)="data.clv_extension == 0"></p-button>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <p-button pTooltip="Rescate de Unidad" tooltipPosition="top" icon="pi pi-truck"
                                styleClass="p-button-secondary"
                                (onClick)="mostrarModalRescate(data)"></p-button>
                        </div>
                    </td>
                    <!--<td>
                        <div class="d-flex justify-content-center">
                            <p-splitButton label="Extensión" [model]="this.items" (onClick)="save(data)" 
                            styleClass="p-button-raised p-button-text mr-2 mb-2"
                            [ariaPosInSet]="true" ></p-splitButton>
                        </div>
                    </td>-->
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!--Maniobras-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspManiobra"
        [style]="{width: '80vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalManiobras()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Servicio extra-Maniobras {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}</div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <form [formGroup]="maniobrasForm" (ngSubmit)="guardarInformacionManiobras()">
                        <div class="row">
                            <div class="col-md-6">
                                <span class="title-light mt-3">Información de Personal con apoyo en Maniobras<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <!--APARTADO COLABORADORES-->
                                <div class="row mt-2">
                                    <div class="row">
                                        <div class="form-group col-md-10">
                                            <div class="row g-0">
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                            style="font-size:1.5rem;"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-10">
                                                    <p-dropdown [options]="lstChoferesDisponibles" optionLabel="nombre_completo" autoWidth="false"
                                                    [style]="{'width':'100%'}" placeholder="Seleccione Colaborador*"
                                                    formControlName="SelectedColaborador" [filter]="true"
                                                    ></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <div class="row g-0">
                                                <div class="col-sm-6 mx-0">
                                                    <p-button icon="pi pi-plus" iconPos="right" (click)="agregarColaborador()"
                                                        styleClass="p-button-sm" pTooltip="Agregar Colaborador"
                                                        [disabled]="btnHabilitar"></p-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <p-table #myTab [value]="lstChoferesDisponiblesAgregados"
                                            styleClass="p-datatable-sm" [tableStyle]="{'min-width': '30rem','min-height':'250px'}"
                                            [scrollable]="true" scrollHeight="250px">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Colaborador</th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                                    <tr>
                                                        <td>{{ (rowIndex+1) }}</td>
                                                        <td class="texto-lila">{{ data.nombre_completo }}</td>
                                                        <td class="g-0">
                                                               <p-button *ngIf="clv_operacion==1" icon="pi pi-trash"
                                                                styleClass="p-button-rounded p-button-danger p-button-text"
                                                                (click)="eliminarColaborador(data)"
                                                                pTooltip="Eliminar"
                                                                [disabled]="((!habilitar_un_dia && clv_operacion==1) && btnHabilitar) || btnHabilitar"></p-button>
                                                                <p-button *ngIf="clv_operacion==3" icon="pi pi-trash"
                                                                styleClass="p-button-rounded p-button-danger p-button-text"
                                                                (click)="eliminarColaborador(data)"
                                                                pTooltip="Admin-Eliminar"
                                                                [disabled]="btnHabilitar"></p-button>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-5">
                        <div class="row mt-2">
                            <!--TERCEROS-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-users color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2" style="display: inline-block; font-weight: bold;">
                                            Numero de personas(terceros)
                                        </p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Numero de Personas"
                                            formControlName="num_terceros" style="text-transform:uppercase;" min="0"
                                            pTooltip="Numero de personas(terceros)" tooltipPosition="left">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Pago a Terceros por Persona
                                        </p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe"
                                            formControlName="imp_terceros" style="text-transform:uppercase;" min="0"
                                            pTooltip="Importe de pago a Terceros (c/u)">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-2">
                                <!--NORMAL-->
                                <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100" 
                                [disabled]="habilitar_un_dia || !maniobrasForm.valid || btnHabilitar"
                                    pTooltip="Almacenar Toda la Informacion de Maniobras de la Ruta" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                                <!--ADMINISTRADOR-->
                                <button  *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100" 
                                [disabled]="!maniobrasForm.valid || btnHabilitar"
                                    pTooltip="Almacenar Toda la Informacion de Maniobras de la Ruta" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-2 text-center">
                                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-4" *ngIf="habilitar_un_dia==true && clv_operacion==1">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-warning w-100" (click)="habilitarManiobras();" *ngIf="clv_operacion==1"
                                [disabled]="(habilitar_un_dia)"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <button class="btn btn-warning w-100" (click)="habilitarManiobras();;" *ngIf="clv_operacion==3"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalManiobras();"></button>
        </ng-template>
    </p-dialog>
    <!--Tienda Adicional-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspTiendaAdicional"
        [style]="{width: '50vw','height':'500px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalTienda()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Servicio extra-Tienda Adicional {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <form [formGroup]="tiendaForm" (ngSubmit)="guardarInformacionTiendaAdicional()">
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Información sobre Apoyo Extra<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--NUMERO DE TIENDAS ADICIONALES-->
                            <div class="form-group col-md-10">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-shopping-cart color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Numero de Tiendas Adicionales</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="#"
                                            formControlName="num_tiendas" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-3">
                                <!--NORMAL-->
                                <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100" 
                                [disabled]="habilitar_un_dia || !tiendaForm.valid || btnHabilitarTienda"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                                <!--ADMINISTRADOR-->
                                <button *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100" 
                                [disabled]="!tiendaForm.valid || btnHabilitarTienda"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-9" *ngIf="habilitar_un_dia==true && clv_operacion==1">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-warning w-100" (click)="habilitarTiendas();" *ngIf="clv_operacion==1"
                                [disabled]="(habilitar_un_dia)"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <button class="btn btn-warning w-100" (click)="habilitarTiendas();" *ngIf="clv_operacion==3"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-start col-orange">
                        <p>*El pago de Tienda Adicional aplica para todo el personal registrado en Ruta</p>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalTienda();"></button>
        </ng-template>
    </p-dialog>
    <!--Extension de Ruta-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspExtension"
        [style]="{width: '80vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalExtension()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Estadía/Extensión de Ruta {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <form [formGroup]="calculoForm" (ngSubmit)="calcularExtension()">
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Calculo de Ruta<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <!--CALCULO DE NUMERO DE DIAS Y NUMEROS DE ESTADIA-->
                        <div class="row">
                            <div class="form-group col-md-5">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-hashtag color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Numero de Dias Extra</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Dias"
                                            formControlName="num_dias_extra" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-5">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-hashtag color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Numero de Dias Estadía</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Dias Estadia"
                                            formControlName="num_dias_estadia" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <div class="row g-0">
                                    <div class="col-sm-12 w-100">
                                        <p-button label="Calcular" icon="pi pi-search"
                                        [disabled]="!calculoForm.valid"
                                        type="submit"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="extensionForm" (ngSubmit)="guardarInformacionExtension()">
                        <!--INFORMACION DE CALCULO-->
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Información sobre Apoyo Extra<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--DRIVER-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Chofer</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_tab_total_extra_driver" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Viatico Extra Chofer</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_driver" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--AUXILIAR-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Auxiliar</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_tab_total_extra_auxiliar" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Viatico Extra Aux.</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_auxiliar" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-5">
                        <div class="row mt-2">
                            <!--HOSPEDAJE-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Hospedaje</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_hospedaje" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-3">
                                <!--NORMAL-->
                                <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100" 
                                [disabled]="habilitar_un_dia || !extensionForm.valid || btnHabilitarExtension"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                                <!--ADMINISTRADOR-->
                                <button *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100" 
                                [disabled]="btnHabilitarExtension"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-6" *ngIf="habilitar_un_dia==true && clv_operacion==1">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-warning w-100" (click)="habilitarExtension();" *ngIf="clv_operacion==1"
                                [disabled]="(habilitar_un_dia)"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <button class="btn btn-warning w-100" (click)="habilitarExtension();" *ngIf="clv_operacion==3"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalExtension();"></button>
        </ng-template>
    </p-dialog>
    <!-- diasExtensionFueraSemana -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspExtensionFueraSemana"
    [style]="{width: '80vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalExtensionFueraSemana()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Estadía/Extensión de Ruta Fuera De Semana {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <form [formGroup]="calculoFueraSemanaForm" (ngSubmit)="calcularExtensionFueraSemana()">
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Calculo de Ruta<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <!--CALCULO DE NUMERO DE DIAS Y NUMEROS DE ESTADIA-->
                        <div class="row">
                            <div class="form-group col-md-5">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-hashtag color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Numero de Dias Extra</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Dias"
                                            formControlName="num_dias_extra_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-5">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-hashtag color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Numero de Dias Estadía</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Dias Estadia"
                                            formControlName="num_dias_estadia_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <div class="row g-0">
                                    <div class="col-sm-12 w-100">
                                        <p-button label="Calcular" icon="pi pi-search"
                                        [disabled]="!calculoForm.valid"
                                        type="submit"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="extensionFSemanaForm" (ngSubmit)="guardarInformacionExtensionFSemana()">
                        <!--INFORMACION DE CALCULO-->
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Información sobre Apoyo Extra Fuera de Semana<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Fecha a Aplicar</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha" tooltipPosition="Fecha a Aplicar"
                                            formControlName="fec_fuera_semana">    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--DRIVER-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Chofer</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_tab_total_extra_driver_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Viatico Extra Chofer</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_driver_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--AUXILIAR-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Auxiliar</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_tab_total_extra_auxiliar_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Viatico Extra Aux.</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_auxiliar_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-5">
                        <div class="row mt-2">
                            <!--HOSPEDAJE-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Hospedaje</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control" placeholder="Importe Total"
                                            formControlName="imp_viaticos_extra_hospedaje_fuera_semana" min="0">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-3">
                                <!--NORMAL-->
                                <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100" 
                                [disabled]="habilitar_un_dia || !extensionForm.valid || btnHabilitarExtensionFueraSemana"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                                <!--ADMINISTRADOR-->
                                <button *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100" 
                                [disabled]="btnHabilitarExtensionFueraSemana"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-6" *ngIf="habilitar_un_dia==true && clv_operacion==1">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-warning w-100" (click)="habilitarExtensionFsemana();" *ngIf="clv_operacion==1"
                                [disabled]="(habilitar_un_dia)"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <button class="btn btn-warning w-100" (click)="habilitarExtensionFsemana();" *ngIf="clv_operacion==3"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalExtensionFueraSemana();"></button>
        </ng-template>
    </p-dialog>
    <!--Rescate Unidad-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspRescate"
        [style]="{width: '50vw','height':'500px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalRescate()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Servicio extra-Rescate Unidad Apoyo {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <form [formGroup]="rescateForm" (ngSubmit)="guardarInformacionRescate()">
                        <div class="row">
                            <div class="form-group col-md mt-2">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0 align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2 d-inline"
                                                style="display: inline-block; font-weight: bold;">Driver</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control sinborde"
                                            pTooltip="Sueldo Driver" tooltipPosition="top" value="{{sueldo_driver|currency}}"
                                            disabled="true">    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md mt-2">
                                <div class="row g-0">
                                    <div class="col-sm-4 mx-0 align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2 d-inline"
                                                style="display: inline-block; font-weight: bold;">Auxiliar</p>
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control sinborde"
                                            pTooltip="Sueldo Auxiliar" tooltipPosition="top" value="{{sueldo_auxiliar|currency}}"
                                            disabled="true">    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Información sobre Apoyo Extra<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--NUMERO ECONOMICO DE UNIDAD A RESCATAR-->
                            <div class="form-group col-md mt-2">
                                <div class="row g-0 mt-2">
                                    <div class="col-sm-4 d-flex align-items-center">
                                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                                        <p class="ml-1 color-icon d-inline">Numero Economico a Rescatar</p>
                                    </span>
                                    </div>
                                    <div class="col-sm-4">
                                    <p-dropdown [options]="lstEconomico" optionLabel="num_economico" placeholder="Numero Economico*"
                                        autoWidth="false" [style]="{'width':'100%'}" [showClear]="true" 
                                        formControlName="num_economico_rescate" [filter]="true"
                                        tooltip="Numero Economico a Rescatar" tooltipPosition="left"
                                        optionValue="id_unidad_transporte" ></p-dropdown>
                                    <hr class="my-0">
                                    </div>
                                </div>
                                <!-- <div class="row g-0 mt-2">
                                    <div class="col-sm-4 d-flex align-items-center">
                                      <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                                        <p class="ml-1 color-icon d-inline">Porcentaje</p>
                                      </span>
                                    </div>
                                    <div class="col-sm-4">
                                      <p-dropdown [options]="lstRescatrPercentage" optionLabel="desc_recate_porc" 
                                        placeholder="Porcentaje del pago diario*"
                                        autoWidth="false" [style]="{'width':'100%'}" [showClear]="true" 
                                        formControlName="num_rescate_porc"
                                        tooltip="Porcentaje del pago diario" tooltipPosition="left"
                                        optionValue="idx"></p-dropdown>
                                      <hr class="my-0">
                                    </div>
                                  </div> 
                                </div>-->
                                <div class="row g-0 mt-2">
                                    <div class="col-sm-4 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                                        <p class="ml-1 color-icon d-inline">Viaticos</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control sinborde"
                                            pTooltip="Viaticos Rescate Apoyo" tooltipPosition="top" formControlName="imp_viaticos_rescate">    
                                        <hr class="my-0">
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-3">
                                <!--NORMAL-->
                                <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100" 
                                [disabled]="habilitar_un_dia || !rescateForm.valid || btnHabilitarRescate"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                                <!--ADMINISTRADOR-->
                                <button *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100" 
                                [disabled]="!rescateForm.valid || btnHabilitarRescate"
                                    pTooltip="Almacenar Toda la Informacion" tooltipPosition="bottom">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-9" *ngIf="habilitar_un_dia==true && clv_operacion==1">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <button class="btn btn-warning w-100" (click)="habilitarRescate();" *ngIf="clv_operacion==1"
                                [disabled]="(habilitar_un_dia)"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <button class="btn btn-warning w-100" (click)="habilitarRescate();" *ngIf="clv_operacion==3"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-start col-orange">
                        <p>*El pago de Rescate de Unidad aplica para todo el personal registrado en Ruta.</p>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalRescate();"></button>
        </ng-template>
    </p-dialog>
    <!--Gestion de Ruta-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspGestion"
        [style]="{width: '80vw','height':'750px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalGestion()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12" style="font-size: 13px;">Gestión de Ruta: {{ texto_nombre_ruta }} -{{(texto_fecha_recurso | date: 'dd/MM/yyyy')}}
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <!--DETALLES Y FINALIZAR RUTA-->
                <div class="row">
                    <div class="col-md-12 texto-lila d-flex justify-content-between">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-7">
                                    <p class="d-flex align-items-center texto-naranja">Numero Economico: {{data_enviar.num_economico}}</p>
                                </div>
                            </div>
                            <div class="row texto-black" style="font-size: 10px;">
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Principal: {{ data_enviar.nombre_chofer}}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Adicional 1: {{data_enviar.nombre_completo_adicional1}}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Adicional 2: {{data_enviar.nombre_completo_adicional2}}</p>
                                </div>
                            </div>
                        </div>
                        <div class=" col-md-5">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center">{{messageFin}}</p>
                                </div>
                                <div class="col-md-6">
                                    <p-button *ngIf="clv_operacion==5" label="Finalizar Ruta" (onClick)="validarInformacionGestion()" 
                                    [disabled]="data_enviar.clv_fin_gestion==1 && clv_operacion==5 || btnHabilitarGestion"></p-button>
                                    <p-button *ngIf="clv_operacion==3 || clv_operacion==6" label="Finalizar Ruta" (onClick)="validarInformacionGestion()" 
                                    [disabled]="btnHabilitarGestion">
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <form [formGroup]="gestionForm" (ngSubmit)="guardarGestion()">
                        <div class="row">
                            <div class="col-md-12">
                                <span class="title-light mt-3">Información de Ruta<i class="pi pi-cog"
                                        style="size: 2rem;"></i>
                                </span>
                            </div>
                        </div>
                        <!--GESTION DE RUTA GENERAL-->
                        <div class="row">
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">¿Tiene Salida?</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-checkbox formControlName="clv_salida" inputId="fc" [binary]="true"></p-checkbox>    
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <p class="texto-lila mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">¿Cumple Salida?</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-checkbox formControlName="clv_cumple_salida" inputId="fcs" [binary]="true"></p-checkbox>    
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Fecha Salida</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                            formControlName="fec_salida">    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-stopwatch color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Hora Salida</p>
                                    </div>
                                    <div class="col-sm-6">
                                            <input type="time" class="form-control sinborde" placeholder="Hora"
                                            formControlName="hora_salida" step="3600">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">¿Tiene Llegada?</p>
                                    </div>
                                    <div class="col-sm-6"> 
                                        <p-checkbox formControlName="clv_llegada"  [binary]="true" inputId="fll"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <p class="texto-lila mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">¿Cumple Llegada?</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-checkbox formControlName="clv_cumple_llegada" inputId="fcs" [binary]="true"></p-checkbox>    
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Fecha Llegada</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                            formControlName="fec_llegada">    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-stopwatch color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Hora Llegada</p>
                                    </div>
                                    <div class="col-sm-6">
                                            <input type="time" class="form-control sinborde" placeholder="Hora"
                                            formControlName="hora_llegada" step="3600">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-verified color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Rostering(Cumple personal y unidad)</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-dropdown [options]="lstTiposValidacion" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="chk_cumple"
                                                optionValue="id"
                                                placeholder="Seleccione Tipo Validación*"></p-dropdown>    
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--Domicilios y Programados-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-building color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="texto-lila mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Domicilios Programados</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control sinborde" placeholder="#"
                                            formControlName="num_domicilios_programados" min="1" max="15">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-building color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Domicilios Visitados</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control sinborde" placeholder="#"
                                            formControlName="num_domicilios_visitados" min="1" max="15">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <!--Paquetes y Programados-->
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-box color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="texto-lila mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Paquetes Programados</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control sinborde" placeholder="#"
                                            formControlName="num_paquetes_programados" min="1">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-box color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                        <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Paquetes Entregados</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="number" class="form-control sinborde" placeholder="#"
                                            formControlName="num_paquetes_entregados" min="1">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--guardar-->
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="form-group col-md-2">
                                <div class="row g-0">
                                    <div class="col-sm-12 w-100">
                                        <!--<p-button label="Guardar" icon="pi pi-save" class="p-button-success"
                                        [disabled]="!gestionForm.valid"
                                        type="submit"></p-button>-->
                                        <button *ngIf="clv_operacion==5" type="submit" class="btn btn-success w-100" 
                                        [disabled]="(habilitar_un_dia) || data_enviar.clv_fin_gestion==1 || btnHabilitarGestion || !gestionForm.valid || btnHabilitarSpinner">
                                            <span class="text-white">Guardar Todo</span>
                                            <i class="pi pi-save text-white"></i>
                                        </button>
                                        <button *ngIf="clv_operacion==3 || clv_operacion==6" type="submit" class="btn btn-success w-100"
                                        [disabled]="btnHabilitarGestion">
                                            <span class="text-white">Guardar Todo</span>
                                            <i class="pi pi-save text-white"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-2 text-center">
                                <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitarSpinner"></i>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-6" *ngIf="habilitar_un_dia==true && clv_operacion==5">
                            <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                                Fechas Anteriores</p>
                        </div>
                        <div class="col-3">
                            <!--USUARIOS NORMALES-->
                            <button class="btn btn-warning w-100" (click)="habilitarGestion();" *ngIf="clv_operacion==5"
                                [disabled]="(habilitar_un_dia) || data_enviar.clv_fin_gestion==1"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                            <!--ADMINISTRADORES-->
                            <button class="btn btn-warning w-100" (click)="habilitarGestion();" 
                                    *ngIf="clv_operacion==6 || clv_operacion==3"
                                    pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModalGestion();"></button>
        </ng-template>
    </p-dialog>
    <!--REPORTES-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{width: '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
        Reportes
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-5">
            <div class="row">
                <div class="col-md-3">
                    <p class="texto-titulo-reporte">Movimientos de Operación(Gestión de Ruta)</p>
                </div>
                <div class="col-md-3">
                    <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Inicio" tooltipPosition="top"
                                            [(ngModel)]="fecha_inicio_r1">
                    <hr class="my-0">
                </div>
                <div class="col-md-1 texto-titulo-reporte">Al</div>
                <div class="col-md-3">
                    <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Fin" tooltipPosition="top"
                                            [(ngModel)]="fecha_fin_r1">
                    <hr class="my-0">
                                            
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-success" style="width: 100%;"
                        (click)="exportar(1)" [disabled]="btngenerandoReporte">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-2 text-center">
                    <i class="pi pi-spin pi-spinner" *ngIf="btngenerandoReporte"></i>
                </div>
            </div>
            <hr class="style-two">
            <div class="row">
                <div class="col-md-4">
                    <p class="texto-titulo-reporte">Reporte 2</p>
                </div>
                <div class="col-md-3">
                    
                </div>
                <div class="col-md-3">
                    
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-success" style="width: 100%;"
                        (click)="exportar(2)">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <hr class="style-two">
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalReportes();"></button>
    </ng-template>
</p-dialog>
</div>