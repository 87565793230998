<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Herramientas Por Unidad</header>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstTool" [tableStyle]="{'min-width': '50rem'}"
                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                responsiveLayout="scroll" [globalFilterFields]="['num_economico','herramienta']" [paginator]="true" [rows]="30"
                [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true" [resizableColumns]="false"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-between">
                    <button type="button"
                    pButton
                    pRipple class="p-button-success ml-5" pTooltip="Exportar Catalogo a EXCEL"
                        tooltipPosition="right" (click)="exportarExcel();">
                        <i class="pi pi-file-excel">Exportar</i>
                    </button>
                    <button type="button"
                    pButton
                    pRipple class="p-button-success ml-5" pTooltip="Reporteria"
                        tooltipPosition="right" (click)="mostrardbsReportes();">
                        <i class="pi pi-file-excel">Reportes</i>
                    </button>
                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Buscar Num Economico, Herramienta" />
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="id" class="text-center">#<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipo" class="text-center">Tipo Transporte<p-sortIcon field="tipo"></p-sortIcon>
                    </th>
                    <th pSortableColumn="num_economico" class="text-center">Numero Economico<p-sortIcon
                        field="num_economico"></p-sortIcon></th>
                    <th pSortableColumn="herramienta">Herramienta<p-sortIcon
                        field="herramienta"></p-sortIcon></th>
                    <th pSortableColumn="clv_instalado" class="text-center">Instalada<p-sortIcon
                        field="clv_instalado"></p-sortIcon>
                    <th pSortableColumn="clv_funcionamiento" class="text-center">Funcionamiento<p-sortIcon
                        field="clv_funcionamiento"></p-sortIcon>
                    <th pSortableColumn="clv_configuracion" class="text-center">Configurada
                      <p-sortIcon field="clv_configuracion"></p-sortIcon>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index>
                  <tr>
                    <td>
                      <p class="font-weight-bold text-center">{{data.id}}</p>
                    </td>
                    <td class="text-center">{{data.tipo}}</td>
                    <td class="text-center">{{data.num_economico}}</td>
                    <td>{{data.herramienta}}</td>
                    <td class="text-center">
                      <ng-container *ngIf="data.clv_instalado != null; else tagclv_instalado">
                        <ng-container *ngIf="data.clv_instalado == 1; else taginstalado">
                          <i class="pi pi-check" style="color: green;"></i>
                      </ng-container>
                      <ng-template #taginstalado>
                        <i class="pi pi-times" style="color: red;"></i>
                      </ng-template>
                      </ng-container>
                      <ng-template #tagclv_instalado>
                        Pendiente
                      </ng-template>
                    </td>
                    <td class="text-center">
                      <ng-container *ngIf="data.clv_funcionamiento != null; else tagclv_funcionamiento">
                        <ng-container *ngIf="data.clv_funcionamiento == 1; else tagfuncionamiento">
                          <i class="pi pi-check" style="color: green;"></i>
                      </ng-container>
                      <ng-template #tagfuncionamiento>
                        <i class="pi pi-times" style="color: red;"></i>
                      </ng-template>
                      </ng-container>
                      <ng-template #tagclv_funcionamiento>
                        Pendiente
                      </ng-template>
                    </td>
                    <td class="text-center">
                      <ng-container *ngIf="data.clv_configuracion != null; else tagclv_configuracion">
                        <ng-container *ngIf="data.clv_configuracion == 1; else tagconfigurado">
                          <i class="pi pi-check" style="color: green;"></i>
                      </ng-container>
                      <ng-template #tagconfigurado>
                        <i class="pi pi-times" style="color: red;"></i>
                      </ng-template>
                      </ng-container>
                      <ng-template #tagclv_configuracion>
                        Pendiente
                      </ng-template>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{width: '70vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
        Reportes
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-5">
            <hr class="style-two">
            <div class="row mt-2">
                <div class="col-md-4">
                    <p class="texto-pesado">Herramientas por Unidad</p>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-success" style="width: 100%;"
                        (click)="generarReporte1()">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <hr class="style-two">
            <div class="row mt-2">
                <div class="col-md-4">
                    <p class="texto-pesado">Control Dispositivos</p>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-success" style="width: 100%;"
                        (click)="generarReporteControlDisp()">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <hr class="style-two">
            <div class="row mt-2">
                <div class="col-md-4">
                    <p class="texto-pesado">Tabla Dinamica Dispositivos</p>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-success" style="width: 100%;"
                        (click)="generarReporteTablaDinamica()">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalReportes();"></button>
    </ng-template>
</p-dialog>
</div>

