<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
  rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 100%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-map-marker mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Proveedores de unidades de transporte</header>
            <div class="row d-flex justify-content-end">
              <div class="col-md-12">
                <div class="form-group row mt-5">
                  <div class="col-md-3 mr-3">
                    <p-dropdown [(ngModel)]="num_estado_filter" [options]="lstEstados" optionLabel="nombre"
                      optionValue="clave_Estado" placeholder="Filtrar por Estado" [showClear]="true">
                    </p-dropdown>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="col-md-2">
                    <button pButton pRipple type="button" (click)="getListaDeProveedores();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left"></button>
                  </div>
                  <div class=" col-md-2 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel">Exportar</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end">
              <div class="col-2">
                <button type="button" class="btn btn-primary form-control" (click)="addProveedor();"
                  pTooltip="Nuevo Proveedor" tooltipPosition="top">
                  Agregar <i class="pi pi-plus-circle"></i>
                </button>
                <!-- <button type="button" class="btn btn-primary form-control" (click)="redireccionarNueva();"
                  pTooltip="ir a Nuev" tooltipPosition="top">
                  Agregar <i class="pi pi-plus-circle"></i>
                </button> -->
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstProveedores" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm"
                [scrollable]="true" scrollHeight="480px" responsiveLayout="scroll"
                [globalFilterFields]="['clave_Proveedor','nombre','desc_estado','desc_razon_social']" [paginator]="true"
                [rows]="50" [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true"
                [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                [loading]="loading" sortField="nombre">
                <ng-template pTemplate="caption">
                  <!-- <div class="flex justify-content-start mb-3">
                    <button type="button" pButton pRipple class="p-button-success ml-5"
                      pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right" (click)="exportarExcel();">
                      <i class="pi pi-file-excel">Exportar</i>
                    </button>
                  </div> -->
                  <div class="row">
                    <div class="flex justify-content-end">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" style="margin-left: 5px;"></i>
                        <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar Clave, Nombre, Estado, Razón Social" style="margin-left: 5px;" />
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <p-columnFilter field="fk_clave_Estado" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstEstados" optionLabel="nombre"
                            optionValue="clave_Estado" (onChange)="filter($event.value)"
                            placeholder="Filtrar por Estado" [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                    <div class="col-md-2">
                      <p-columnFilter field="id_tipo_renta" matchMode="equals" [showMenu]="false"
                        tooltip="Filtrar por Tipo Renta" tooltipPosition="top">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstTipoRenta" optionLabel="nombre" optionValue="idx"
                            (onChange)="filter($event.value)" placeholder="Filtrar por Tipo Renta" [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                    <div class="col-md-2">
                      <p-columnFilter field="id_dias_trabajados" matchMode="equals" [showMenu]="false"
                        tooltip="Filtrar las Dias Trabajados" tooltipPosition="top">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstDiasTrabajados" optionLabel="nombre"
                            optionValue="idx" (onChange)="filter($event.value)"
                            placeholder="Filtrar por dias Trabajados" [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                    <div class="col-md-2">
                      <p-columnFilter field="id_metodo_pago" matchMode="equals" [showMenu]="false"
                        tooltip="Filtrar por metodo de Pago" tooltipPosition="top">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstTipoPago" optionLabel="nombre" optionValue="idx"
                            (onChange)="filter($event.value)" placeholder="Filtrar por metodo de pago"
                            [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                    <div class="col-md-2">
                      <p-columnFilter field="clv_activo" matchMode="equals" [showMenu]="false"
                        tooltip="Filtrar por estatus Proveedor" tooltipPosition="top">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="id"
                            (onChange)="filter($event.value)" placeholder="Filtrar por estatus Proveedor"
                            [showClear]="true">
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" class="flex justify-content">
                  <tr>
                    <th pSortableColumn="clave_Proveedor" class="text-center">Clave Proveedor<p-sortIcon
                        field="clave_Proveedor"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombre">Nombre Proveedor<p-sortIcon field="nombre"></p-sortIcon></th>
                    <th pSortableColumn="desc_razon_social">Razón Social<p-sortIcon
                        field="desc_razon_social"></p-sortIcon>
                    </th>
                    <th>Estado</th>
                    <th>Tipo Renta</th>
                    <th>Dias Trabajados</th>
                    <th pSortableColumn="clv_kim_adicional">Lleva Kim. Adicional?<p-sortIcon
                        field="clv_kim_adicional"></p-sortIcon>
                    </th>
                    <th pSortableColumn="imp_kim_adicional">Importe Km. Adicional<p-sortIcon
                        field="imp_kim_adicional"></p-sortIcon>
                    </th>
                    <th>Metodo de Pago</th>
                    <th pSortableColumn="clv_activo">Estatus<p-sortIcon field="clv_activo"></p-sortIcon>
                    </th>
                    <th style="width: 150px;"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-prov>
                  <tr [ngClass]="{'row-accessories': prov.clv_activo === 0}">
                    <td [ngClass]="{'col-clave':true}">
                      {{prov.clave_Proveedor}}
                    </td>
                    <td [ngClass]="{'col-header':true,'col-header-sp': prov.clv_activo==1}">
                      {{prov.nombre}}
                    </td>
                    <td class="texto_responsable" [ngClass]="{'col-header-sp': prov.clv_especial==1}">
                      <!-- <p *ngIf="ruta.clv_especial==1" style="display: inline;"
                        pTooltip="Fecha de Operacion de la Ruta Especial" tooltipPosition="top">
                        {{ruta.fecha_registro_especial_cast}}</p> -->
                      {{prov.desc_razon_social}}
                    </td>
                    <td class="col-clave">{{prov.desc_estado}}</td>
                    <td>{{prov.desc_tipo_renta}}</td>
                    <td>{{prov.desc_dias_trabajados}}</td>
                    <td>{{prov.desc_kim_adicional}}</td>
                    <td class="col-clave">{{prov.imp_kim_adicional | currency}}</td>
                    <td>{{prov.desc_metodo_pago}}</td>
                    <td class="texto-valido">{{prov.desc_estatus}}</td>
                    <td style="width: 150px;">
                      <div class="d-flex justify-content-around">
                        <button class="btn btn-warning" pTooltip="Editar Informacion de Ruta" tooltipPosition="left"
                          (click)="editProveedor(prov)">
                          <i class="pi pi-pencil"></i>
                        </button>
                        <button class="btn btn-info" pTooltip="Cambiar Estatus de Ruta" tooltipPosition="left"
                          (click)="confirmarDesactivar(prov.clave_Proveedor,prov);">
                          <i class="pi pi-delete-left"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstRutas ? lstRutas.length : 0 }} Registros.
                                    </div>
                                </ng-template>-->
              </p-table>
            </div>
            <!-- <div class="row mt-3 d-flex justify-content-center">
              <div class="col-6">
                <div class="mt-3">
                  <p-colorPicker [(ngModel)]="color_rojo_especial" [disabled]="true"></p-colorPicker>
                  <p class="text-left informativo" style="display: inline;">Rutas Especiales</p>
                </div>
                <div class="mt-3 mb-2">
                  <p-colorPicker [(ngModel)]="color_gris_deshabilitadas" [disabled]="true"></p-colorPicker>
                  <p class="text-left informativo" style="display: inline;">Ruta INACTIVA</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- AddEditProveedor -->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspAddEditProveedor"
    [style]="{width: '70vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarMostrarAddEditProveedor()">
    <ng-template pTemplate="header">
      {{headerText}}
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <div class="row" *ngIf="provEdit">
          <div class="col-md-6">
            <p class="d-flex align-items-center texto-valido">Proveedor:&nbsp;{{proveedorName}}</p>
          </div>
          <div class="col-md-6">
            <p class="d-flex align-items-center texto-valido">Razón Social:&nbsp;{{provRazonSocial}}</p>
          </div>
        </div>
        <form [formGroup]="provForm" (ngSubmit)="guardarInformacionProveedor()">
          <span class="title-light mt-3">Información del Proveedor:<i class="pi pi-cog" style="size: 2rem;"></i>
          </span>
          <div style="height:700px;">
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Nombre Proveedor</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="string" class="form-control sinborde" pTooltip="Nombre Proveedor" tooltipPosition="top"
                      formControlName="nom_proveedor" style="text-transform:uppercase;">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Razón Social</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="string" class="form-control sinborde" pTooltip="Razón Social" tooltipPosition="top"
                      formControlName="desc_razon_social" style="text-transform:uppercase;">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Estado</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="lstEstados_Filter" optionLabel="nombre" placeholder="Estado*"
                      autoWidth="false" [style]="{'width':'100%'}" [showClear]="true" formControlName="num_estado"
                      tooltip="Seleccione el estado Origen del Proveedor" tooltipPosition="left"
                      optionValue="clave_Estado"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Tipo de Renta</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="lstTipoRenta" optionLabel="nombre" placeholder="Tipo Renta*"
                      autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                      (onChange)="changedpIdRenta($event)" formControlName="id_tipo_renta"
                      tooltip="Seleccione el Tipo de Renta" tooltipPosition="left" optionValue="idx"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Tipo de Días Trabajados</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="lstDiasTrabajados" optionLabel="nombre" placeholder="Días Trabajados"
                      autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                      formControlName="id_dias_trabajados" tooltip="Seleccione el Tipo de Días Trabajados"
                      tooltipPosition="left" optionValue="idx"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-truck color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Tiene Kilometros Adicionaels?</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="lstKimAdicional" optionLabel="nombre" placeholder="Kilometros Adicionales?"
                      (onChange)="changedpclvKim($event)" autoWidth="false" [style]="{'width':'100%'}"
                      [showClear]="true" formControlName="clv_kim_adicionales" tooltip="Seleccione SI/NO "
                      tooltipPosition="left" optionValue="id"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-dollar color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Importe Kilómetros Adicionales</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="number" class="form-control sinborde" pTooltip="Importe Kilómetros Adicionales"
                      placeholder="Imp. Kilómetros Adicionales" tooltipPosition="top"
                      formControlName="imp_kim_adicional" style="font-size: x-medium;" min="0">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-credit-card color-icon"
                        style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Método de Pago</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="lstTipoPago" optionLabel="nombre" placeholder="Método de Pago*"
                      autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                      formControlName="id_tipo_pago_proveedor" tooltip="Seleccione el Método de Pago"
                      tooltipPosition="left" optionValue="idx"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col">
                <p *ngIf="!provForm.valid" class="texto-invalido text-right">
                  <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                    Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                </p>
                <p *ngIf="provForm.valid" class="texto-valido text-right">
                  <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                    Completa</i>
                </p>
              </div>
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100" [disabled]="!provForm.valid ">
                  <span class="text-white">Aceptar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarMostrarAddEditProveedor();"></button>
    </ng-template>
  </p-dialog>
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
      Generar Reporte De Excel
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <hr class="style-two">
        <div class="row">
          <div class="col-md-4">
            <p class="texto-pesado">Reporte De Proveedores</p>
          </div>
          <div class="col-md-4">
            <p-dropdown [(ngModel)]="num_estado_reporte" [options]="lstEstados" optionLabel="nombre"
              optionValue="clave_Estado" placeholder="Filtrar por Estado" [showClear]="true">
            </p-dropdown>
          </div>
          <div class="col-md-4">
            <button type="button" class="btn btn-outline-success" style="width: 100%;" (click)="exportarExcel()"
              [disabled]="ButtonExportExcel">
              Exportar <i class="pi pi-file-export"></i>
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-2 text-center">
            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarModalReportes();"></button>
    </ng-template>
  </p-dialog>
</div>