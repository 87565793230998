import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ICliente } from '../models/cliente';
import { environment } from 'src/environments/environment';
import { IMovimientosFecha } from '../models/movfecha';
import { IRecursosMovimientos } from '../models/recursos';
import { IMovimientosFechaRecursos } from '../models/movfecharecursos';
import { IRecursosFechas, IUpdateRecursosFechas } from '../models/recursosFechas';
import { IResultadoGeneral } from '../models/resultadogeneral';

@Injectable({
  providedIn: 'root'
})
export class MovFechaService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'Movimientos';
  }

  getListadoDeMovimientosFecha(fecha: string,fecha_fin:string,cod_usuario?:string): Observable<IMovimientosFecha[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!;
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_final', fecha_fin).
        set('cod_usuario', cod_usuario_cast)
    };
    return this.http
      .get<IMovimientosFecha[]>(this.apiUrl + '/GetListadoMovFecha/', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoDeMovimientosFechaRecursos(fecha: string,fecha_fin:string,cod_usuario?:string): Observable<IMovimientosFechaRecursos[]> {
    let cod_usuario_cast = cod_usuario=='undefined' ? '': cod_usuario!;
    const valores = {
      params: new HttpParams().
        set('fecha', fecha).
        set('fecha_final', fecha_fin).
        set('cod_usuario', cod_usuario_cast)
    };
    return this.http
      .get<IMovimientosFechaRecursos[]>(this.apiUrl + '/GetMovimientoRecursos/', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  getFechaRecursos(data: number): Observable<IRecursosFechas> {
    const valores = {
      params: new HttpParams().
        set('idx', data)
    };
    return this.http
      .get<IRecursosFechas>(this.apiUrl + '/GetRecursosFechasServicio/', valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateFechaRecursos(data: IUpdateRecursosFechas): Observable<IResultadoGeneral> {
    console.log(data);
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/UpdateRecursosFechasServicio/',body,{'headers':headers});
  }

  public updateRecurso(data: IRecursosMovimientos ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/UpdateMovimientosUnidadesViaticos/',body,{'headers':headers});
   }

   public getListadoKilometraje( fec_dia_reporte: string, fec_fin_reporte: string  ): Observable<any> {
    const valores = {
      params: new HttpParams().
         set('fec_inicio',fec_dia_reporte).
         set('fec_fin',fec_fin_reporte)

    }
    return this.http
        .get<any>(this.apiUrl+'/GetRptRutaKilometraje',valores)
        .pipe(retry(1), catchError(this.handleError));

   }


  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
