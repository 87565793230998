
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IChofer } from '../models/chofer';
import { IChoferDisponible } from '../models/choferdisponible';
import { IChoferDisponibleManiobras } from '../models/choferdisponible_maniobras';
import { Ipuestos } from '../models/Ipuestos';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IBajaChofer } from '../models/bajaChofer';
import { IUserDispMovil } from '../models/usersDispMovil';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { __values } from 'tslib';
@Injectable({
  providedIn: 'root'
})
export class UsuariosDispMovilService {
  private apiUrl: string ='';
  private apiDispUrl: string = '';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'UsuariosDispMov';
    this.apiDispUrl = environment.sUrlDisp + 'user';
  }

  public getListadoChoferesSinUsuario(): Observable<IUserDispMovil[]> {
    return this.http
      .get<IUserDispMovil[]>(this.apiUrl+'/GetUsuariosDispMovil/'+2)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getListadoChoferesDispMovil(): Observable<IUserDispMovil[]> {
    return this.http
      .get<IUserDispMovil[]>(this.apiUrl+'/GetUsuariosDispMovil/'+1)
      .pipe(retry(1), catchError(this.handleError));
  }

  public addUsrChofer(cod_chofer: number,cod_usuario: string ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    return this.http.post<any>(this.apiDispUrl + '/chof?code='+cod_chofer+'&user='+cod_usuario,headers);
  }

  public editUserChofer(cod_chofer: number,cod_usuario: string ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    return this.http.post<any>(this.apiDispUrl + '/chof/username?code='+cod_chofer+'&user='+cod_usuario,headers);
  }

  public getUsrChofer(cod_chofer: number ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const valores = {
      params: new HttpParams().
        set('code',cod_chofer)
    };
    return this.http.get<any>(this.apiDispUrl + '/chof/',valores);
  }

  public chageStatusDispMovil(cod_chofer: number,cod_usuario: string): Observable<IResultadoGeneral> {
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify({cod_chofer: cod_chofer,cod_usuario: cod_usuario}); 

    return this.http.put<IResultadoGeneral>(this.apiUrl +'/CambiarEstatus/',body,{'headers':headers})
      .pipe(catchError(this.handleError));
  }

  public earseUserDispMovil(cod_chofer: number): Observable<IResultadoGeneral> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let data_enviar={headers:headers, body : JSON.stringify({cod_chofer: cod_chofer})};

    return this.http.delete<IResultadoGeneral>(this.apiUrl +'/EarseUserDispMovil',data_enviar)
      .pipe(catchError(this.handleError));
  }

  public getRptUsuariosDispositivosMoviles(): Observable<any> {
    return this.http
      .get<any>(this.apiUrl+'/GetrptUsuariosDispMovil')
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
  // getListadoChoferesGeneral(): Observable<InombreCompletoChofer[]> {
  //   return this.http.get<InombreCompletoChofer[]>(`${this.apiUrl}/getChoferesGenerales`)
  //   .pipe(retry(1), catchError(this.handleError));
  //}

}
