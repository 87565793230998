<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5 mb-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-cog mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Listado de Usuarios Dispositivos Moviles</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-primary form-control"
                                    (click)="mostrarNuevoUsuario(0)" pTooltip="Agregar un Nuevo Usuario"
                                    tooltipPosition="top" [disabled]="this.num_opcion != 0">
                                    Agregar <i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-start">
                            <div class="col-2">
                                <button type="button" class="btn btn-success form-control"
                                    (click)="exportarRptUsuariosDispositivosMoviles()" pTooltip="Exportar Usuarios General"
                                    tooltipPosition="top" [disabled]="this.lstUsuarios.length == 0">
                                    Exportar <i class="pi pi-file-export"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt [value]="lstUsuarios" [tableStyle]="{'min-width': '50rem'}"
                            styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="480px"
                            responsiveLayout="scroll" [globalFilterFields]="['cod_chofer','nombre_completo']" 
                            [paginator]="true" [rows]="50"
                            [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true"
                            [resizableColumns]="false"
                            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                            [loading]="loading" sortField="nombre_completo">
                            <ng-template pTemplate="caption">
                                <div class="flex justify-content-between">
                                    <p-columnFilter field="clv_activo" matchMode="equals" [showMenu]="false">
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-dropdown [ngModel]="value" [options]="lstEstatus"
                                                optionLabel="nombre" optionValue="id"
                                                (onChange)="filter($event.value)" placeholder="Filtrar por Estatus"
                                                [showClear]="true">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-columnFilter>
                                    <span class="p-input-icon-left p-ml-auto">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" size="40"
                                            (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar Codigo,Nombre" />
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="cod_chofer" class="text-center">#Codigo <p-sortIcon
                                        field="nom_usuario"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="nombre_completo">Nombre Chofer <p-sortIcon
                                            field="nombre_completo"></p-sortIcon></th>
                                    <th pSortableColumn="desc_estatus">Estatus<p-sortIcon
                                            field="desc_estatus"></p-sortIcon></th>
                                    <th style="width: 150px;"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-usr>
                                <tr>
                                    <td class="text-center" [ngClass]="{'color-usuario':true}">
                                        {{usr.cod_chofer}}
                                    </td>
                                    <td [ngClass]="{'col-header':true,'col-header-sp': usr.clv_activo==1}">
                                        {{usr.nombre_completo}}
                                    </td>
                                    <td>{{usr.desc_estatus}}</td>
                                    <td style="width: 150px;">
                                        <div class="d-flex justify-content-around form-control">
                                            <p-button styleClass="p-button-sm" icon="pi pi-eye"
                                                pTooltip="Mostrar" tooltipPosition="left" [disabled]="this.dspNuevoUsuario"
                                                (click)="mostrarNuevoUsuario(1,usr)"></p-button>
                                            <p-button styleClass="p-button-help p-button-sm" icon="pi pi-pencil"
                                                pTooltip="Cambiar Estatus" tooltipPosition="left" [disabled]="this.dspNuevoUsuario"
                                                (click)="editarEstatus(usr)"></p-button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <!--<ng-template pTemplate="summary">
                                <div class="flex align-items-center justify-content-between">
                                    Un total de {{lstRutas ? lstRutas.length : 0 }} Registros.
                                </div>
                            </ng-template>-->
                        </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="mt-3">
                                </div>
                                <div class="mt-3 mb-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspNuevoUsuario" (onHide)="cancelarMostrarAgregarUsuario()" on
        [style]="{'width': '60vw','height':'57vh'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{ texto_operacion }}
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_user" [formGroup]="userDispForm" (ngSubmit)="guardarInformacion()">
                        <div class="ml-1">
                            <span class="title-light">Informacion del Usuario <i class="pi pi-cog"
                                style="size: 2rem;"></i></span>
                            <div class="row mt-5" *ngIf="this.num_opcion == 0">
                                <div class="form-group col-md-10">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-5">
                                            <p-dropdown [options]="lstUsDisponibles" optionLabel="nombre_completo" placeholder="Nuevo Usuario"
                                              autoWidth="false" [style]="{'width':'100%'}" [showClear]="true" formControlName="cod_chofer"
                                              tooltip="Seleccione el chofer" tooltipPosition="left" [filter]="true"
                                              optionValue="cod_chofer"></p-dropdown>
                                        </div>
                                        <div class="col-sm-3"></div>
                                        <div class="col-sm-3">
                                            <button type="submit" class="btn btn-success w-100" [disabled]="enableDisable()">
                                            <span class="text-white">Generar usuario </span>
                                            <i class="pi pi-save text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.num_opcion == 1">
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde" placeholder="Nombre Empleado*"
                                                    formControlName="nombre_completo" required style="text-transform:uppercase;"
                                                    pTooltip="Nombre de Chofer">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde" placeholder="Apellido Paterno*"
                                                    formControlName="apellido_Paterno" required style="text-transform:uppercase;"
                                                    pTooltip="Apellido Paterno">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde"
                                                    placeholder="Nombre de Usuario*" formControlName="nom_usuario"
                                                    pTooltip="Nombre de Usuario">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde" placeholder="Contraseña*"
                                                    formControlName="pass" required
                                                    pTooltip="Contraseña de Acceso">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4 g-0 mr-5" *ngIf="this.num_opcion == 1">
                                    <div class="col-2">
                                        <button type="submit" class="btn btn-success w-100" [disabled]="enableDisable()"
                                         pTooltip="Cambiar Nombre de Usuario">
                                        <span class="text-white">Editar</span>
                                        <i class="pi pi-pencil text-white"></i>
                                        </button>
                                    </div>
                                    <div class="col">
                                        <p class="col-all-ciudades">
                                            *Si encuentra el nombre de usuario inapropiado, puede generar uno nuevo utilizando el botón Editar.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row mt-2 g-0 mr-5" *ngIf="this.num_opcion == 0">
                                <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100" [disabled]="enableDisable()">
                                    <span class="text-white">Generar usuario</span>
                                    <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div> -->
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="form-group">
                <div class="row g-0 mr-5" style="float: right">
                    <div class="col">
                        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                            (click)="cancelarMostrarAgregarUsuario()"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dialog>
</div>
