import { CiudadService } from './../services/ciudad.service';
import { ProyeccionService } from './../services/proyeccion.service';
import { IMovimientosFechaRecursos } from './../models/movfecharecursos';
import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { MovFechaService } from '../services/movfecha.service';
import { IMovimientosFecha } from '../models/movfecha';
import { Table } from 'primeng/table';
import { saveAs } from 'file-saver';
import { IrptMovDiarioFecha } from '../models/rpt_MovDiarioFecha';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRecursosMovimientos } from '../models/recursos';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IRecursosMaster } from '../models/recursos_master';
import { IRecursoCasetas } from '../models/recurso_casetas';
import { IRecursoCombustible } from '../models/recurso_combustible';
import { IPagoCaseta } from '../models/pago_caseta';
import { RecursosService } from '../services/recursos.service';
import { IrptMovDiarioFechaController } from '../models/rpt_MovDiarioFechaController';
import { ICombustible } from '../models/combustible';
import { IMes } from '../models/mes';
import { ICiudad } from '../models/ciudad';
import { IRecursosFechas, IUpdateRecursosFechas } from '../models/recursosFechas';

@Component({
  selector: 'app-movfecha',
  templateUrl: './movfecha.component.html',
  styleUrls: ['./movfecha.component.scss']
})
export class MovfechaComponent {
  lstMovimientos: IMovimientosFecha[] = [];
  lstMovimientosReporte: IrptMovDiarioFecha[] = [];
  lstMovimientosRegistro: IMovimientosFechaRecursos[] = [];
  lstMovimientosReporteRegistroRecursos: IrptMovDiarioFechaController[] = [];
  loading: boolean = false;
  loading_fechas: boolean = false;
  fecha_dia: any = null;
  fecha_fin: any = null;
  mostrarbtnLimpiarFiltro: boolean = false;
  @ViewChild('dt1') dt!: Table;
  //Registro
  user: ICredencialUsuario = {};
  dspRecursos: boolean = false; //aqui
  messageFin: string = '';
  fec_dia_reporte: any = null;
  fec_fin_reporte:  any = null;
  ButtonExportExcel: boolean = false;
  dgfechas: boolean = false;
  fec_fin_casetas: any = null;
  fec_fin_combustible: any = null;
  fec_fin_ruta: any = null;
  messageFinCasetas:string='';
  messageFinCombustible:string='';
  messageFinalizado: string = '*Ruta finalizada por el usuario';
  messageFinalizadoCombustible: string = '*Combustible finalizado por el usuario';
  messageFinalizadoCasetas: string = '*Caseta finalizado por el usuario';
  messageSinFinalizar: string = '*Ruta "NO" finalizada';
  messageSinFinalizarCasetas: string = '*Casetas "NO" finalizado';
  messageSinFinalizarCombustible: string = '*Combustible "NO" finalizado';
  dataForm: FormGroup = new FormGroup({
    imp_caseta: new FormControl(0),
    imp_combustible: new FormControl(0),
    kim_inicial: new FormControl(0),
    kim_final: new FormControl(0),
    num_litro: new FormControl(0),
    imp_litro: new FormControl(0),
    SelectedPagoCaseta: new FormControl(),
    SelectedPagoCombustible: new FormControl(),
    clv_fin_ruta: new FormControl(),
    kim_inicial_carga: new FormControl(0),
    kim_final_carga: new FormControl(0),
    SelectedTipoCombustible: new FormControl(0)
  });
  data_enviar: IRecursosMovimientos = {
    idx: 0,
    idx_pao_diario: 0,
    id_ruta: 0,
    id_unidad_transporte: 0,
    num_economico: '',
    cod_chofer: 0,
    imp_combustible: 0,
    imp_caseta: 0,
    kim_inicial: 0,
    kim_final: 0,
    cod_usuario: '',
    num_litro: 0,
    imp_litro: 0
  };
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  formfechas : FormGroup = new FormGroup({
    idx: new FormControl(0),
    clv_fin_ruta: new FormControl(0),
    clv_fin_combustible: new FormControl(0),
    clv_fin_casetas: new FormControl(0),
    fecha: new FormControl(0,Validators.required),
    fecha_fin_ruta: new FormControl(null),
    fecha_fin_combustible: new FormControl(null),
    fecha_fin_casetas: new FormControl(null),
    mod_fin_combustible: new FormControl(0),
    mod_fin_casetas: new FormControl(0),
    mod_fin_ruta: new FormControl(0),
    mod_servicio: new FormControl(0),
  });

  clv_modificarCaseta: number = 0;
  //Fecha de Dias Atras
  fecha_dos_dias_anterior!: Date;
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  desc_ruta: string = '';
  //Gestor de Recursos
  recurso: IRecursosMaster = {
    idx_pao_diario_unidades: 0,
    id_unidad_transporte: 0,
    num_economico: '',
    listCasetas: [],
    listCombustible: [],
    kim_inicial: 0,
    kim_final: 0,
    cod_usuario_registro_combustible: '',
    clv_fin_ruta: 0,
    total_casetas: 0,
    total_combustible: 0,
    clv_fin_casetas: 0,
    clv_fin_combustible: 0
  };
  listCasetas: IRecursoCasetas[] = [];
  listCombustible: IRecursoCombustible[] = [];
  lstPagoCaseta: IPagoCaseta[] =
  [{ id: 1, nombre: 'TAG' },
    /*{ id: 3, nombre: 'DEBITO' },
  { id: 2, nombre: 'EFECTIVO' }*/
  { id: 4, nombre: 'BROXEL' },
  { id: 5, nombre: 'EDENRED' },
  { id: 6, nombre: 'TELEVIA'}
  ];
  lstPagoCumbustible: IPagoCaseta[] = 
  [{ id: 1, nombre: 'SODEXO' },
  // { id: 3, nombre: 'DEBITO' },
  // { id: 2, nombre: 'EFECTIVO' },
  { id: 4, nombre: 'BROXEL' },
  { id: 5, nombre: 'EDENRED' },
  { id: 6, nombre: 'OXXOGAS' }
  ];
  //agregar otro meoto de pago

  //Variables Generales
  idx_pao_diario_unidades: number = 0;
  id_unidad_transporte: number = 0;
  num_economico: string = '';
  clv_operacion: number = 0; //lo puse fijo
  clv_operacion_fechas: number = 0;
  clv_fin_ruta: boolean = false;
  clv_fin_casetas: boolean = false;
  clv_fin_combustible: boolean = false;
  clv_fin_ruta_original: number = 0;
  fecha_del_recurso: any;
  //Fecha no se modifica
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  habilitar_tres_dias: boolean = true;
  texto_fecha_recurso: any = null;
  //para recursos de tag
  num_tag_fijo: string = '0';
  num_tag_emergente: string = '0';
  num_tarjeta_sodexo: string = '0';
  fecha_dia_real: any = null;

  //kilometraje por carga
  kim_inicial_carga:number=0;
  kim_final_carga:number=0;
  loading_combustible:boolean=false;
  loading_caseta:boolean=false;
  //Limitar comportamiento de Botones
  btnHabilitarGestion:boolean=true;
  //Nuevo Tipo de Combustible
  lstCombustible: ICombustible[] = [{id:1,nombre:'GASOLINA'},{id:2,nombre:'DIESEL'},{id:4,nombre:'UREA'}];
  //Reporte rendimiento por Colaborador
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;
  loading_reporte1:boolean=false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
    dbsReportes: boolean = false;
    lstRendimientoColaboradorReporte:any = [];
    lstCiudades: ICiudad[] = [];
    selectedCiudad: ICiudad ={
      id: 0,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private movfechaService: MovFechaService,
    private auth: AuthService,
    private recursoService: RecursosService,
    private proyeccionService:ProyeccionService,
    private ciudadService:CiudadService
  ) {
    this.cargarInformacionUsuario();
    this.dataForm.controls['num_litro'].valueChanges.subscribe(data => {
      let imp_litro_cast: number = this.dataForm.get('imp_litro')?.value == null ? 0 : this.dataForm.get('imp_litro')?.value;
      if (imp_litro_cast != 0) {
        this.dataForm.controls['imp_combustible'].setValue((data * imp_litro_cast).toFixed(2));
      }
    });
    this.dataForm.controls['imp_litro'].valueChanges.subscribe(data => {
      let num_litro_cast: number = this.dataForm.get('num_litro')?.value == null ? 0 : this.dataForm.get('num_litro')?.value;
      if (num_litro_cast != 0) {
        this.dataForm.controls['imp_combustible'].setValue((data * num_litro_cast).toFixed(2));
      }
    });

    this.formfechas.get('clv_fin_combustible')?.valueChanges.subscribe(data => {
      this.validarFechas(data,1);
    });
    this.formfechas.get('clv_fin_casetas')?.valueChanges.subscribe(data => {
      this.validarFechas(data,2);
    });
    this.formfechas.get('clv_fin_ruta')?.valueChanges.subscribe(data => {
      this.validarFechas(data,3);
    });

    this.formfechas.get('fecha_fin_combustible')?.valueChanges.subscribe(data =>{
      if(!this.loading_fechas) this.formfechas.get('mod_fin_combustible')?.setValue(1);
    });

    this.formfechas.get('fecha_fin_casetas')?.valueChanges.subscribe(data =>{
      if(!this.loading_fechas) this.formfechas.get('mod_fin_casetas')?.setValue(1);
    });

    this.formfechas.get('fecha_fin_ruta')?.valueChanges.subscribe(data =>{
      if(!this.loading_fechas) this.formfechas.get('mod_fin_ruta')?.setValue(1);
    });

    this.formfechas.get('fecha')?.valueChanges.subscribe(data =>{
      if(!this.loading_fechas) this.formfechas.get('mod_servicio')?.setValue(1);
    });
  }

  validarInformacionOdometro() {
    //Se valida si se puede finalizar ruta
    let kim_inicial: number = this.dataForm.get('kim_inicial')?.value == null ? 0 : this.dataForm.get('kim_inicial')?.value;
    let kim_final: number = this.dataForm.get('kim_final')?.value == null ? 0 : this.dataForm.get('kim_final')?.value;
    /*if (kim_inicial == 0 || kim_final == 0) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Kilometro Inicial y Final deben de tener valores diferentes a 0');
      this.clv_fin_ruta = false;
      this.messageFin = this.messageSinFinalizar;
    } else {
      this.clv_fin_ruta = true;
      this.messageFin = this.messageFinalizado;
    }*/
    if(this.clv_fin_casetas==false || this.clv_fin_combustible==false){
      this.mostrarDialogoInformativo('<b>Aviso</b> Casetas y Combustible deben estar finalizados para <b>Finalizar Ruta Completamente</b>');
      this.clv_fin_ruta = false;
      this.messageFin = this.messageSinFinalizar;
    } else {
      this.clv_fin_ruta = true;
      this.messageFin = this.messageFinalizado;
    }
  }

  validarInformacionCasetas() {
    //Se valida si se puede finalizar ruta
    this.mostrarDialogoEliminarConfirmacionCasetas('Esta seguro que desea Finalizar el Apartado de Casetas');
  }

  mostrarDialogoEliminarConfirmacionCasetas(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.clv_fin_casetas = true;
        this.messageFinCasetas = this.messageFinalizadoCasetas;
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Ruta se Finalizo de Casetas.' });
      },
      reject: () => {
        this.clv_fin_casetas = false;
        this.messageFinCasetas = this.messageSinFinalizarCasetas;
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'No se finalizo Casetas.' });
        //se reinicia en caso de estar finaliza. No se puede finalizar completamente si casetas o combustible sigue abierto. Administrador.
        this.clv_fin_ruta = false;
      },
      key: "kc"
    });
  }

  validarInformacionCombustible() {
    //Se valida si se puede finalizar ruta
    this.mostrarDialogoEliminarConfirmacionCombustible('Esta seguro que desea Finalizar el Apartado de Combustible');
  }

  mostrarDialogoEliminarConfirmacionCombustible(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.clv_fin_combustible = true;
        this.messageFinCombustible = this.messageFinalizadoCombustible;
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Ruta se Finalizo de Combustible.' });
      },
      reject: () => {
        this.clv_fin_combustible = false;
        this.messageFinCombustible = this.messageSinFinalizarCombustible;
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'No se finalizo Combustible.' });
        //se reinicia en caso de estar finaliza. No se puede finalizar completamente si casetas o combustible sigue abierto. Administrador.
        this.clv_fin_ruta = false;
      },
      key: "kc"
    });
  }


  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }


  ngOnInit(): void {
    this.inicializarFechasFormateadas();
    this.cargarListadoCiudades();
  }

  cargarListadoCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  inicializarFechasFormateadas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));

    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }


  inicializarFechasFormateadasReportes() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
    this.fec_dia_reporte = dateFormat;
    this.fec_fin_reporte = null;
  }

  cargarInformacionPorFecha(fecha: string, fecha_fin: string) {
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.lstMovimientos = [];
    this.loading = true;
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      //Cargar info del excel
      this.cargarInformacionPorFechaRecursos(fecha, fecha_fin_cast, this.user.cod!);
      this.movfechaService.getListadoDeMovimientosFecha(fecha, fecha_fin_cast, this.user.cod).subscribe((resp) => {
        this.lstMovimientos = resp;
        if(resp.length > 0)
          this.clv_operacion_fechas = resp[0].clv_operacion;
        if (!this.lstMovimientos.length || typeof this.lstMovimientos === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada de Operaciones');
        }
        this.mostrarbtnLimpiarFiltro = true;
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Operacion Diaria');
        });
    }
  }

  cargarInformacionPorFechaRecursos(fecha_inicio: string, fecha_fin: string, cod_user: string) {
    this.movfechaService.getListadoDeMovimientosFechaRecursos(fecha_inicio, fecha_fin, cod_user).subscribe((resp) => {
      this.lstMovimientosRegistro = resp;
      if (!this.lstMovimientosRegistro.length || typeof this.lstMovimientosRegistro === 'undefined') {
        //this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada de Recursos');
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de reporte Recursos');
      });
  }




  mostrarRecursos(data: IMovimientosFecha) {
    //recursos tag
    this.num_tag_fijo = data.num_tag_fijo;
    this.num_tag_emergente = data.num_tag_emergente;
    this.num_tarjeta_sodexo = data.num_tarjeta_sodexo;


    //asignar variables generales
    this.idx_pao_diario_unidades = data.idx;
    this.id_unidad_transporte = data.id_unidad_transporte;
    this.num_economico = data.num_economico;
    this.clv_operacion = data.clv_operacion;
    this.clv_fin_ruta_original = data.clv_fin_ruta;
    if (data.clv_fin_ruta == 1) {
      this.messageFin = this.messageFinalizado;
      this.clv_fin_ruta = true;
    } else if (data.clv_fin_ruta == 0) {
      this.messageFin = this.messageSinFinalizar;
      this.clv_fin_ruta = false;
    } else {
      this.messageFin = '';
      this.clv_fin_ruta = false;
    }

    //fin casetas y combustible
    if (data.clv_fin_casetas == 1) {
      this.messageFinCasetas = this.messageFinalizadoCasetas;
      this.clv_fin_casetas = true;
    } else if (data.clv_fin_casetas == 0) {
      this.clv_fin_casetas = false;
      this.messageFinCasetas = this.messageSinFinalizarCasetas;
    } else {
      this.clv_fin_casetas = false;
      this.messageFinCasetas = '';
    }

    if (data.clv_fin_combustible == 1) {
      this.clv_fin_combustible=true;
      this.messageFinCombustible = this.messageFinalizadoCombustible;
    } else if (data.clv_fin_combustible == 0) {
      this.clv_fin_combustible=false;
      this.messageFinCombustible = this.messageSinFinalizarCombustible;
    } else {
      this.clv_fin_combustible=false;
      this.messageFinCombustible = '';
    }

    //

    //asignacion de Fecha del recurso a validar
    this.fecha_del_recurso = new Date(data.fecha+'T00:00:00');
    //texto mostrar en header
    this.texto_fecha_recurso = data.fecha;

    this.dspRecursos = true;
    this.data_enviar = {
      idx: data.idx,
      idx_pao_diario: 0,
      id_ruta: data.clave_ruta,
      id_unidad_transporte: data.id_unidad_transporte,
      num_economico: data.num_economico,
      cod_chofer: data.cod_chofer,
      imp_combustible: 0,
      imp_caseta: 0,
      kim_inicial: 0,
      kim_final: 0,
      num_litro: 0,
      imp_litro: 0,
      cod_usuario: ''
    };

    this.deshabilitarEdicion();

    this.loading_combustible=true;
    this.loading_caseta=true;
    this.recursoService.getListadoRecursos(data.idx, data.id_unidad_transporte, data.num_economico).subscribe((resp) => {
      this.recurso = resp[0];
      this.dataForm.controls['kim_inicial'].setValue(this.recurso.kim_inicial);
      this.dataForm.controls['kim_final'].setValue(this.recurso.kim_final);
      this.dataForm.controls['clv_fin_ruta'].setValue(this.recurso.clv_fin_ruta);
      this.listCasetas = this.recurso.listCasetas.filter((data) => data.cod_usuario_registro != '0000');
      this.listCombustible = this.recurso.listCombustible.filter((data) => data.cod_usuario_registro != '0000');
      this.loading_combustible=false;
      this.loading_caseta=false;
      //
      if(this.listCombustible.length>0){
        //se pone el kilometro final del ultimo registro como inicial
        let kim_inicial:number=this.listCombustible.at(-1)?.kim_final_carga!;
        this.kim_inicial_carga = kim_inicial;
        this.kim_final_carga = 0;
        this.dataForm.controls['kim_inicial_carga'].setValue(kim_inicial);
      } else {
        this.kim_inicial_carga = this.recurso.kim_inicial;
        this.kim_final_carga = 0;
        this.dataForm.controls['kim_inicial_carga'].setValue(this.recurso.kim_inicial);
      }
    },
    (error)=>{
      this.loading_combustible=false;
      this.loading_caseta=false;
    });

    /*let fecha_cast = new Date(data.fecha);
    let resta = this.fecha_del_dia.getTime() - fecha_cast.getTime();
    let diferencia = Math.round(resta / (1000 * 60 * 60 * 24));
    if (diferencia > 1) {
      this.btnHabilitar = false;
      this.mostrar_texto_fechas = 1;
    } else {
      this.btnHabilitar = true;
    }*/

    //4 dias para usuario normal apartir de la fecha
    this.fecha_final_dia = new Date(this.obtenerFechaMomento()+'T00:00:00');
    this.fecha_minus3 = this.obtenerFechaDiferenciaDias(4);
    if (this.clv_operacion == 1) {
      if (this.fecha_del_recurso.getTime() >= this.fecha_minus3.getTime() && this.fecha_del_recurso.getTime() <= this.fecha_final_dia.getTime()) {
        this.habilitar_tres_dias = false;
      } else {
        this.habilitar_tres_dias = true;
        this.mostrar_texto_fechas = 1;
      }
    }
  }

  obtenerFechaMomento():string{
    let fecha:any;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    fecha = getYear + "-" + getMonth + "-" + getDay;
    return fecha;
  }

  obtenerFechaDiferenciaDias(num_dias:number):any{
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let fecha_actual = new Date(dateFormat + 'T00:00:00');
    let fecha_minus = new Date(fecha_actual.setDate(new Date(fecha_actual).getDate() - num_dias));
    return fecha_minus;
  }




  modificaFinRuta(clv_fin: number) {
    if (clv_fin == 1) {
      this.dataForm.controls['clv_fin_ruta'].disable();
    }
    this.clv_fin_ruta = clv_fin == 0 ? false : true;
    this.messageFin = this.clv_fin_ruta ? this.messageFinalizado : this.messageSinFinalizar;
  }

  cancelarModal() {
    this.dspRecursos = false;
    this.data_enviar = {
      idx: 0,
      idx_pao_diario: 0,
      id_ruta: 0,
      id_unidad_transporte: 0,
      num_economico: '',
      cod_chofer: 0,
      imp_combustible: 0,
      imp_caseta: 0,
      kim_inicial: 0,
      kim_final: 0,
      cod_usuario: '',
      num_litro: 0,
      imp_litro: 0
    };
    this.deshabilitarEdicion();
    this.clv_modificarCaseta = 0;
    this.btnHabilitar = false;
    this.mostrar_texto_fechas = 0;

    //reiniciar variables generales
    this.idx_pao_diario_unidades = 0;
    this.id_unidad_transporte = 0;
    this.num_economico = '';
    this.clv_operacion = 0;
    this.clv_fin_ruta = false;
    this.clv_fin_ruta_original = 0;

    this.listCasetas = [];
    this.listCombustible = [];

    //reiniciar tres dias
    this.habilitar_tres_dias = true;

    //tag recursos
    this.num_tag_fijo = '';
    this.num_tag_emergente = '';
    this.num_tarjeta_sodexo = '';
    //fin casetas y combustible
    this.clv_fin_casetas = false;
    this.clv_fin_combustible = false;

    this.limpiarFormularioCasetas();
    this.limpiarFormularioCombustible();
  }

  retornarKimMayor(myArr:IRecursoCombustible[]):number{
    var maximum = Math.max.apply(Math, myArr.map(o => o.kim_final_carga));
    return maximum;
  }

  retornarKimMenor(myArr:IRecursoCombustible[]):number{
    var minimum = Math.min.apply(Math, myArr.map(o => o.kim_final_carga));
    return minimum;
  }

  guardarInformacion() {
    this.btnHabilitar = true;
    this.data_enviar.imp_caseta = this.dataForm.get('imp_caseta')?.value == null ? 0 : this.dataForm.get('imp_caseta')?.value;
    this.data_enviar.kim_inicial = this.dataForm.get('kim_inicial')?.value == null ? 0 : this.dataForm.get('kim_inicial')?.value;
    this.data_enviar.kim_final = this.dataForm.get('kim_final')?.value == null ? 0 : this.dataForm.get('kim_final')?.value;
    this.data_enviar.num_litro = this.dataForm.get('num_litro')?.value == null ? 0 : this.dataForm.get('num_litro')?.value;
    this.data_enviar.imp_litro = this.dataForm.get('imp_litro')?.value == null ? 0 : this.dataForm.get('imp_litro')?.value;
    this.data_enviar.imp_combustible = this.dataForm.get('imp_combustible')?.value == null ? 0 : this.dataForm.get('imp_combustible')?.value;
    //Filtrar registros Nuevos-Casetas
    //Filtrar Registros Nuevos-Combustible
    let data_enviar: IRecursosMaster = {
      idx_pao_diario_unidades: this.recurso.idx_pao_diario_unidades,
      id_unidad_transporte: this.recurso.id_unidad_transporte,
      num_economico: this.recurso.num_economico,
      listCasetas: this.listCasetas.length == 0 ? [] : this.filtrarCasetas(),
      listCombustible: this.listCombustible.length == 0 ? [] : this.filtrarCombustible(),
      kim_inicial: this.data_enviar.kim_inicial,
      kim_final: this.data_enviar.kim_final,
      cod_usuario_registro_combustible: this.user.cod!,
      clv_fin_ruta: this.clv_fin_ruta == true ? 1 : 0,
      total_casetas: 0,
      total_combustible: 0,
      clv_fin_casetas: this.clv_fin_casetas == true ? 1 : 0,
      clv_fin_combustible: this.clv_fin_combustible == true ? 1 : 0
    };
    this.recursoService.postRecursos(data_enviar).subscribe((resp) => {
      this.resultado = resp;
      if (this.resultado.clv_estatus == 1) {
        setTimeout(() => {
          this.btnHabilitar = false;
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: 'Información se actualizo correctamente.' });
          this.deshabilitarEdicion();
          this.cargarInformacionPorFecha(this.fecha_dia, this.fecha_fin);
          this.cancelarModal();
        }, 1000);
      } else if (this.resultado.clv_estatus == 0) {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en <guardar Recurso>. Contacte al administrador.' });
      }
    },
      (error) => {
        this.btnHabilitar = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en <guardar Recurso>. Contacte al administrador.' });
      });
  }

  filtrarCasetas(): IRecursoCasetas[] {
    let listCasetas: IRecursoCasetas[] = [];
    if (this.clv_operacion == 1) {
      if (this.listCasetas.length > 0) {
        this.listCasetas.map((valor) => {
          if (valor.clv_guardado == 0) {
            listCasetas.push(valor);
          } else if (valor.clv_guardado == 3) {
            listCasetas.push(valor);
          }
        });
      }

    } else if (this.clv_operacion == 3) {
      //Es Administrador
      if (this.listCasetas.length > 0) {
        this.listCasetas.map((valor) => {
          if (valor.clv_guardado == 0) {
            listCasetas.push(valor);
          } else if (valor.clv_guardado == 3) {
            listCasetas.push(valor);
          }
        });
      }
    }
    return listCasetas;
  }

  filtrarCombustible(): IRecursoCombustible[] {
    let listCombustible: IRecursoCombustible[] = [];
    if (this.clv_operacion == 1) {
      if (this.listCombustible.length > 0) {
        this.listCombustible.map((valor) => {
          if (valor.clv_guardado == 0) {
            listCombustible.push(valor);
          } else if (valor.clv_guardado == 3) {
            listCombustible.push(valor);
          }
        });
      }
    } else if (this.clv_operacion == 3) {
      //Es Administrador
      if (this.listCombustible.length > 0) {
        this.listCombustible.map((valor) => {
          if (valor.clv_guardado == 0) {
            listCombustible.push(valor);
          } else if (valor.clv_guardado == 3) {
            listCombustible.push(valor);
          }
        });
      }
    }
    return listCombustible;
  }


  habilitarEdicion() {
    this.dataForm.controls['SelectedPagoCaseta'].enable();
    this.dataForm.controls['SelectedPagoCombustible'].enable();
    this.dataForm.controls['imp_caseta'].enable();
    this.dataForm.controls['imp_combustible'].disable();
    this.dataForm.controls['kim_inicial'].enable();
    this.dataForm.controls['kim_final'].enable();
    this.dataForm.controls['num_litro'].enable();
    this.dataForm.controls['imp_litro'].enable();

    //
    this.dataForm.controls['kim_inicial_carga'].enable();
    this.dataForm.controls['kim_final_carga'].enable();
    this.dataForm.controls['kim_inicial_carga'].setValue(this.kim_inicial_carga);
    this.dataForm.controls['kim_final_carga'].setValue(0);
    //
    this.btnHabilitarGestion = false;
  }

  deshabilitarEdicion() {
    this.dataForm.controls['SelectedPagoCaseta'].disable();
    this.dataForm.controls['SelectedPagoCombustible'].disable();
    this.dataForm.controls['imp_caseta'].disable();
    this.dataForm.controls['imp_combustible'].disable();
    this.dataForm.controls['kim_inicial'].disable();
    this.dataForm.controls['kim_final'].disable();
    this.dataForm.controls['num_litro'].disable();
    this.dataForm.controls['imp_litro'].disable();
    //
    this.dataForm.controls['kim_inicial_carga'].disable();
    this.dataForm.controls['kim_final_carga'].disable();
    //
    this.btnHabilitarGestion=true;
  }

  mostrarDialogoEliminarConfirmacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "kc"
    });
  }




  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

  LimpiarFiltro() {
    this.lstMovimientos = [];
    this.lstMovimientosReporte = [];
    this.fecha_dia = null;
    this.fecha_fin = null;
    this.limpiarFiltrosTabla();
    this.mostrarbtnLimpiarFiltro = false;

    //limpiar reporte recursos
    this.lstMovimientosRegistro = [];
    this.lstMovimientosReporteRegistroRecursos = [];
  }

  limpiarFiltrosTabla() {
    this.dt.clear();
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


//--------------------itzael
  asignarArregloParaExportar(data: IMovimientosFecha[]) {
    data.map((valor) => {
      this.lstMovimientosReporte.push({
        NombreRuta: valor.desc_ruta,
        Folio: valor.num_folio,
        FolioProyecto: valor.num_folio_proyecto,
        TipoDeRuta: valor.desc_tiporuta,
        Cliente: valor.desc_cliente,
        Proyecto: valor.desc_proyecto,
        Ciudad: valor.desc_ciudad,
        NumeroEconomico: valor.num_economico,
        DatosUnidad: valor.datos_generales,
        NombreChofer: valor.nombre_chofer,

        //-----------------------
        Adicional1: valor.desc_puesto_adicional1? '('+ valor.desc_puesto_adicional1 +') ' + valor.nombre_completo_adicional1:'',
        Adicional2:valor.desc_puesto_adicional2? '('+ valor.desc_puesto_adicional2 +') ' + valor.nombre_completo_adicional2: '',

        Gerente: valor.nombre_gerentes,
        Coordinador: valor.nombre_coordinadores,
        Supervisor: valor.nombre_supervisores,
        FechaRuta: valor.fecha,
        //-----------------------



        CasetasPases: valor.imp_caseta_1,
        CasetasBROXEL: valor.imp_caseta_2,
        CasetasEDENRED: valor.imp_caseta_3,
        CasetasTELEVIA: valor.imp_caseta_4,
        MontoTotalDeCasetas : valor.imp_caseta,
        CombustibleSODEXO: valor.imp_combustible_1,
        CombustibleBROXEL: valor.imp_combustible_2,
        CombustibleEDENRED: valor.imp_combustible_3,
        CombustibleOXXOGAS: valor.imp_combustible_4,
        MontoTotalDeCombustible : valor.imp_combustible,
        //-------


        KilometroInicial: valor.kim_inicial,
        KilometroFinal: valor.kim_final,
        TotalLitros: valor.num_litros_total,
        TotalKilometros: valor.num_kim_total,
        TotalRendimiento: valor.num_rendimiento_total,
        EstatusRecursos: valor.clv_fin_ruta == 0 ? 'NO FINALIZADA' : 'FINALIZADA',
        EstatusRuta: valor.clv_cancelado == 0 ? '' : 'CANCELADA'
      });
    });
  }

  exportarExcelRecursos() {
    this.lstMovimientosReporteRegistroRecursos = [];
    if (!this.lstMovimientosRegistro.length || typeof this.lstMovimientosRegistro === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      this.asignarArregloParaExportarRecursos(this.lstMovimientosRegistro);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstMovimientosReporteRegistroRecursos);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        let texto: string = this.fecha_fin == '1900-01-01' || this.fecha_fin == null ? '' : '_Al_' + this.fecha_fin;
        this.saveAsExcelFile(excelBuffer, "MovimientosOperacionDiariaRecursos" + '_' + this.fecha_dia + texto);
      });
    }
  }

  asignarArregloParaExportarRecursos(data: IMovimientosFechaRecursos[]) {
    data.map((valor) => {
      this.lstMovimientosReporteRegistroRecursos.push({
        NombreRuta: valor.desc_ruta,
        NumFolio : valor.num_folio,
        NumFolioProyecto: valor.num_folio_proyecto,
        TipoDeRuta: valor.desc_tiporuta,
        Cliente: valor.desc_cliente,
        Proyecto: valor.desc_proyecto,
        Ciudad: valor.desc_ciudad,
        NumeroEconomico: valor.num_economico,
        DatosUnidad: valor.datos_generales,
        NombreChofer: valor.nombre_chofer,
        FechaRecurso: valor.fecha,
        NumeroCasetas: valor.num_casetas,
        CasetasPases: valor.imp_caseta_1,
        CasetasBROXEL: valor.imp_caseta_2,
        CasetasEDENRED: valor.imp_caseta_3,
        CasetasTELEVIA: valor.imp_caseta_4,
        MontoTotalDeCasetas : valor.imp_caseta,
        NumeroCargas: valor.num_cargas,
        CombustibleSODEXO: valor.imp_combustible_1,
        CombustibleBROXEL: valor.imp_combustible_2,
        CombustibleEDENRED: valor.imp_combustible_3,
        CombustibleOXXOGAS: valor.imp_combustible_4,
        MontoTotalDeCombustible : valor.imp_combustible,
        KilometroInicial: valor.kim_inicial,
        KilometroFinal: valor.kim_final,
        EstatusRecursos: valor.clv_fin_ruta == 0 ? 'NO FINALIZADA' : 'FINALIZADA',
        EstatusRuta: valor.clv_cancelado == 0 ? '' : 'CANCELADA'
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName /*+ "_export_" + dateFormat */ + EXCEL_EXTENSION);

  }

  agregarCasetas() {
    //agregar a Tabla
    let tipo_pago: number = this.dataForm.get('SelectedPagoCaseta')?.value;
    let cod_user: string = this.user.cod == 'undefined' ? '0999' : this.user.cod!;
    let imp_caseta: number = this.dataForm.get('imp_caseta')?.value;
    var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric',hour12: false});
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    //var fecha_completa = this.fecha_dia_real + 'T' + formattedDate;
    var fecha_completa = fecha_registro_momento + 'T' + formattedDate;
    let data_caseta: IRecursoCasetas = {
      id_caseta: 0,
      tipo_pago: tipo_pago,
      desc_pago: this.obtenerNombrePagoCaseta(tipo_pago),
      imp_caseta: imp_caseta,
      fec_registro: fecha_completa,
      cod_usuario_registro: cod_user,
      clv_guardado: 0,
      num_tag_fijo: this.num_tag_fijo,
      num_tag_emergente: this.num_tag_emergente
    };
    if ((tipo_pago == null || tipo_pago == 0) || (imp_caseta == 0 || imp_caseta == null)) {
    } else {
      this.listCasetas.push(data_caseta);
      this.limpiarFormularioCasetas();
    }
  }

  limpiarFormularioCasetas() {
    this.dataForm.controls['imp_caseta'].setValue(0);
    this.dataForm.controls['SelectedPagoCaseta'].setValue(null);
  }

  obtenerNombrePagoCaseta(id: number): string {
    let nombre: string = '';
    if (this.lstPagoCaseta.length > 0) {
      this.lstPagoCaseta.map((valor) => {
        if (valor.id == id) {
          nombre = valor.nombre;
        }
      });
    }
    return nombre;
  }

  eliminarCasetas(data: IRecursoCasetas) {
    if(this.btnHabilitarGestion==true){
      //no hace nada sino se habilita edicion
    } else {
      if (this.clv_operacion == 3 && data.clv_guardado == 1) {
        //admin puede eliminar registros guardados, clv_guardado=3 no se visualiza en la tabla
        let mensaje_usuario='Esta seguro que desea eliminar el registro permanente <b>('+data.desc_pago+'- $'+data.imp_caseta+')</b>';
        this.confirmationService.confirm({
          message: mensaje_usuario,
          header: 'Informativo',
          icon: 'pi pi-info-circle',
          accept: () => {
            data.clv_guardado = 3;
            this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'El registro se eliminara al <Guardar Todo>.' });
          },
          reject: () => {
          },
          key: "kc"
        });
      } else if(this.clv_operacion == 1 && data.clv_guardado == 1) {
        //no se elimina nada si es usuario normal y la informacion ya esta registrada
      } else {
        this.eliminarObjetoArray(this.listCasetas, data);
      }
    }
  }

  eliminarCombustible(data: IRecursoCombustible) {
    if(this.btnHabilitarGestion==true){
      //no hace nada sino se habilita edicion
    } else {
      if (this.clv_operacion == 3 && data.clv_guardado == 1) {
        //admin puede eliminar registros guardados, clv_guardado=3 no se visualiza en la tabla
        let mensaje_usuario='Esta seguro que desea eliminar el registro permanente <b>('+data.desc_pago+'- $'+data.imp_total_combustible+')</b>';
        this.confirmationService.confirm({
          message: mensaje_usuario,
          header: 'Informativo',
          icon: 'pi pi-info-circle',
          accept: () => {
            data.clv_guardado = 3;
            this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'El registro se eliminara al <Guardar Todo>.' });
          },
          reject: () => {
          },
          key: "kc"
        });
      } else if(this.clv_operacion == 1 && data.clv_guardado == 1) {
        //no se elimina nada si es usuario normal
      } else {
        //los registros locales se pueden eliminar
        this.eliminarObjetoArray(this.listCombustible, data);
      }
    }
  }

  public eliminarObjetoArray(arr: any, item: any) {
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  }


  agregarCombustible() {
    let tipo_pago: number = this.dataForm.get('SelectedPagoCombustible')?.value;
    let tipo_combustible: number = this.dataForm.get('SelectedTipoCombustible')?.value;
    let cod_user: string = this.user.cod == 'undefined' ? '0999' : this.user.cod!;
    let num_litro = this.dataForm.get('num_litro')?.value == null ? 0 : this.dataForm.get('num_litro')?.value;
    let imp_litro = this.dataForm.get('imp_litro')?.value == null ? 0 : this.dataForm.get('imp_litro')?.value;
    let kim_inicial_carga = this.dataForm.get('kim_inicial_carga')?.value == null ? 0 : this.dataForm.get('kim_inicial_carga')?.value;
    let kim_final_carga = this.dataForm.get('kim_final_carga')?.value == null ? 0 : this.dataForm.get('kim_final_carga')?.value;
    let imp_combustible = this.dataForm.get('imp_combustible')?.value == null ? 0 : this.dataForm.get('imp_combustible')?.value;
    var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric',hour12: false});
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    //var fecha_completa = this.fecha_dia_real + 'T' + formattedDate;
    var fecha_completa = fecha_registro_momento + 'T' + formattedDate;
    let data_combustible: IRecursoCombustible = {
      id_combustible: 0,
      num_litros_combustible: num_litro,
      imp_litro: imp_litro,
      tipo_pago: tipo_pago,
      desc_pago: this.obtenerNombrePagoCumbustible(tipo_pago),
      imp_total_combustible: imp_combustible,
      fec_registro: fecha_completa,
      cod_usuario_registro: cod_user,
      clv_guardado: 0,
      num_tarjeta_sodexo: this.num_tarjeta_sodexo,
      kim_inicial_carga: kim_inicial_carga,
      kim_final_carga: kim_final_carga,
      kim_recorridos:0,
      num_rendimiento:-1,
      tipo_combustible: tipo_combustible
    };
    if(tipo_combustible==4){
      //UREA
      if ((num_litro == 0 || num_litro == null) ||
      (imp_litro == 0 || imp_litro == null) || (tipo_pago == 0 || tipo_pago == null)) {
        this.mostrarDialogoInformativo('Falta información obligatoria a capturar(Numero Litros,Imp.Litro,Tipo de Pago).Verifique.');
    } else  {
      data_combustible.kim_inicial_carga=0;
      data_combustible.kim_final_carga=0;
      data_combustible.kim_recorridos=0;
      data_combustible.num_rendimiento=0;
      this.listCombustible.push(data_combustible);
      this.limpiarFormularioCombustible();
    }
    } else if(tipo_combustible==1 || tipo_combustible==2) {
      //OTROS TIPOS DE COMBUSTIBLE(Gasolina, Diesel)
      if ((num_litro == 0 || num_litro == null) ||
      (imp_litro == 0 || imp_litro == null) || (tipo_pago == 0 || tipo_pago == null)
      || (kim_inicial_carga == 0 || kim_inicial_carga == null)
      || (kim_final_carga == 0 || kim_final_carga == null)) {
        this.mostrarDialogoInformativo('Falta información obligatoria a capturar(Numero Litros,Imp.Litro,Tipo de Pago,Tipo Combustible,Kilometraje Inicial y Final). Verifique.');
    } else {
      if(kim_final_carga<kim_inicial_carga){
        this.mostrarDialogoInformativo('Kilometro Final no puede ser menor al Kilometro Inicial. Verifique.');
      } else {
        this.listCombustible.push(data_combustible);
        this.limpiarFormularioCombustible();
      }
    }
    } else {
      //Es un tipo de Combustible invalido
      this.mostrarDialogoInformativo('Falta seleccionar Tipo de Combustible. Verifique.');
    }


    //kilometro inicial y final
    let mayor: number = this.retornarKimMayor(this.listCombustible);
    let menor: number = this.retornarKimMenor(this.listCombustible);
    //asignar el kilometraje mayor
    this.dataForm.controls['kim_final'].setValue(data_combustible.kim_final_carga);
  }

  obtenerNombrePagoCumbustible(id: number): string {
    let nombre: string = '';
    if (this.lstPagoCumbustible.length > 0) {
      this.lstPagoCumbustible.map((valor) => {
        if (valor.id == id) {
          nombre = valor.nombre;
        }
      });
    }
    return nombre;
  }

  limpiarFormularioCombustible() {
    this.dataForm.controls['imp_combustible'].setValue(0);
    this.dataForm.controls['num_litro'].setValue(0);
    this.dataForm.controls['imp_litro'].setValue(0);
    this.dataForm.controls['SelectedPagoCombustible'].setValue(null);
    this.dataForm.controls['SelectedTipoCombustible'].setValue(null);
    //asignar el nuevo kilometro inicial del ultimo agregado
    let kim_fin= this.dataForm.get('kim_final_carga')?.value == null ? 0 : this.dataForm.get('kim_final_carga')?.value;
    this.dataForm.controls['kim_inicial_carga'].setValue(kim_fin);
    this.dataForm.controls['kim_final_carga'].setValue(0);
  }

  abrirModalReportes(){
    this.dbsReportes = true;
    this.inicializarFechasFormateadasReportes();
  }

  cancelarModalReportes(){
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad ={
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    // Fin Reporte 1
    this.fec_dia_reporte = null;
    this.fec_fin_reporte = null;
  }

  generarReporte1() {
    this.loading_reporte1 = true;
    this.lstRendimientoColaboradorReporte = [];
    if ((this.selectedMesReporte1 == null || this.selectedMesReporte1.num_mes == -1) || (this.anioReporte1 === 'undefined' || this.anioReporte1 == null)
    || (this.selectedCiudad == null || this.selectedCiudad.id == -1)) {
      this.mostrarMensajeDeAlerta();
      this.loading_reporte1 = true;
    } else {
      let mes = this.selectedMesReporte1.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporte1 instanceof Date) {
        // will execute
        anio_cast = this.anioReporte1.getFullYear();
      } else {
        anio_cast = this.anioReporte1;
      }
      this.cargarInformacionDelMes(this.selectedCiudad.id, mes, anio_cast);
    }
  }



  exportarExcel() {
    this.lstMovimientosReporte = [];
    if (!this.lstMovimientos.length || typeof this.lstMovimientos === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
    } else {
      this.asignarArregloParaExportar(this.lstMovimientos);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstMovimientosReporte);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        let texto: string = this.fecha_fin == '1900-01-01' || this.fecha_fin == null ? '' : '_Al_' + this.fecha_fin;
        this.saveAsExcelFile(excelBuffer, "MovimientosOperacionDiaria" + '_' + this.fecha_dia + texto);
      });
    }
  }



  exportarExcelKilometros(fec_dia_reporte: string, fec_fin_reporte: string) {
    let fecha_fin_cast = fec_fin_reporte == null ? '1900-01-01' : fec_fin_reporte;
    this.loading_reporte1 = true;
    if (fec_dia_reporte == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.movfechaService.getListadoKilometraje(fec_dia_reporte, fecha_fin_cast)
        .subscribe((resp) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {

            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });
              let texto: string = fec_fin_reporte == '1900-01-01' || fec_fin_reporte == null ? '' : '_Al_' + fec_fin_reporte;
              this.saveAsExcelFile(excelBuffer, "Kilometraje" + '_' + fec_dia_reporte + texto);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Kilometraje.' });
          });
    }
  }



  cargarInformacionDelMes(id_ciudad_hm: number, num_mes: number, num_anio: number) {
    // aqui se manda el servicio a llamar se llena rpt_ListadoUnidades
    this.proyeccionService.getListadoRendimientoMensual(id_ciudad_hm, num_mes, num_anio).subscribe((resp) => {
      this.lstRendimientoColaboradorReporte = resp;
      if (this.lstRendimientoColaboradorReporte.length == 0 || typeof this.lstRendimientoColaboradorReporte === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe información para exportar(Excel)');
      } else {
        //Validar si tienen información
        let nombre:string='ReporteRendimientoMensual_'+this.selectedCiudad.nombre+'_'+this.selectedMesReporte1.nombre+'_'+num_anio;
        this.asignarArregloParaExportarDinamico(this.lstRendimientoColaboradorReporte,nombre);
        setTimeout(() => {
          this.loading_reporte1 = false;
        },
          800);
      }
      this.loading_reporte1 = false;
    },
      (error) => {
        this.loading_reporte1 = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <ReporteRendimientoMensual>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  asignarArregloParaExportarDinamico(data: any[],nombre_reporte:string) {
    let data_cast: any = [];
    let header: any = {};
    for (const dat of data) {
      //
      /*Aqui se debe inicializar el header*/
      //
      header = {};
      for (const [key, value] of Object.entries(dat)) {
        if (key === "nombrechofer") {
          header.NombreChofer = value;
        } else {
          header[key.charAt(0).toUpperCase() + key.slice(1)] = value;
        }
      }
      data_cast.push(header);
    }
    ;
    if (data_cast.length > 0) {
      this.generarReporteExcelCasteado(data_cast, nombre_reporte);
    } else {
      this.mostrarDialogoInformativo('No existe información para generar el Reporte.');
    }
  }

  generarReporteExcelCasteado(data: any, nombre_reporte: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      let texto: string = this.fecha_fin == '1900-01-01' || this.fecha_fin == null ? '' : '_Al_' + this.fecha_fin;
      this.saveAsExcelFile(excelBuffer, nombre_reporte);
    });
  }

  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
    });
  }
  cancelarGestionFechas(){
    this.dgfechas= false;
    this.formfechas.reset();
    console.log(this.formfechas);
  }
  mostrarFechasFin(idx: number){
    this.loading_fechas = true;
    let dsc_ruta = this.lstMovimientos.find(x=> x.idx == idx)?.desc_ruta;
    this.desc_ruta = typeof dsc_ruta !== 'undefined' ? dsc_ruta:'';
    this.movfechaService.getFechaRecursos(idx).subscribe(resp => {
      
      if(resp.idx != 0 || typeof resp !== 'undefined')
        this.setFechasRecursos(resp);
      else
      {
        this.loading_fechas = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Fechas Servicio>', detail: 'Contacte al Administrador del Sitio' });
      }
        // this.btnHabilitar = false;
        // this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: 'Información se actualizo correctamente.' });
        // this.deshabilitarEdicion();
        // this.cargarInformacionPorFecha(this.fecha_dia, this.fecha_fin);
        // this.cancelarModal();
      // setTimeout(() => {
      // }, 600);
    },(error) => {
      this.loading_fechas = false;
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Fechas Servicio>', detail: 'Contacte al Administrador del Sitio' });
    },() => {
      this.loading_fechas = false;
      this.dgfechas = true;
    });

  }
  setFechasRecursos(data: IRecursosFechas)
  {
    let fecha: any = 
      data.fecha == null ? null : data.fecha.toString().substring(0,10);
      //|| typeof data.fecha != undefined ? data.fecha.toString().substring(0,10) : null;
    let fecha_fin_casetas: any = 
      data.fecha_fin_casetas == null ? null : data.fecha_fin_casetas.toString().substring(0,10);
      //|| typeof data.fecha_fin_casetas != undefined ? data.fecha_fin_casetas.toString().substring(0,10) : null;
    let fecha_fin_combustible: any = 
      data.fecha_fin_combustible == null ? null : data.fecha_fin_combustible.toString().substring(0,10);
      //|| typeof data.fecha_fin_combustible != undefined ? data.fecha_fin_combustible.toString().substring(0,10) : null;
    let fecha_fin_ruta: any = 
      data.fecha_fin_ruta == null ? null : data.fecha_fin_ruta.toString().substring(0,10);
      //|| typeof data.fecha_fin_ruta != undefined ? data.fecha_fin_ruta.toString().substring(0,10) : null;
    
    // setTimeout(() => {
        this.formfechas.get('idx')?.setValue(data.idx);
        this.formfechas.get('fecha')?.setValue(fecha);
        this.formfechas.get('fecha_fin_casetas')?.setValue(fecha_fin_casetas);
        this.formfechas.get('fecha_fin_combustible')?.setValue(fecha_fin_combustible);
        this.formfechas.get('fecha_fin_ruta')?.setValue(fecha_fin_ruta);

        this.formfechas.get('clv_fin_combustible')?.setValue(data.clv_fin_combustible);
        this.formfechas.get('clv_fin_casetas')?.setValue(data.clv_fin_casetas);
        this.formfechas.get('clv_fin_ruta')?.setValue(data.clv_fin_ruta);

        
        this.loading_fechas = true;
        this.loading_fechas = false;
    // }, 600);
  }

  validarFechas(data:number,ty: number)
  {
    let typclv:any='';

    switch(ty)
    {
      case 1:
        typclv= 'fecha_fin_combustible';
        break;
      case 2:
        typclv= 'fecha_fin_casetas';
        break;
      case 3:
        typclv = 'fecha_fin_ruta';
        break;
      default:
        typclv= 'fecha';
        break;
    };
    console.log(typclv);
    this.formfechas.get(typclv.toString())?.clearValidators();

    if(data == 1)
    {
      this.formfechas.get(typclv.toString())?.enable();
      this.formfechas.get(typclv.toString())?.setValidators(Validators.required);
    }
    else
    {
      this.formfechas.get(typclv.toString())?.setValue(null);
      this.formfechas.get(typclv.toString())?.disable();
    }
  }

  guardarFechas(){
    let mssg = '.';
    let data = {
      idx: this.formfechas.get('idx')?.value,
      fecha_servicio: this.formfechas.get('fecha')?.value,
      mod_servicio: this.formfechas.get('mod_servicio')?.value,
      fecha_fin_casetas: this.formfechas.get('fecha_fin_casetas')?.value,
      mod_fin_casetas: this.formfechas.get('mod_fin_casetas')?.value,
      fecha_fin_combustible: this.formfechas.get('fecha_fin_combustible')?.value,
      mod_fin_combustible: this.formfechas.get('mod_fin_combustible')?.value,
      fecha_fin_ruta: this.formfechas.get('fecha_fin_ruta')?.value,
      mod_fin_ruta: this.formfechas.get('mod_fin_ruta')?.value,
      cod_usuario: this.user.cod == 'undefined' ? '0999' : this.user.cod!
    }
    console.log(this.formfechas);

    if(data.mod_fin_ruta == 1) mssg = 'Fin de Ruta'+mssg;
    if(data.mod_fin_combustible == 1){ 
      if(data.mod_fin_ruta == 1) mssg = ","+mssg; 
      mssg = "Fin de Combustible"+mssg; 
    }
    if(data.mod_fin_casetas == 1){ 
      if(data.mod_fin_combustible == 1 || data.mod_fin_ruta == 1) mssg = ","+mssg; 
      mssg = "Fin de Casetas"+mssg;
    }
    if(data.mod_servicio == 1){
      if(data.mod_fin_combustible == 1 || data.mod_fin_ruta == 1 || data.mod_fin_casetas == 1) mssg = ","+mssg; 
      mssg = "Servicio"+mssg;
    }

    this.confirmationService.confirm({
      message: 'Esta seguro que desea modificar las fechas correspondientes a <b>' 
        + mssg + '</b>?',
      header: 'Confirmacion de modificación de fechas',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.movfechaService.updateFechaRecursos(data).subscribe(resp =>{
          console.log(resp);
          if(typeof resp === 'undefined' || resp.clv_estatus == -2)
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
          else{
            this.dgfechas = false;
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: 'Información se actualizo correctamente.' });
            this.cargarInformacionPorFecha(this.fecha_dia,this.fecha_fin);
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado la operación' });
            break;
          case ConfirmEventType.CANCEL:          
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado la operación' });
            break;
        }
      }
      ,key: "kc"
    });
  }

}
