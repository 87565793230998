<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<!-- <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog> -->
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 95%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-users mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">Listado de Colaboradores</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-primary w-100" (click)="redireccionarNueva(1);"
                                    pTooltip="Nuevo Colaborador" tooltipPosition="top">
                                    Agregar <i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstChofer" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll"
                                [globalFilterFields]="['cod_Chofer','nombres','apellido_Paterno','apellido_Materno','desc_tipo_transporte','desc_ciudad','rfc']"
                                [paginator]="true" [rows]="50" [rowsPerPageOptions]="[20,45,50,100,150]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading" sortField="nombres">
                                <ng-template pTemplate="caption">
                                    <div class="row flex justify-content-between">
                                        <div class="col-md-3">
                                            <button type="button" pButton pRipple class="p-button-success ml-5"
                                                pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right"
                                                (click)="exportarExcel();">
                                                <i class="pi pi-file-excel">Exportar</i>
                                            </button>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter field="clv_activo" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="lstEstatus"
                                                        optionLabel="nombre" optionValue="id"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por Estatus" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter type="text" field="desc_puesto" placeholder="Puesto"
                                                class="w-100 sinborde filtro"></p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="p-input-icon-left p-ml-auto">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" size="30"
                                                    (input)="applyFilterGlobal($event, 'contains')"
                                                    placeholder="RFC,Codigo Chofer,Nombres,Apellidos,Ciudad" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="cod_Chofer">#Codigo<p-sortIcon
                                                field="cod_Chofer"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="rfc">RFC<p-sortIcon field="rfc"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombres">#Nombre(s)<p-sortIcon
                                                field="nombres"></p-sortIcon></th>
                                        <th>Apellido Paterno</th>
                                        <th>Apellido Materno</th>
                                        <th>Puesto</th>
                                        <th pSortableColumn="desc_tipo_transporte">Tipo de Transporte<p-sortIcon
                                                field="desc_tipo_transporte"></p-sortIcon></th>
                                        <th style="width: 180px;" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                                                field="desc_ciudad"></p-sortIcon></th>
                                        <th style="width: 130px;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.cod_Chofer}}</p>
                                        </td>
                                        <td class="font-weight-bold text-center">{{data.rfc}}</td>
                                        <td>{{data.nombres}}</td>
                                        <td>{{data.apellido_Paterno}}</td>
                                        <td>{{data.apellido_Materno}}</td>
                                        <td>{{data.desc_puesto}}</td>
                                        <td [ngClass]="{'texto-ligero': data.clave_tipoTransporte==1,'texto-pesado': data.clave_tipoTransporte==2}"
                                            class="text-justify">{{data.desc_tipo_transporte}}</td>
                                        <td style="width: 180px;">{{data.desc_ciudad}}</td>
                                        <td style="width: 130px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Información"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data,2);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus" tooltipPosition="top"
                                                    (click)="confirmarEliminar(data.cod_Chofer,data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstRutas ? lstRutas.length : 0 }} Registros.
                                    </div>
                                </ng-template>-->
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="mt-3 mb-2">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                        [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Colaborador Inactivo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--PANTALLA DE GESTION DEL COLABORADOR-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspChofer" [style]="{width: '70vw','height':'600px'}"
        [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            {{ texto_operacion}}
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_cliente" [formGroup]="ChoferForm" (ngSubmit)="guardarInformacion()">
                        <div class="ml-1 px-1">
                            <span class="title-light">Información del Colaborador<i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-lock color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11" pTooltip="Identificador del Colaborador"
                                            tooltipPosition="top">
                                            <input type="text" class="form-control sinborde" placeholder="***"
                                                formControlName="cod_Chofer" style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Nombre*"
                                                formControlName="nombres" style="text-transform:uppercase;"
                                                pTooltip="Nombre" tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Apellido Paterno*" formControlName="apellido_Paterno"
                                                style="text-transform:uppercase;" pTooltip="Apellido Paterno"
                                                tooltipPosition="left">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Apellido Materno*" formControlName="apellido_Materno"
                                                style="text-transform:uppercase;" pTooltip="Apellido Materno"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11 pl-2">
                                            <p-dropdown [options]="lstTransporte" optionLabel="nombre"
                                                formControlName="clave_tipoTransporte"
                                                placeholder="Seleccione Tipo Transporte*" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="clave_tipoTransporte"
                                                [showClear]="true"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11 pl-2">
                                            <p-dropdown [options]="lstciudades" optionLabel="nombre"
                                                formControlName="id_ciudad_hm" placeholder="Seleccione Ciudad*"
                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Prestamo</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <p-dropdown [options]="lstciudades_prestadas" optionLabel="nombre"
                                                formControlName="id_ciudad_hm_prestada" placeholder="Ciudad Prestamo"
                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                [showClear]="true"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="puestos" optionLabel="nombre"
                                                formControlName="id_puesto_chofer" placeholder="Seleccione Puesto*"
                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                [showClear]="true" tooltip="Puesto"
                                                tooltipPosition="right"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Ingreso</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                formControlName="fec_alta">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">RFC</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <input style="text-transform:uppercase;" type="text"
                                                class="form-control sinborde" pTooltip="RFC" tooltipPosition="top"
                                                formControlName="rfc" (keyup.enter)="validarInformacionRFC()"
                                                (keyup.Space)="validarInformacionRFC()"
                                                (keyup.shift)="validarInformacionRFC()"
                                                (keyup)="validarInformacionRFCAlTeclear()">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6" *ngIf="mostrarBajaEdit">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                            <p class="color-icon mt-2 ml-2"
                                                style="display: inline-block; font-weight: bold;">Fecha de Baja</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha baja" tooltipPosition="top"
                                                formControlName="fec_baja">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <p *ngIf="!ChoferForm.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                            La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="ChoferForm.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                            Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        [disabled]="btnValidaGuardar()" pTooltip="Almacenar informacion"
                                        tooltipPosition="right">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-6">
                                    <p *ngIf="ChoferForm.controls['id_ciudad_hm'].disabled">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                        Cargando Informacion de Ciudades
                                    </p>
                                </div>
                                <div class="col-6">
                                    <p *ngIf="ChoferForm.controls['clave_tipoTransporte'].disabled">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                        Cargando Informacion de Transporte
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgConfirmBaja"
    [style]="{width: '45vw','height':'400px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarDialogBajaColaborador()">
        <ng-template pTemplate="header">
            {{dgBajaHeader}}
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="row" >
                    <div class="col-md-12">
                    <p class="d-flex align-items-center texto-valido">{{dgBody}}</p>
                    </div>
                </div>
            </div>
            <form [formGroup]="ChoferBajaForm" (ngSubmit)="guardarInformacionDesactivar()">
                <div class="form-group col-md-6">
                    <div class="row g-0 mt-3">
                        <div class="col-sm-6 mx-0 d-flex align-items-center">
                            <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                    style="font-size: 2rem"></i>
                            </span>
                            <p class="color-icon mt-2 ml-2"
                                style="display: inline-block; font-weight: bold;">Fecha de Baja</p>
                        </div>
                        <div class="col-sm-6">
                            <input type="date" class="form-control sinborde"
                                pTooltip="Seleccione o teclee fecha baja" tooltipPosition="top"
                                formControlName="fec_baja">
                            <hr class="my-0">
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center mt-3">
                    <div class="col-4">
                        <p *ngIf="!ChoferBajaForm.valid" class="texto-invalido text-right">
                            <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                La Informaci&oacute;n Obligatoria(*)</i>
                        </p>
                        <p *ngIf="ChoferBajaForm.valid" class="texto-valido text-right">
                            <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                Completa</i>
                        </p>
                    </div>
                    <div class="col-2">
                        <button class="btn btn-primary form-control" type="submit"
                            [disabled]="!ChoferBajaForm.valid" pTooltip="Almacenar informacion"
                            tooltipPosition="right">
                            <span class="text-white">Guardar</span>
                            <i class="pi pi-save text-white"></i>
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>    
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarDialogBajaColaborador();"></button>
        </ng-template>
    </p-dialog>
</div>