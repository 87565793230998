import { ChoferService } from './../services/chofer.service';
import { CiudadService } from './../services/ciudad.service';
import { ClienteService } from './../services/cliente.service';
import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import ICredencialUsuario from '../models/credencial';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { NoordinariosService } from '../services/noordinarios.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICiudad } from '../models/ciudad';
import { ICliente } from '../models/cliente';
import { ITipoServicio } from '../models/tiposervicio';
import { ITipoAsistenciaPagada } from '../models/tipoasistencia';
import { IHorariosAsistenciaPagada } from '../models/tipohorariosap';
import { ITipoServiciosAsistenciaPagada } from '../models/tiposervicioap';
import { IDataEnviarAP } from '../models/data_enviar_ap';
import { IDataEnviarTU } from '../models/data_enviar_tu';
import { IDataEnviarCapacitacion } from '../models/data_enviar_capacitacion';
import { IDataEnviarCertificacion } from '../models/data_enviar_certificacion';
import { IMovNoOrdinarios } from '../models/mov_noordinarios';
import { AuthService } from '../services/auth.service';
import { IChofer } from '../models/chofer';
import { IChoferesActivos } from '../models/choferes_activos';
import { ITipoTransporte } from '../models/tipotransporte';
import { TipotransporteService } from '../services/tipotransporte.service';
import { Ipuestos } from '../models/Ipuestos';
import { ConfunidadService } from '../services/confunidad.service';
import { IConfiguracionUnidad } from '../models/confUnidad';

import { UnidadtransporteService } from '../services/unidadtransporte.service';
import { InumEconomico } from '../models/numEconomico';
import { IResultadoGeneral } from '../models/resultadogeneral';
import * as saveAs from 'file-saver';
import { Observable } from 'rxjs';
import { IAdmProveedor } from '../models/admProveedor';

@Component({
  selector: 'app-noordinarios',
  templateUrl: './noordinarios.component.html',
  styleUrls: ['./noordinarios.component.scss']
})
export class NoordinariosComponent {
  @ViewChild('dt1') dt!: Table;
  lstChofer: IChofer[] = [];
  lstMovimientos: IMovNoOrdinarios[] = [];
  lscargarConfunidadService:IConfiguracionUnidad[]=[];
  lstCiudades: ICiudad[] = [];
  lstCliente: ICliente[] = [];
  lstTipoServicio: ITipoServicio[]=[];
  lstTipoAsistencia: ITipoAsistenciaPagada[]=[];
  lsPuestos: Ipuestos[]=[];
  lsNumEconomico: InumEconomico []=[];
  lstHorariosAsistenciaPagada:IHorariosAsistenciaPagada[]=[];
  lstTipoServiciosAsistenciaPagada:ITipoServiciosAsistenciaPagada[]=[];
  //CATALOGOS POR FEFINIR
  lstChoferesDisponibles:IChoferesActivos[]=[];
  //FIN CATALOGOS POR DEFINIR
  fecha_dia: any = null;
  mostrarbtnLimpiarFiltro: boolean = false;
  user: ICredencialUsuario = {};
  loading: boolean = false;
  loading_reporte1: boolean = false;
  dspTipoServicio: boolean = false;
  clv_operacion:number=0;
  btnHabilitarSpinner: boolean = false;
  habilitar_un_dia:boolean = false;
  ButtonExportExcel:boolean = false;
  //validaciones de pantallas
  clv_AP: boolean = false;
  clv_TU: boolean = false;
  clv_CA: boolean = false;
  clv_CE: boolean = false;
  gestionForm: FormGroup = new FormGroup({
    id_tipo_servicio: new FormControl(0, [
      Validators.required
    ])
  });
  APForm: FormGroup = new FormGroup({
    id_tipo_servicio: new FormControl({ value: 0, disabled: true }, [
      Validators.required
    ]),
    id_tipo_asistencia_pagada: new FormControl(0, [
      Validators.required
    ]),
    id_horario: new FormControl(0, [
      Validators.required
    ]),
    id_servicio_prestado: new FormControl(0, [
      Validators.required
    ]),
    cod_chofer: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_hm: new FormControl(0, [
      Validators.required
    ]),
    id_cliente: new FormControl(0,[Validators.required]),
    //agrega cambio itzael
    clave_configuracionUnidad: new FormControl(0,[Validators.required]),
    id_puesto: new FormControl(0,[Validators.required] ),


  });
  TUForm: FormGroup = new FormGroup({
    id_tipo_servicio: new FormControl({ value: 0, disabled: true }, [Validators.required]),
    cod_chofer: new FormControl(0, [Validators.required]),
    id_ciudad_hm_colab: new FormControl({ value: 0, disabled: true }),
    id_ciudad_hm: new FormControl(0, [Validators.required]),
    id_cliente: new FormControl(0, [Validators.required]),
    num_economico: new FormControl('', [Validators.required]),
    imp_viaticos: new FormControl(0,[Validators.required,Validators.min(0),Validators.max(450)])
  });



  CAForm: FormGroup = new FormGroup({
    id_tipo_servicio: new FormControl({ value: 0, disabled: true }, [
      Validators.required,
    ]),
    cod_chofer: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_hm: new FormControl(0, [
      Validators.required
    ]),
    id_cliente: new FormControl(0, [Validators.required]),
    id_puesto: new FormControl(0,[Validators.required] ),
    clave_configuracionUnidad: new FormControl(0,[Validators.required
      ]),
  });
  CEForm: FormGroup = new FormGroup({
    id_tipo_servicio: new FormControl({ value: 0, disabled: true }, [
      Validators.required
    ]),
    cod_chofer: new FormControl(0, [
      Validators.required
    ]),
    id_ciudad_hm: new FormControl(0, [
      Validators.required
    ]),
    id_cliente: new FormControl(0, [Validators.required]),
    clave_configuracionUnidad: new FormControl(0,[Validators.required
    ]),

    id_puesto: new FormControl(0,[Validators.required
    ]),

  });
  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private noordinariosService: NoordinariosService,
    private clienteService: ClienteService,
    private confunidadService: ConfunidadService,
    private ciudadService: CiudadService,
    private unidadtransporteService: UnidadtransporteService,
    private choferService: ChoferService,
    private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.gestionForm.controls['id_tipo_servicio'].valueChanges.subscribe(data => {
      if (data==null) {
        //cuando se limpie
        this.resetearPantallasAMostrar();
        this.resetearFormularios();
      }
      //else this.resetearFormularios();

    });

    this.APForm.controls['cod_chofer'].valueChanges.subscribe(data => {
      let idx= this.lstChoferesDisponibles.findIndex(x => x.cod_chofer == data)
      this.APForm.controls['id_puesto'].setValue(this.lstChoferesDisponibles[idx].id_puesto);
      this.APForm.controls['id_ciudad_hm'].setValue(this.lstChoferesDisponibles[idx].id_ciudad_hm);
    });
    this.TUForm.controls['cod_chofer'].valueChanges.subscribe(data => {
      let idx= this.lstChoferesDisponibles.findIndex(x => x.cod_chofer == data)
      this.TUForm.controls['id_ciudad_hm_colab'].setValue(this.lstChoferesDisponibles[idx].id_ciudad_hm);
    });
    this.CAForm.controls['cod_chofer'].valueChanges.subscribe(data => {
      let idx= this.lstChoferesDisponibles.findIndex(x => x.cod_chofer == data)
      this.CAForm.controls['id_puesto'].setValue(this.lstChoferesDisponibles[idx].id_puesto);
      this.CAForm.controls['id_ciudad_hm'].setValue(this.lstChoferesDisponibles[idx].id_ciudad_hm);
    });
    this.CEForm.controls['cod_chofer'].valueChanges.subscribe(data => {
      let idx= this.lstChoferesDisponibles.findIndex(x => x.cod_chofer == data)
      this.CEForm.controls['id_puesto'].setValue(this.lstChoferesDisponibles[idx].id_puesto);
      this.CEForm.controls['id_ciudad_hm'].setValue(this.lstChoferesDisponibles[idx].id_ciudad_hm);
    });
  }
  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  ngOnInit(): void {
    this.getListaDeChoferes_Filtro();
    this.getListaDeClientes();
    this.cargarListadoCiudades();
    this.cargarListadoTipoServicioAP();
    this.cargarConfunidadService();
    this.cargarListaPuestos();
    this.CargarNumeroEconomico();
    this.cargarListadoHorarios();
    this.cargarListadoTipoAsistencia();
    this.inicializarFechasFormateadas();
    this.cargarListadoNoOrdinarios();
  }

  exportarExcel() {
    this.ButtonExportExcel = true;
    this.loading_reporte1 = true;
    if (this.fecha_dia == undefined || this.fecha_dia == '1900-01-01') {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> a Filtrar');
    } else {   
      if (this.lstMovimientos.length == 0 || typeof this.lstMovimientos.length === 'undefined') {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion Filtrada para la fecha <b>'+this.fecha_dia+'</b>');
        this.loading_reporte1 = false;
        this.ButtonExportExcel = false;
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.mapeaExcel(this.lstMovimientos));
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "Servicios_NoOrdinarios_Fecha");
        });
        setTimeout(() => {
          this.ButtonExportExcel = false;
          this.loading_reporte1 = false;
        }, 1000);
      }
    }
  }

  mapeaExcel(movs :IMovNoOrdinarios[]): [any]
  {
    var inf: any = [];
    movs.forEach(x=>{
        inf.push(
          {
            No_Ordinario: x.desc_tipo_servicio,
            Tipo_Asistencia: x.desc_tipo_asistencia_pagada,
            Horario: x.desc_horario,
            Tipo_Servicio_Prestado: x.desc_tipo_servicio_prestado,
            Codigo_Chofer:x.cod_chofer,
            Nombre_Chofer:x.nombre_completo,
            Puesto:x.desc_puesto,
            Ciudad_Operacion:x.desc_ciudad,
            Ruta:x.desc_ruta,
            Cliente:x.desc_cliente,
            Numero_Economico:x.num_economico,
            Configuracion_Unidad:x.desc_confUnidad,
            Fecha:x.fecha,
            Usuario_Ultima_Modificacion:x.nom_ult_mod
          }
        )
      }
    )
    return inf;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }

  abrirModalReportes(){

  }

  getListaDeChoferes_Filtro() {
    this.loading=true;
    this.noordinariosService.getChoferesActivos().subscribe((resp) => {
      this.lstChoferesDisponibles = resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    },
    (error)=> {
      this.loading=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Choferes');
    });
  }

  cargarListadoNoOrdinarios() {
    this.noordinariosService.getListadoNoOrdinarios().subscribe((resp) => {
      this.lstTipoServicio = resp;

      let idx_canceladas = this.lstTipoServicio.findIndex(x => x.id == 5);
      let idx_rescate = this.lstTipoServicio.findIndex(x => x.id == 6);
      this.lstTipoServicio[idx_canceladas].disabled  = true;
      this.lstTipoServicio[idx_rescate].disabled  = true;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <No Ordinarios>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoTipoAsistencia() {
    this.noordinariosService.getListadoTipoAsistencia().subscribe((resp) => {
      this.lstTipoAsistencia = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <TipoAsistencia>', detail: 'Contacte al Administrador del Sitio' });
      });
  }
  cargarListaPuestos() {
    this.choferService.getPuestos().subscribe((resp) => {
      this.lsPuestos = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <TipoAsistencia>', detail: 'Contacte al Administrador del Sitio' });
      });
  }
  //itzael numero economico
  CargarNumeroEconomico() {
    this.unidadtransporteService.getListadoNumEconomicoSimplified().subscribe((resp) => {
      this.lsNumEconomico = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <TipoAsistencia>', detail: 'Contacte al Administrador del Sitio' });
      });
  }


  cargarListadoHorarios() {
    this.noordinariosService.getListadoHorariosAsistenciaPagada().subscribe((resp) => {
      this.lstHorariosAsistenciaPagada = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Horarios>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoTipoServicioAP() {
    this.noordinariosService.getListadoTipoServicioAP().subscribe((resp) => {
      this.lstTipoServiciosAsistenciaPagada = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <TipoServiciosAsistenciaPagada>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarListadoCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }
//itzael consumir las listas de tipo transporte
cargarConfunidadService() {
  this.confunidadService.getListadoConfUnidades().subscribe((resp) => {

    this.lscargarConfunidadService = resp;

  },
    (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error en cargar <Ciudades>', detail: 'Contacte al Administrador del Sitio' });
    });
}
//---------------------------fin itzael
  getListaDeClientes() {
    this.loading = true;
    this.clienteService.getListadoClientes().subscribe((resp) => {
      this.lstCliente = resp;
      //agregar HappyMile como Cliente
      this.lstCliente.unshift({
        id_cliente: -2,
        nombre: 'HAPPYMILE',
        fec_registro: '1900-01-01',
        clv_activo: 1
      });
      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar <Listado de Clientes>');
      });
  }

  inicializarFechasFormateadas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
  }

  obtenerFechaMomento(): string {
    let fecha: any;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    fecha = getYear + "-" + getMonth + "-" + getDay;
    return fecha;
  }

  cargarInformacionPorFecha(fecha: string, fecha_fin: string) {
    let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
    this.lstMovimientos = [];
    this.loading = true;
    if (fecha == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.noordinariosService.getListadoDeMovimientosFecha(fecha, this.user.cod!).subscribe((resp) => {
        this.lstMovimientos = resp;
        if (!this.lstMovimientos.length || typeof this.lstMovimientos === 'undefined') {
          //
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion con la Fecha Seleccionada de Servicios <b>No Ordinarios</b>.');
        } else {
          //asignar el tipo de permiso al usuario
          this.clv_operacion = this.lstMovimientos[0].clv_operacion;
          if(this.clv_operacion==1){
            //revisar permisos de usuario normal, solo puede ingresar información de la fecha del dia
            let fecha_ruta = new Date(this.lstMovimientos[0].fecha + 'T00:00:00');
            if (this.clv_operacion == 1) {
              //Se agrega el tiempo para que no reste un dia por ser tipo Date();
              let fecha_final_dia=new Date(this.obtenerFechaMomento()+'T00:00:00');
              if (fecha_final_dia.getTime() == fecha_ruta.getTime()) {
                this.habilitar_un_dia = true;
              } else {
                this.habilitar_un_dia = false;
              }
            }
          }
          //filtro el arreglo para eliminar el registro default
          this.lstMovimientos = this.lstMovimientos.filter((data) => data.id_tipo_servicio != 0);
        }
        this.mostrarbtnLimpiarFiltro = true;
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Informacion de Operacion Diaria');
        });
    }
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }

  LimpiarFiltro() {
    this.lstMovimientos = [];
    this.fecha_dia = null;
    this.limpiarFiltrosTabla();
    this.mostrarbtnLimpiarFiltro = false;
    this.loading = false;
  }

  limpiarFiltrosTabla() {
    this.dt.clear();
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  guardarInformacionAsistenciaPagada() {
    this.btnHabilitarSpinner=true;
    let data_enviar : IDataEnviarAP = {
      id_tipo_servicio: this.APForm.get('id_tipo_servicio')?.value,
      id_tipo_asistencia_pagada: this.APForm.get('id_tipo_asistencia_pagada')?.value,
      id_horario: this.APForm.get('id_horario')?.value,
      id_servicio_prestado: this.APForm.get('id_servicio_prestado')?.value,
      cod_chofer: this.APForm.get('cod_chofer')?.value,
      id_ciudad_hm: this.APForm.get('id_ciudad_hm')?.value,
      id_cliente: this.APForm.get('id_cliente')?.value==null ? 0:this.APForm.get('id_cliente')?.value,
      cod_usuario: this.user.cod!,
      fecha: this.fecha_dia,
      clave_configuracionUnidad: this.APForm.get('clave_configuracionUnidad')?.value ,
      id_puesto:this.APForm.get('id_puesto')?.value

    }
    this.noordinariosService.agregarAsistenciaPagada(data_enviar).subscribe((resp)=>{
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.cancelarModalTipoServicio();
      this.cargarInformacionPorFecha(this.fecha_dia,'1900-01-01');
      setTimeout(() => {
        this.btnHabilitarSpinner =false;
      }, 1000);
    },
    (error)=> {
      this.btnHabilitarSpinner=false;
      this.messageService.add({ severity: 'error', summary: 'Error en <guardar AP>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacionTrasladoUnidad() {
    this.btnHabilitarSpinner =true;
    let data_enviar : IDataEnviarTU = {
      id_tipo_servicio: this.TUForm.get('id_tipo_servicio')?.value,
      cod_chofer: this.TUForm.get('cod_chofer')?.value,
      id_ciudad_hm: this.TUForm.get('id_ciudad_hm')?.value,
      id_cliente: this.TUForm.get('id_cliente')?.value == null ? 0 : this.TUForm.get('id_cliente')?.value,
      cod_usuario: this.user.cod!,
      fecha: this.fecha_dia,
      idx: 0,
      id_tipo_asistencia_pagada: 0,
      id_horario: 0,
      id_servicio_prestado: 0,
      //agregar una doble validacion itzael
      num_economico:this.TUForm.get('num_economico')?.value == null ? 0 : this.TUForm.get('num_economico')?.value.num_economico,
      imp_viaticos:this.TUForm.get('imp_viaticos')?.value
    }
    // Se comento por parte de operación, que si es posible que se asigne a la misma ciudad de OP
    // if(data_enviar.id_ciudad_hm == this.TUForm.get('id_ciudad_hm_colab')?.value )
    // {
    //   this.messageService.add({ severity: 'warn', summary: 'Revise Informacion', detail: 'No se puede guardar una ciudad destino igual al origen del colaborador.' });
    //   this.btnHabilitarSpinner = false;
    // }
    // else
      this.noordinariosService.agregarTrasladoUnidad(data_enviar).subscribe((resp)=>{
        this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
        this.cancelarModalTipoServicio();
        this.cargarInformacionPorFecha(this.fecha_dia,'1900-01-01');
        setTimeout(() => {
          this.btnHabilitarSpinner =false;
        }, 1000);
      },
      (error)=> {
        this.btnHabilitarSpinner=false;
        this.messageService.add({ severity: 'error', summary: 'Error en <guardar Traslado de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });

  }
//itzael giardar capacitacion
  guardarInformacionCapacitacion() {
    this.btnHabilitarSpinner=true;
    let data_enviar : IDataEnviarCapacitacion = {
      id_tipo_servicio: this.CAForm.get('id_tipo_servicio')?.value,
      cod_chofer: this.CAForm.get('cod_chofer')?.value,
      id_ciudad_hm: this.CAForm.get('id_ciudad_hm')?.value,
      id_puesto: this.CAForm.get('id_puesto')?.value == null ? 0 : this.CAForm.get('id_puesto')?.value.id,
      clave_configuracionUnidad: this.CAForm.get('clave_configuracionUnidad')?.value ,
      id_cliente: this.CAForm.get('id_cliente')?.value == null ? 0 : this.CAForm.get('id_cliente')?.value,
      cod_usuario: this.user.cod!,
      fecha: this.fecha_dia,
      idx: 0,
      id_tipo_asistencia_pagada: 0,
      id_horario: 0,
      id_servicio_prestado: 0
    }

    this.noordinariosService.agregarCapacitacion(data_enviar).subscribe((resp)=>{
      let mssg: IResultadoGeneral = resp
      if(mssg.clv_estatus == -2)
        this.messageService.add({ severity: 'warn', summary: 'Revise Datos', detail: mssg.messageDetails });
      else if(mssg.clv_estatus == 0)
        this.messageService.add({ severity: 'error', summary: 'Error en <guardar Capacitacion>', detail: 'Contacte al Administrador del Sitio' });
      else
        this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      
      this.cancelarModalTipoServicio();
      this.cargarInformacionPorFecha(this.fecha_dia,'1900-01-01');
      setTimeout(() => {
        this.btnHabilitarSpinner =false;
      }, 1000);
    },
    (error)=> {
      this.btnHabilitarSpinner=false;
      this.messageService.add({ severity: 'error', summary: 'Error en <guardar Capacitacion>', detail: 'Contacte al Administrador del Sitio' });
    });
  }
//certificacion itzael
  guardarInformacionCertificacion() {
    this.btnHabilitarSpinner=true;
    let data_enviar: IDataEnviarCertificacion = {
      id_tipo_servicio: this.CEForm.get('id_tipo_servicio')?.value,
      cod_chofer: this.CEForm.get('cod_chofer')?.value,
      id_ciudad_hm: this.CEForm.get('id_ciudad_hm')?.value,
      id_cliente: this.CEForm.get('id_cliente')?.value == null ? 0 : this.CEForm.get('id_cliente')?.value,
      clave_configuracionUnidad: this.CEForm.get('clave_configuracionUnidad')?.value ,
      id_puesto:this.CEForm.get('id_puesto')?.value,
      cod_usuario: this.user.cod!,
      fecha: this.fecha_dia,
      idx: 0,
      id_tipo_asistencia_pagada: 0,
      id_horario: 0,
      id_servicio_prestado: 0
    }

    this.noordinariosService.agregarCertificacion(data_enviar).subscribe((resp)=>{
      this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
      this.cancelarModalTipoServicio();
      this.cargarInformacionPorFecha(this.fecha_dia,'1900-01-01');
      setTimeout(() => {
        this.btnHabilitarSpinner =false;
      }, 1000);
    },
    (error)=> {
      this.btnHabilitarSpinner=false;
      this.messageService.add({ severity: 'error', summary: 'Error en <guardar Certificacion>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  confirmarEliminar(idx: number,id_tipo_servicio: number) {
    let text_info = this.lstTipoServicio.find(x => x.id = id_tipo_servicio)?.nombre
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b> Eliminar </b> el registro de <b>' + text_info + '</b> ?',
      header: 'Confirmacion de Eliminación',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.eliminarNoOrdinario(idx,id_tipo_servicio);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            //prov.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Eliminado' });
            break;
          case ConfirmEventType.CANCEL:
            //prov.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Proceso Eliminado' });
            break;
        }
      }
    });
  }

  tpServicio(clv_btn_eliminar: number,id_tipo_servicio: number): string
  {
    let tp_servicio = this.lstTipoServicio.find(x => x.id == id_tipo_servicio)?.nombre.toUpperCase();
    let text_info = clv_btn_eliminar == 0 ? 'No se puede elminar '+tp_servicio : 'Eliminar '+tp_servicio;

    return text_info;
  }

  eliminarNoOrdinario(idx: number,id_tipo_servicio: number)
  {
    this.btnHabilitarSpinner=true;
    let data_enviar = 
    {
      idx: idx,
      id_tipo_servicio: id_tipo_servicio,
      cod_usuario: this.user != undefined ? this.user.cod : '0999'      
    }

    this.noordinariosService.earseNoOrdin(data_enviar).subscribe((resp) => {
      if(resp.clv_estatus == -2)
      {
        setTimeout(() => {
          this.btnHabilitarSpinner=false;
          this.messageService.add({ severity: 'warn', summary: 'Atención', detail: resp.messageDetails });
        }, 800);
      }
      else
      {
        setTimeout(() => {
          this.btnHabilitarSpinner=false;
          this.messageService.add({ severity: 'success', summary: 'Registro Eliminado', detail: 'La informacion se elimino de forma correcta.' });
          this.cargarInformacionPorFecha(this.fecha_dia, '1900-01-01')
        }, 800);
      }
    }, (error) => {
      this.btnHabilitarSpinner=false;
      this.messageService.add({ severity: 'error', summary: 'Error al eliminar Registro', detail: 'Contacte al Administrador del Sitio.' });
    });
  }

  mostrarModalTipoServicio(){
    this.gestionForm.reset();
    this.dspTipoServicio = true;

  }

  cancelarModalTipoServicio() {
    this.dspTipoServicio = false;
    this.ocultarTodasPantallasServicios();
    //reiniciar formularios
    this.gestionForm.reset();
    this.APForm.reset();
    this.TUForm.reset();
    this.CAForm.reset();
    this.CEForm.reset();
  }

  ocultarTodasPantallasServicios() {
    this.clv_AP = false;
    this.clv_TU = false;
    this.clv_CA = false;
    this.clv_CE = false;
    //falta reiniciar los formularios

  }

  resetearPantallasAMostrar(){
    this.clv_AP = false;
    this.clv_TU = false;
    this.clv_CA = false;
    this.clv_CE = false;
  }

  resetearFormularios(){
    this.APForm.reset();
    this.TUForm.reset();
    this.CAForm.reset();
    this.CEForm.reset();
  }

  gestionPantallas() {
    let num_opcion = this.gestionForm.get('id_tipo_servicio')?.value==null ? 0:this.gestionForm.get('id_tipo_servicio')?.value;
    switch (num_opcion) {
      case 1: {
        //Asistencia Pagada
        this.resetearFormularios();
        this.APForm.controls['id_tipo_servicio'].setValue(num_opcion);
        this.clv_AP = true;
        this.clv_TU = false;
        this.clv_CA = false;
        this.clv_CE = false;
      } break;
      case 2: {
        //Traslado de Unidad
        this.resetearFormularios();
        this.TUForm.controls['id_tipo_servicio'].setValue(num_opcion);
        this.TUForm.controls['imp_viaticos'].setValue(0);
        this.clv_AP = false;
        this.clv_TU = true;
        this.clv_CA = false;
        this.clv_CE = false;
      } break;
      case 3: {
        //Capacitación
        this.resetearFormularios();
        this.CAForm.controls['id_tipo_servicio'].setValue(num_opcion);
        this.clv_AP = false;
        this.clv_TU = false;
        this.clv_CA = true;
        this.clv_CE = false;
      } break;
      case 4: {
        //Certificacion
        this.resetearFormularios();
        this.CEForm.controls['id_tipo_servicio'].setValue(num_opcion);
        this.clv_AP = false;
        this.clv_TU = false;
        this.clv_CA = false;
        this.clv_CE = true;
      } break;
      default: {
        this.mostrarDialogoInformativo('Servicio No Encontrado. Contacte al Administrador.');
      } break;
    }
  }

}
