import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ListadoComponent } from './shared/listado/listado.component';
import { LstchoferComponent } from './shared/lstchofer/lstchofer.component';
import { LstclienteComponent } from './shared/lstcliente/lstcliente.component';
import { LstproyectoComponent } from './shared/lstproyecto/lstproyecto.component';
import { LstrutasComponent } from './shared/lstrutas/lstrutas.component';
import { MainComponent } from './shared/main/main.component';
import { MantenimientoComponent } from './shared/mantenimiento/mantenimiento.component';
import { ProyeccionComponent } from './shared/proyeccion/proyeccion.component';
import { RealComponent } from './shared/real/real.component';
import { RutasComponent } from './shared/rutas/rutas.component';
import { UnidadComponent } from './shared/unidad/unidad.component';
import { ConsultapaoComponent } from './shared/consultapao/consultapao.component';
import { LsusuariosComponent } from './shared/lsusuarios/lsusuarios.component';
import { ManualComponent } from './shared/manual/manual.component';
import { MovfechaComponent } from './shared/movfecha/movfecha.component';
import { RptserviciosComponent } from './shared/rptservicios/rptservicios.component';
import { MovextrasComponent } from './shared/movextras/movextras.component';
import { NoordinariosComponent } from './shared/noordinarios/noordinarios.component';
import { CostounidadComponent } from './shared/costounidad/costounidad.component';
import { FoliosComponent } from './shared/folios/folios.component';
import { SalariosComponent } from './shared/salarios/salarios.component';
import { LoginGuard } from './shared/utils/guards/login.guard';
import { AuthGuard } from './shared/utils/guards/auth.guard';
import { RegistroPersonalComponent } from './shared/registro-personal/registro-personal.component';
import { ProvdispComponent } from './shared/provdisp/provdisp.component';
import { ProcsegComponent } from './shared/procseg/procseg.component';
import { HerramunidComponent } from './shared/herramunid/herramunid.component';
import { HerramconcComponent } from './shared/herramconc/herramconc.component';
import { HerramComponent } from './shared/herram/herram.component';
import { GestdispComponent } from './shared/gestdisp/gestdisp.component';
import { AdmprovedoresComponent } from './shared/admprovedores/admprovedores.component';
import { UserdispComponent } from './shared/userdisp/userdisp.component';

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "unidad",
        component: UnidadComponent
      },
      {
        path: "listado",
        component: ListadoComponent
      },
      {
        path: "mantenimiento",
        component: MantenimientoComponent
      },
      {
        path: "clientes",
        component: LstclienteComponent
      },
      {
        path: "servicios",
        component: LstproyectoComponent
      },
      {
        path: "rutas",
        component: LstrutasComponent
      },
      {
        path: "ruta",
        component: RutasComponent
      },
      {
        path: "drivers",
        component: LstchoferComponent
      },
      {
        path: "proyeccion",
        component: ProyeccionComponent
      },
      {
        path: "opediaria",
        component: RealComponent
      },
      {
        path: "consulta",
        component: ConsultapaoComponent
      },
      {
        path: "users",
        component: LsusuariosComponent
      },
      {
        path: "manual",
        component: ManualComponent
      },
      {
        path: "movfecha",
        component: MovfechaComponent
      },
      {
        path: "movser",
        component: RptserviciosComponent
      },
      {
        path: "seres",
        component: MovextrasComponent
      },
      {
        path: "ordin",
        component: NoordinariosComponent
      },
      {
        path: "prog",
        component: RegistroPersonalComponent
      },
      {
        path: "costo",
        component: CostounidadComponent
      },
      {
        path: "folio",
        component: FoliosComponent
      },
      {
        path: "tab",
        component: SalariosComponent
      },
      {
        path: "gesdi",
        component: GestdispComponent
      },
      {
        path: "herrm",
        component: HerramComponent
      },
      {
        path: "herrmcon",
        component: HerramconcComponent
      },
      {
        path: "herun",
        component: HerramunidComponent
      },
      {
        path: "prseg",
        component: ProcsegComponent
      },
      {
        path: "provdisp",
        component: ProvdispComponent
      },
      {
        path: "prove",
        component: AdmprovedoresComponent
      }
      ,
      {
        path: "usdis",
        component: UserdispComponent
      }
    ]
  }
  ,
  {
    canActivate: [LoginGuard],
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
