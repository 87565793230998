import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './shared/main/main.component';

/***/
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToastModule} from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import {CaptchaModule} from 'primeng/captcha';
import {ButtonModule} from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { RippleModule } from 'primeng/ripple';
import { ImageModule } from 'primeng/image';
import { UnidadComponent } from './shared/unidad/unidad.component';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';

import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {ColorPickerModule} from 'primeng/colorpicker';
import { BlockUIModule } from 'primeng/blockui';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';

import {SidebarModule} from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { ListadoComponent } from './shared/listado/listado.component';
import { MantenimientoComponent } from './shared/mantenimiento/mantenimiento.component';
import { LstproyectoComponent } from './shared/lstproyecto/lstproyecto.component';
import { LstclienteComponent } from './shared/lstcliente/lstcliente.component';
import { RutasComponent } from './shared/rutas/rutas.component';
import { LstrutasComponent } from './shared/lstrutas/lstrutas.component';
import { ProyeccionComponent } from './shared/proyeccion/proyeccion.component';
import { RealComponent } from './shared/real/real.component';
import { LstchoferComponent } from './shared/lstchofer/lstchofer.component';
import { OperaciondiariaComponent } from './shared/operaciondiaria/operaciondiaria.component';
import { ConsultapaoComponent } from './shared/consultapao/consultapao.component';
import { LsusuariosComponent } from './shared/lsusuarios/lsusuarios.component';
import { ManualComponent } from './shared/manual/manual.component';
import { MovfechaComponent } from './shared/movfecha/movfecha.component';
import { RptserviciosComponent } from './shared/rptservicios/rptservicios.component';
import { MovextrasComponent } from './shared/movextras/movextras.component';
import { ThousandPipe } from './shared/utils/pipes/thousand.pipe';
import { NoordinariosComponent } from './shared/noordinarios/noordinarios.component';
import { CostounidadComponent } from './shared/costounidad/costounidad.component';
import { FoliosComponent } from './shared/folios/folios.component';
import { SalariosComponent } from './shared/salarios/salarios.component';
import { RegistroPersonalComponent } from './shared/registro-personal/registro-personal.component';
import { ProvdispComponent } from './shared/provdisp/provdisp.component';
import { HerramComponent } from './shared/herram/herram.component';
import { ProcsegComponent } from './shared/procseg/procseg.component';
import { HerramconcComponent } from './shared/herramconc/herramconc.component';
import { HerramunidComponent } from './shared/herramunid/herramunid.component';
import { GestdispComponent } from './shared/gestdisp/gestdisp.component';
import { AdmprovedoresComponent } from './shared/admprovedores/admprovedores.component';
import { UserdispComponent } from './shared/userdisp/userdisp.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    UnidadComponent,
    ListadoComponent,
    MantenimientoComponent,
    LstproyectoComponent,
    LstclienteComponent,
    RutasComponent,
    LstrutasComponent,
    ProyeccionComponent,
    RealComponent,
    LstchoferComponent,
    OperaciondiariaComponent,
    ConsultapaoComponent,
    LsusuariosComponent,
    ManualComponent,
    MovfechaComponent,
    RptserviciosComponent,
    MovextrasComponent,
    ThousandPipe,
    NoordinariosComponent,
    CostounidadComponent,
    FoliosComponent,
    SalariosComponent,
    RegistroPersonalComponent,
    ProvdispComponent,
    HerramComponent,
    ProcsegComponent,
    HerramconcComponent,
    HerramunidComponent,
    GestdispComponent,
    AdmprovedoresComponent,
    UserdispComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ToastModule,
    MessagesModule,
    CaptchaModule,
    ButtonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RippleModule,
    ImageModule,
    MenuModule,
    TooltipModule,
    SidebarModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ProgressBarModule,
    InputTextModule,
    ConfirmDialogModule,
    DividerModule,
    CheckboxModule,
    ColorPickerModule,
    TagModule,
    BlockUIModule,
    TabViewModule,
    ToggleButtonModule,
    SplitButtonModule
  ],
  providers: [DatePipe,ConfirmationService,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
