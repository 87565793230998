import { Component, OnInit } from '@angular/core';
import { UnidadService } from '../services/unidad.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ITipoTransporte } from '../models/tipotransporte';
import { IProveedor, IProveedorSimp, IProveedorSimp2 } from '../models/proveedor';
import { ITipoUnidad } from '../models/tipoUnidad';
import { ITipoVerificacion } from '../models/tipoVerificacion';
import { IVerificacion } from '../models/verificacion';
import { ICiudad } from '../models/ciudad';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { ICombustible } from '../models/combustible';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminProveedoresService } from '../services/admproveedores.service';

@Component({
  selector: 'app-unidad',
  templateUrl: './unidad.component.html',
  styleUrls: ['./unidad.component.scss']
})
export class UnidadComponent implements OnInit {
  lstTransporte: ITipoTransporte[] = [{ clave_tipoTransporte: 1, nombre: "LIGERO" }, { clave_tipoTransporte: 2, nombre: "PESADO" }];
  selectedTransporte: ITipoTransporte = { clave_tipoTransporte: 0, nombre: "" };
  lstproveedor: IProveedorSimp[] = [];
  selectedProveedor: IProveedorSimp2 = {
    clave_Proveedor: 0,
    nombre: '',
    fecha_Registro: '',
    fecha_InicioContrato: '',
    fecha_FinContrato: '',
    fk_clave_Estado: ''
  };
  lstTipoUnidad: ITipoUnidad[] = [];
  selectedTipoUnidad: ITipoUnidad = {
    clave_tipoUnidad: 0,
    nombre: '',
    fecha_Registro: ''
  };
  visualizarFechasProveedor: boolean = false;
  lstTipoVerificacion: ITipoVerificacion[] = [];
  selectedTipoVerificacion: ITipoVerificacion = {
    clave_tipoverificacion: 0,
    nombre: '',
    fecha_Registro: ''
  };
  lstVerificaciones: IVerificacion[] = [];
  selectedVerificacion: IVerificacion = {
    clave_verificacion: 0,
    nombre: '',
    fecha_Registro: ''
  };
  lstCiudades: ICiudad[] = [];
  selectedCiudadOrigen: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };
  selectedCiudadDestino: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };
  lstConfUnidad: IConfiguracionUnidad[] = [];
  selectedConfUnidad: IConfiguracionUnidad = {
    clave_configuracionUnidad: 0,
    nombre: ''
  };

  /*proveedor*/
  fecha_inicio_proveedor!: Date;
  fecha_fin_proveedor!: Date;

  /*Poliza Seguro*/
  fecha_inicio_poliza!: Date;

  /*Combustible*/
  lstCombustible: ICombustible[] = [];
  selectedCombustible: ICombustible = {
    id: 0,
    nombre: ''
  };

  /*Formulario*/
  formularioContacto!: any;
  constructor(private unidadService: UnidadService,
    private admProveedoresService: AdminProveedoresService,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder) {
    this.formularioContacto = this.fb.group({
      nombre: ['numeroeconomico', [Validators.required, Validators.minLength(6)]],
      mail: ['numeroplaca', [Validators.required]],
      selectedTransporte: ['', [Validators.required]],
      selectedConfUnidad: ['', [Validators.required]],
      selectedTipoUnidad: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.cargarListadoProveedores();
    this.cargarListadoTipoUnidad();
    this.cargarListadoTipoVerificacion();
    this.cargarListadoVerificaciones();
    this.cargarListadoCiudades();
    this.cargarListadoConfUnidad();
    this.cargarListadoCombustible();
  }

  cargarListadoCombustible() {
    this.lstCombustible = [{
      id: 1,
      nombre: 'GASOLINA'
    }, {
      id: 2,
      nombre: 'DIESEL'
    }, {
      id: 3,
      nombre: 'OTROS'
    }];
  }

  cargarListadoProveedores() {
    this.admProveedoresService.getLstProveedores(0).subscribe((resp) => {
      this.lstproveedor = resp;
      setTimeout(() => {
        //this.loading = false;
      },
        800);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Proveedores');
        //this.loading = false;
      });
  }
  
  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  cargarListadoTipoUnidad() {
    this.lstTipoUnidad = [
      {
        clave_tipoUnidad: 1,
        nombre: 'PROPIA',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 2,
        nombre: 'ARRENDADA-RENTA',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 3,
        nombre: 'CREDITO',
        fecha_Registro: ''
      },
      {
        clave_tipoUnidad: 4,
        nombre: 'SUBCONTRATADA',
        fecha_Registro: ''
      }];
  }

  cargarListadoTipoVerificacion() {
    this.lstTipoVerificacion = [{
      clave_tipoverificacion: 1,
      nombre: 'ESTATAL',
      fecha_Registro: ''
    },
    {
      clave_tipoverificacion: 2,
      nombre: 'FEDERAL',
      fecha_Registro: ''
    }];
  }

  cargarListadoVerificaciones() {
    this.lstVerificaciones = [{
      clave_verificacion: 1,
      nombre: 'VERIFICACION VEHICULAR DEL ESTADO DE MEXICO',
      fecha_Registro: ''
    },
    {
      clave_verificacion: 2,
      nombre: 'VERIFICACION FEDERAL HUMO',
      fecha_Registro: ''
    },
    {
      clave_verificacion: 3,
      nombre: 'VERIFICACION FEDERAL FISICO-MECANICA',
      fecha_Registro: ''
    }];
  }

  cargarListadoCiudades() {
    this.lstCiudades = [];

  }

  cargarListadoConfUnidad() {
    this.lstConfUnidad = [
      {
        clave_configuracionUnidad: 1,
        nombre: 'SV'
      },
      {
        clave_configuracionUnidad: 2,
        nombre: 'LV'
      },
      {
        clave_configuracionUnidad: 3,
        nombre: 'PICK UP'
      },
      {
        clave_configuracionUnidad: 4,
        nombre: '3.5'
      },
      {
        clave_configuracionUnidad: 5,
        nombre: 'RABON'
      },
      {
        clave_configuracionUnidad: 6,
        nombre: 'THORTON 20'
      },
      {
        clave_configuracionUnidad: 7,
        nombre: 'THORTON 24'
      },
      {
        clave_configuracionUnidad: 8,
        nombre: 'THORTON 28'
      },
      {
        clave_configuracionUnidad: 9,
        nombre: 'CAJA 48 PIES'
      },
      {
        clave_configuracionUnidad: 10,
        nombre: 'CAJA 53 PIES'
      }
    ];
  }

  redireccionarListadoUnidades() {
    this.router.navigateByUrl("/principal/listado");
  }

  onSubmit() {
    alert("se guardara informaacion");
  }

  seleccionarProveedor() {
    this.visualizarFechasProveedor = true;
  }

  limpiarFormularioGeneral() {
    this.visualizarFechasProveedor = false;
  }

  validarFormulario() {
    alert("validar el formulario");
  }

  validarCambioTipoUnidad() {
    //Reiniciar valores de Proveedor
    this.selectedProveedor = {
      clave_Proveedor: 0,
      nombre: '',
      fecha_Registro: '',
      fecha_InicioContrato: '',
      fecha_FinContrato: '',
      fk_clave_Estado: ''
    };
    this.fecha_fin_proveedor = new Date();
    this.fecha_inicio_proveedor = new Date();
  }

  submit() {
    if (this.formularioContacto.valid) {
      alert("Todos los datos son válidos");
    }
    else {
      alert("Hay datos inválidos en el formulario");
    }
  }

  redireccionarNueva(){
    this.router.navigateByUrl("/principal/listado");
  }


}
