import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ICiudad } from '../models/ciudad';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IMovDiarioUnidadCompleto } from '../models/movdiariocompleto';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IProveedor, IProveedorSimp } from '../models/proveedor';
import { IAdmProveedor } from '../models/admProveedor';

@Injectable({
  providedIn: 'root'
})
export class AdminProveedoresService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.SUrlgApi + 'Proveedor';
  }

    public getLstProveedoresSimply(num_estado:number):Observable<IProveedorSimp[]>{
      const headers = { 'content-type': 'application/json'}  
      const valores = {
          params: new HttpParams().
            set('num_estado',num_estado)
        };
      return this.http.get<IProveedorSimp[]>(this.apiUrl + '/getLstProveedoresSmpd/',valores).pipe(retry(1), catchError(this.handleError));
    }
    public getLstProveedores(num_estado:number):Observable<IProveedor[]>{
        const headers = { 'content-type': 'application/json'}  
        const valores = {
            params: new HttpParams().
               set('num_estado',num_estado)
          };
        return this.http.get<IProveedor[]>(this.apiUrl + '/getListaProveedores/',valores).pipe(retry(1), catchError(this.handleError));
    }

    public AddProveedor(data:IAdmProveedor):Observable<IResultadoGeneral>{
        const headers = { 'content-type': 'application/json'}
        const body = JSON.stringify(data); 

        return this.http.post<IResultadoGeneral>(this.apiUrl + '/guardarProveedor/',body,{'headers':headers});
    }
    
    public cambiarEstatusProveedor(data:IAdmProveedor):Observable<IResultadoGeneral>{
        const headers = { 'content-type': 'application/json'}
        const body = JSON.stringify(data); 

        return this.http.put<IResultadoGeneral>(this.apiUrl + '/CambiarEstatusProveedor',body,{'headers':headers});
    }

    public getRptEstatusProveedor(num_estado: number,clv_activo: number):Observable<any>{
        const headers = { 'content-type': 'application/json'}
        const valores = {
          params: new HttpParams().
             set('num_estado',num_estado).
             set('clv_activo',clv_activo)
        };
        return this.http.get<any>(this.apiUrl + '/rptProveedores/',valores)
        .pipe(retry(1), catchError(this.handleError));
    }

    handleError(error:any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }

//    public eliminarMovimiento(data:IMovDiarioUnidadCompleto):Observable<any>{
//     const headers = { 'content-type': 'application/json'}  
//     const body = JSON.stringify(data); 
//     return this.http.put<any>(this.apiUrl + '/getListaProveedores/',body,{'headers':headers});
   
}
