<p-toast [style]="{marginTop: '80px'}" position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
                        class="p-button-secondary"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar"
                        class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 98%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-car mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <!--<header style="left: 5%; top: 5%;">Listado de Unidades</header>-->
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-success" style="width: 100%;"
                                    (click)="abrirModalReportes()" pTooltip="Abrir Ventana" tooltipPosition="left">
                                    Reportes <i class="pi pi-file-excel"></i>
                                </button>
                            </div>
                            <div class="col-2">
                                <button type="button" class="btn btn-primary" style="width: 100%;"
                                    (click)="redireccionarNueva(1);" pTooltip="Agregar nueva Unidad de Transporte"
                                    tooltipPosition="bottom">
                                    Agregar <i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="lstUnidades" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="580px"
                                [globalFilterFields]="['num_economico','desc_placaUnidad','nombre_tipounidad','nombre_tipotransporte','nombre_configuracionunidad','nombre_ciudaddestino','desc_marca',
                                'desc_modelo','vin']" [paginator]="true" [rows]="50"
                                [rowsPerPageOptions]="[25,50,100,150,200]" [loading]="loading"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <p class="mr-2 fs-2 color-header">Listado de Unidades de Transporte</p>
                                        <p-columnFilter field="clv_estatus" matchMode="equals" [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="lstEstatus"
                                                    optionLabel="nombre" optionValue="id"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por Estatus"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <span class="p-input-icon-left ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Economico,Transporte,Ciudad,Unidad" />
                                        </span>
                                    </div>
                                    <!--<div class="flex flex-row-reverse flex-wrap">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
                                        </span>
                                    </div>-->
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="num_economico" class="text-center"
                                            [ngClass]="{'texto_sm':true}">#Economico
                                            <p-sortIcon field="num_economico"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_placaUnidad" [ngClass]="{'texto_sm':true}">
                                            Placa<p-sortIcon field="desc_placaUnidad"></p-sortIcon>
                                        </th>
                                        <th style="min-width:150px ;max-width: 150px;" [ngClass]="{'texto_sm':true}">VIN
                                        </th>
                                        <th style="min-width:180px ;max-width: 180px;" [ngClass]="{'texto_sm':true}">
                                            Descripci&oacute;n</th>
                                        <th pSortableColumn="num_rendimiento_objetivo" class="text-center"
                                            style="min-width:150px ;max-width: 150px;" [ngClass]="{'texto_sm':true}">
                                            Rendimiento<p-sortIcon field="num_rendimiento_objetivo"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_configuracionunidad" class="text-center"
                                            [ngClass]="{'texto_sm':true}">
                                            Tipo
                                            <p-sortIcon field="nombre_configuracionunidad"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_tipounidad" class="text-center"
                                            style="min-width:160px ;max-width: 160px;" [ngClass]="{'texto_sm':true}">
                                            Unidad<p-sortIcon field="nombre_tipounidad"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="nombre_ciudaddestino" class="text-center"
                                            style="min-width:160px ;max-width: 160px;" [ngClass]="{'texto_sm':true}">
                                            Ciudad
                                            Operaci&oacute;n<p-sortIcon field="nombre_ciudaddestino"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="desc_estatus" class="text-center"
                                            style="min-width:230px ;max-width: 230px;" [ngClass]="{'texto_sm':true}">
                                            Estatus/Sub-Estatus
                                            <p-sortIcon field="desc_estatus"></p-sortIcon>
                                        </th>
                                        <th style="min-width:180px ;max-width: 180px;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-unidad>
                                    <tr
                                        [ngClass]="{'row-accessories': unidad.clv_estatus===0,'row-baja': unidad.clv_estatus==3,'texto_sm':true}">
                                        <td [ngClass]="{'color-numeconomico':true}">
                                            <p class="text-center">{{unidad.num_economico}}</p>
                                        </td>
                                        <td [ngClass]="{'color-sombreado':true}">{{unidad.desc_placaUnidad}}</td>
                                        <td>{{unidad.vin}}</td>
                                        <td [ngClass]="{'ajustarcelda':true}">{{ unidad.desc_marca +' '+
                                            unidad.desc_modelo + ' '+unidad.desc_color}}<p
                                                [ngClass]="{'color-sombreado':true}" style="display: inline;">
                                                -{{unidad.num_anio}}</p>
                                        </td>
                                        <td class="text-center texto-pesado">{{unidad.num_rendimiento_objetivo}}</td>
                                        <td class="text-center">{{unidad.nombre_configuracionunidad}}</td>
                                        <td class="text-center">{{unidad.nombre_tipounidad}}</td>
                                        <td class="text-center">{{unidad.nombre_ciudaddestino}}</td>
                                        <td class="text-center">{{unidad.desc_estatus +' -'+ unidad.desc_sub_estatus}}
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion de Unidad"
                                                    tooltipPosition="left" (click)="editarInformacion(unidad,2);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Gestionar Estatus de Unidad"
                                                    tooltipPosition="top" (click)="mostrarDialogoEstatus(unidad);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                                <p-button styleClass="p-button-help p-button-sm" icon="pi pi-cog"
                                                    pTooltip="Herramientas de Recursos" tooltipPosition="left"
                                                    (click)="gestionarHerramientas(unidad);"></p-button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!--<ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Un total de {{lstUnidadesTransporte ? lstUnidadesTransporte.length : 0 }}
                                        Registros.
                                    </div>
                                </ng-template>-->
                            </p-table>
                        </div>
                        <div class="row mt-3 d-flex justify-content-center">
                            <div class="col-6">
                                <div class="text-center">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                        [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Inactiva</p>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-6">
                                <div class="text-center">
                                    <p-colorPicker [(ngModel)]="color_naranja_baja" [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">
                                        Baja de Operación</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--EDICION DE INFORMACION-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditar" [style]="{width: '70vw'}"
            [transitionOptions]="'0ms'" styleClass="card-shadow">
            <ng-template pTemplate="header">
                {{texto_operacion}}
            </ng-template>
            <ng-template pTemplate="content">
                <div class="card-content">
                    <form class="form_unidad" [formGroup]="formularioUnidad" (ngSubmit)="submit()">
                        <div>
                            <span class="title-light">Datos Principales <i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTransporte" optionLabel="nombre" autoWidth="false"
                                                [style]="{'width':'100%'}" [showClear]="true"
                                                formControlName="clave_tipoTransporte"
                                                optionValue="clave_tipoTransporte"
                                                placeholder="Seleccione Tipo Transporte*" pTooltip="Tipo Transporte"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstConfUnidad" optionLabel="nombre"
                                                formControlName="clave_configuracionUnidad"
                                                optionValue="clave_configuracionUnidad" autoWidth="false"
                                                [style]="{'width':'100%'}" placeholder="Seleccione Unidad*"
                                                pTooltip="Unidad"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTipoUnidad" optionLabel="nombre"
                                                formControlName="clave_tipoUnidad" optionValue="clave_tipoUnidad"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                placeholder="Seleccione Tipo Unidad*"
                                                (ngModelChange)="validarCambioTipoUnidad()" pTooltip="Tipo Unidad"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Numero Económico*" formControlName="num_economico"
                                                style="text-transform:uppercase; color: blueviolet;"
                                                (keyup.enter)="validarNumeroEconomico()"
                                                (keyup.Space)="validarNumeroEconomico()"
                                                (keyup.shift)="validarNumeroEconomico()"
                                                (keyup)="validarNumeroEconomicoAlTeclear()"
                                                style="text-transform:uppercase;" pTooltip="Numero Economico"
                                                tooltipPosition="top">
                                        </div>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstTipoVerificacion" autoWidth="false"
                                                [style]="{'width':'100%'}" formControlName="clave_tipoverificacion"
                                                optionValue="clave_tipoverificacion"
                                                placeholder="Seleccione Tipo de Placa*" optionLabel="nombre"
                                                [showClear]="true" pTooltip="Tipo de Placa"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Placa*"
                                                formControlName="desc_placaUnidad" style="text-transform:uppercase;"
                                                pTooltip="Placa">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstEstado" autoWidth="false"
                                                [style]="{'width':'100%'}" formControlName="clave_Estado"
                                                optionValue="clave_Estado" placeholder="Seleccione Estado de Tarjeta*"
                                                optionLabel="nombre" [showClear]="true" pTooltip="Estado de Tarjeta"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Tarjeta de Circulación*"
                                                formControlName="num_tarjetaCirculacion"
                                                style="text-transform:uppercase;" pTooltip="Tarjeta de Circulación">
                                            <hr class="my-0">
                                        </div>
                                        <div class="col-sm-5">
                                            <input type="date" class="form-control sinborde" pTooltip="Vigente Hasta"
                                                tooltipPosition="top" formControlName="fec_vigencia_tarjeta"
                                                style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstVerificaciones" autoWidth="false"
                                                [style]="{'width':'100%'}" formControlName="clave_verificacion"
                                                optionValue="clave_verificacion" placeholder="Verificación Estado"
                                                optionLabel="nombre" [showClear]="true" pTooltip="Verificacion Estado"
                                                tooltipPosition="left"
                                                (ngModelChange)="validarCambioEstado()"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Inicio*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Inicio de Estado"
                                                tooltipPosition="top" formControlName="fec_inicio_estatal"
                                                style="text-transform:uppercase;">
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Fin*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Fin de Estado" tooltipPosition="top"
                                                formControlName="fec_fin_estatal" style="text-transform:uppercase;">
                                        </div>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstVerificacionHumo" autoWidth="false"
                                                [style]="{'width':'100%'}" formControlName="clv_humo" optionValue="id"
                                                placeholder="Verificación Humo" optionLabel="nombre" [showClear]="true"
                                                pTooltip="Verificacion Humo" (ngModelChange)="validarCambioHumo()"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Inicio*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Inicio de Humo"
                                                tooltipPosition="top" formControlName="fec_inicio_humo"
                                                style="text-transform:uppercase;">
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Fin*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Fin de Humo" tooltipPosition="top"
                                                formControlName="fec_fin_humo" style="text-transform:uppercase;">
                                        </div>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstVerificacionFisico" autoWidth="false"
                                                [style]="{'width':'100%'}" formControlName="clv_fisico" optionValue="id"
                                                placeholder="Verificación Fisico-Mecanica" optionLabel="nombre"
                                                [showClear]="true" pTooltip="Verificacion Fisico-Mecanica"
                                                tooltipPosition="left"
                                                (ngModelChange)="validarCambioFisico()"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Inicio*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Inicio de Fisico"
                                                tooltipPosition="top" formControlName="fec_inicio_mecanica"
                                                style="text-transform:uppercase;">
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Fin*</label>
                                            <input type="date" class="form-control sinborde"
                                                pTooltip="Seleccione o teclee fecha Fin de Fisico" tooltipPosition="top"
                                                formControlName="fec_fin_mecanica" style="text-transform:uppercase;">
                                        </div>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2" *ngIf="formularioUnidad.value.clave_tipoUnidad==2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1">
                                            <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <p-dropdown [options]="lstproveedor" optionLabel="nombre"
                                                formControlName="clave_proveedor" optionValue="clave_proveedor"
                                                (onChange)="seleccionarProveedor()" autoWidth="false"
                                                [style]="{'width':'100%'}" placeholder="Seleccione Proveedor*"
                                                [showClear]="true" pTooltip="Proveedor"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Inicio*</label>
                                            <input type="date" class="form-control sinborde"
                                                formControlName="fecha_inicioRenta"
                                                pTooltip="Seleccione o teclee fecha Inicio de Renta"
                                                tooltipPosition="top" style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-around">
                                            <label class="label-header">Fecha Fin</label>
                                            <input type="date" class="form-control sinborde"
                                                formControlName="fecha_finRenta"
                                                pTooltip="Seleccione o teclee fecha fin de Renta" tooltipPosition="top"
                                                style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title-light">Generales y Caracteristicas <i class="pi pi-check-square"
                                    style="size: 2rem;"></i></span>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="VIN"
                                            formControlName="vin" style="text-transform:uppercase;" pTooltip="VIN">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Marca*"
                                            formControlName="desc_marca" style="text-transform:uppercase;"
                                            pTooltip="Marca">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Modelo*"
                                            formControlName="desc_modelo" style="text-transform:uppercase;"
                                            pTooltip="Modelo">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Año*"
                                            formControlName="num_anio" style="text-transform:uppercase;" pTooltip="Año">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Color*"
                                            formControlName="desc_color" style="text-transform:uppercase;"
                                            pTooltip="Color">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Poliza de Seguro*"
                                            formControlName="num_polizaSeguro" style="text-transform:uppercase;"
                                            pTooltip="Poliza de Seguro">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex justify-content-around">
                                        <label class="label-header">Fecha Inicio*</label>
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Inicio de Poliza" tooltipPosition="top"
                                            formControlName="fecha_inicioPoliza" style="text-transform:uppercase;">
                                    </div>
                                    <div class="col-sm-6 d-flex justify-content-around">
                                        <label class="label-header">Fecha Fin*</label>
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha Fin de Poliza" tooltipPosition="top"
                                            formControlName="fecha_finPoliza" style="text-transform:uppercase;">
                                    </div>

                                    <hr class="my-0">
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Aseguradora"
                                            formControlName="desc_aseguradora" style="text-transform:uppercase;"
                                            pTooltip="Aseguradora">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Tipo de Caja"
                                            formControlName="desc_tipoCaja" style="text-transform:uppercase;"
                                            pTooltip="Tipo de Caja">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <input type="text" class="form-control sinborde" placeholder="Numero de Caja"
                                            formControlName="num_caja" style="text-transform:uppercase;"
                                            pTooltip="Numero de Caja">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                                            placeholder="Seleccione Ciudad Destino" optionLabel="nombre"
                                            [showClear]="true" formControlName="clave_ciudadDestino" optionValue="id"
                                            tooltip="Ciudad Destino"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCiudades" autoWidth="false" [style]="{'width':'100%'}"
                                            placeholder="Seleccione Ciudad Origen" optionLabel="nombre"
                                            [showClear]="true" formControlName="clave_ciudadOrigen" optionValue="id"
                                            tooltip="Ciudad Origen" tooltipPosition="top"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstCombustible" autoWidth="false"
                                            [style]="{'width':'100%'}" placeholder="Seleccione Tipo de Combustible"
                                            optionLabel="nombre" [showClear]="true"
                                            formControlName="clave_tipo_combustible" optionValue="id"
                                            tooltip="Tipo de Combustible"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="title-light">Medidas de Seguridad <i class="pi pi-lock"
                                    style="size: 2rem;"></i></span>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="Numero GPS" class="form-control sinborde"
                                                formControlName="num_gps" style="text-transform:uppercase;"
                                                pTooltip="Numero GPS">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="Nombre GPS" class="form-control sinborde"
                                                formControlName="nombre_gps" style="text-transform:uppercase;"
                                                pTooltip="Nombre GPS">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Camara" class="form-control sinborde"
                                                formControlName="num_camara" style="text-transform:uppercase;"
                                                pTooltip="#Camara">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Boton Panico" class="form-control sinborde"
                                                formControlName="num_boton_panico" style="text-transform:uppercase;"
                                                pTooltip="#Boton Panico">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Numero Inmovilizador"
                                                class="form-control sinborde" formControlName="num_inmobilizador"
                                                style="text-transform:uppercase;" pTooltip="#Numero Inmovilizador">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-1"><span class="input-group-addon"> <i
                                                    class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                            </span></div>
                                        <div class="col-sm-11">
                                            <input type="text" placeholder="#Rendimiento Objetivo"
                                                class="form-control sinborde" formControlName="num_rendimiento_objetivo"
                                                style="text-transform:uppercase;" pTooltip="#Rendimiento Objetivo">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-1">
                                <div class="col-4">
                                    <p *ngIf="!formularioUnidad.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1.5rem;">Es Necesario
                                            Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="formularioUnidad.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1.5rem;">Informaci&oacute;n
                                            Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="submit" class="btn btn-success form-control"
                                        [disabled]="!formularioUnidad.valid">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarVentanaOperacion()"></button>
            </ng-template>
        </p-dialog>
        <!--ASIGNACION DE ESTATUS-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEstatus"
            [style]="{width: '60vw','height':'530px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModal()">
            <ng-template pTemplate="header">
                Administración de Estatus y Sub-Estatus
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <form [formGroup]="dataForm" (ngSubmit)="guardarInformacionEstatus()">
                        <span class="title-light mt-3">Información de Estatus de Unidad {{num_economico_general}}<i
                                class="pi pi-cog" style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-2 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-10">
                                        <p-dropdown [options]="lstEstatusUnidad" optionLabel="desc_estatus"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="clv_estatus" optionValue="clv_estatus"
                                            placeholder="Seleccione Estatus*" tooltip="Estatus"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-2 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                style="font-size: 1.5rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-10">
                                        <p-dropdown [options]="lstSubEstatusUnidad" optionLabel="desc_sub_estatus"
                                            autoWidth="false" [style]="{'width':'100%'}" [showClear]="true"
                                            formControlName="clv_sub_estatus" optionValue="clv_sub_estatus"
                                            placeholder="Seleccione Sub-Estatus*" tooltip="Sub-Estatus"></p-dropdown>
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!dataForm.valid || habilitar_edicion">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100" (click)="habilitarEdicion();"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModal();"></button>
            </ng-template>
        </p-dialog>
        <!--REPORTES-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
            [style]="{width: '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalReportes()">
            <ng-template pTemplate="header">
                Reportes
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Todas las Unidades</p>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarExcel()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Unidades: Dias Prestados y Total Servicios (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMes"
                                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anio" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarExcelTDO()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Unidades: Días Prestados y Total Servicios por Rango de Fechas</p>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                              tooltipPosition="top" [(ngModel)]="fecha_rpt_dias">
                            <hr class="my-0">
                          </div>
                          <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                              tooltipPosition="top" [(ngModel)]="fecha_fin_rpt_dias">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarRptDiasFecha()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Unidades Detallado: Total Servicios por Rango de Fechas</p>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                              tooltipPosition="top" [(ngModel)]="fecha_rpt_detallado">
                            <hr class="my-0">
                          </div>
                          <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                              tooltipPosition="top" [(ngModel)]="fecha_fin_rpt_detallado">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="  exportarRptTasaOcupacionDetalladoFecha()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Histórico de Estatus de Unidades (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesHistorico"
                                tooltip="Es el Mes a consultar y/o Todos" tooltipPosition="left"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anio_historico" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarExcelHistorico()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Histórico de Estatus de Unidades  Por Rango de Fechas
                            </p>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                              tooltipPosition="top" [(ngModel)]="fecha_rpt_historico">
                            <hr class="my-0">
                          </div>
                          <div class="col-md-3">
                            <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                              tooltipPosition="top" [(ngModel)]="fecha_fin_rpt_historico">
                            <hr class="my-0">
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarRptHistoricoFechas()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Contabilizar Inactividad de Unidad (Mes y Año)</p>
                        </div>
                        <div class="col-md-3">
                            <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesHistoricoIna"
                                tooltip="Es el Mes a consultar y/o Todos" tooltipPosition="left"></p-dropdown>
                        </div>
                        <div class="col-md-3">
                            <p-calendar [(ngModel)]="anio_historico_ina" [showIcon]="true" view="year" dateFormat="yy"
                                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarExcelHistoricoIna()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="style-two">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="texto-pesado">Control General De Unidades Completo</p>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-outline-success" style="width: 100%;"
                                (click)="exportarExcelGeneralUnidades()">
                                Exportar <i class="pi pi-file-export"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalReportes();"></button>
            </ng-template>
        </p-dialog>
        <!--Herramientas de Recursos-->
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbHerramientas"
            [style]="{width: '60vw','height':'600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
            (onHide)="cancelarModalHerramientas()">
            <ng-template pTemplate="header">
                Herramientas de Recursos
            </ng-template>
            <ng-template pTemplate="content">
                <div class="mt-5">
                    <form [formGroup]="herramientasForm" (ngSubmit)="guardarInformacionHerramientas()">
                        <span class="title-light mt-3">Información de la Unidad<i class="pi pi-cog"
                                style="size: 2rem;"></i>
                        </span>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-truck color-icon"
                                                style="font-size: 1.5rem"></i><p class="ml-1 color-icon d-inline">Economico</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control sinborde" formControlName="num_economico"
                                            style="text-transform:uppercase; font-weight: bold;" pTooltip="Economico">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-credit-card color-icon"
                                                style="font-size: 1.5rem"></i><p class="ml-1 color-icon d-inline">Fijo</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control sinborde" placeholder="Tag Fijo*"
                                            formControlName="num_tag_fijo" style="text-transform:uppercase;"
                                            pTooltip="Fijo">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-12 d-flex align-items-center">
                                        <small *ngIf="herramientasForm.controls['num_tag_fijo'].invalid"
                                        style="color: red;">
                                            *Obligatorio 4 digitos. Solo Numeros.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-credit-card color-icon"
                                                style="font-size: 1.5rem"></i><p class="ml-1 color-icon d-inline">Emergente</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control sinborde" placeholder="Tag Emergente"
                                            formControlName="num_tag_emergente" style="text-transform:uppercase;"
                                            pTooltip="Emergente">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-12 d-flex align-items-center">
                                        <small *ngIf="herramientasForm.controls['num_tag_emergente'].invalid"
                                        style="color: red;">
                                            *Obligatorio 4 digitos. Solo Numeros.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-6 d-flex align-items-center">
                                        <span class="input-group-addon"> <i class="pi pi-credit-card color-icon"
                                                style="font-size: 1.5rem"></i><p class="ml-1 color-icon d-inline">Sodexo</p>
                                        </span>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control sinborde" placeholder="Tarjeta Sodexo*"
                                            formControlName="num_tarjeta_sodexo" style="text-transform:uppercase;"
                                            pTooltip="Sodexo">
                                        <hr class="my-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="row g-0">
                                    <div class="col-sm-12 d-flex align-items-center">
                                        <small *ngIf="herramientasForm.controls['num_tarjeta_sodexo'].invalid"
                                        style="color: red;">
                                            *Obligatorio 4 digitos. Solo Numeros.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-2">
                                <button type="submit" class="btn btn-success w-100"
                                    [disabled]="!herramientasForm.valid || habilitar_edicion_herramientas">
                                    <span class="text-white">Aceptar</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-md-3">
                            <button class="btn btn-warning w-100" (click)="habilitarEdicionHerramientas();"
                                pTooltip="Habilitar campos para actualizar Información.">
                                <span>Habilitar Edición</span>
                                <i class="pi pi-eraser"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="cancelarModalHerramientas();"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
