import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Table } from 'primeng/table';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { ICiudad } from '../models/ciudad';
import ICredencialUsuario from '../models/credencial';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IUsuarios } from '../models/usuarios';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { UsuariosService } from '../services/usuarios.service';
import { IUserDispMovil } from '../models/usersDispMovil';
import { UsuariosDispMovilService } from '../services/choferDispMovil.service';
import { IEstatus } from '../models/estatus';
import { UnidadComponent } from '../unidad/unidad.component';

@Component({
  selector: 'app-userdisp',
  templateUrl: './userdisp.component.html',
  styleUrls: ['./userdisp.component.scss']
})
export class UserdispComponent {
  @ViewChild('dt') dt!: Table;
  user: ICredencialUsuario = {};
  lstUsuarios: IUserDispMovil[] = [];
  lstUsDisponibles: IUserDispMovil[] = [];
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVA'
  },
  {
    id: 0,
    nombre: 'INACTIVA'
  }];
  num_opcion: number = 0;
  dspNuevoUsuario: boolean = false;
  succesMssg = {
    status: false,
    data: {
        username: "",
        pass: ""
    }
  }
  
  userDispForm: FormGroup = new FormGroup({
    cod_chofer: new FormControl(0, [Validators.required]),
    nombre_completo: new FormControl({ value: '', disabled: true }),
    nom_usuario: new FormControl({value:'',disabled:true}),
    pass: new FormControl({value:'',disabled:true})
  });

  mssg = {
    status: false,
    message: ''
  }
  texto_btn: string = '';
  texto_operacion: string = '';
  loading: boolean = false;
  resultado:IResultadoGeneral={clv_estatus : 0, messageDetails: ''};

  constructor(private auth: AuthService, 
    private usersDispMovService: UsuariosDispMovilService,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.cargarInformacionUsuariosDispositivos();
  }

  cancelarMostrarAgregarUsuario(){
    this.dspNuevoUsuario = false;
    this.reiniciar();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  cargarInformacionUsuariosDispositivos() {
    this.usersDispMovService.getListadoChoferesDispMovil().subscribe((resp) => {
      this.lstUsuarios = resp;
      if (this.lstUsuarios.length == 0) {
        setTimeout(() => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b>-No existen usuarios registrados');
        }, 1000);
      }
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Usuarios');
    });
  }

  enableDisable(): boolean
  {
    if(!this.loading && this.userDispForm.valid)
      return false;
    else 
      return true;
  }

  cargarInformacionUsuarioSinUsuario() {
    this.lstUsDisponibles = [];
    this.loading = true;
    this.usersDispMovService.getListadoChoferesSinUsuario().subscribe((resp) => {
      this.lstUsDisponibles = resp;
      if (this.lstUsDisponibles.length == 0) {
        setTimeout(() => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existen Choferes nuevos para registrar');
        }, 1000);
      }
      else
        this.loading = false;
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar el listado de Choferes para registrar');
    });
  }

  mostrarNuevoUsuario(num_opcion: number,data?: IUserDispMovil) {
    this.loading = true;
    this.dspNuevoUsuario = true;
    this.num_opcion = num_opcion;
    if(num_opcion == 1)
    { 
      if(data!= undefined)
      {
        this.texto_operacion = 'Usuario de Dispositivo Movil';
        this.mostrarUsuario(data);
      }
      else
      {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> Error al obtener usuario, Favor de contactar con administrador');
      }
    }
    else
    {
      this.texto_operacion = 'Nuevo Usuario para Dispositivo Movil';
      this.cargarInformacionUsuarioSinUsuario();    
    }
  }

  mostrarUsuario(data: IUserDispMovil) {
    this.userDispForm.get('nombre_completo')?.setValue(data.nombre_completo);
    this.userDispForm.get('cod_chofer')?.setValue(data.cod_chofer);

    this.cargarChofer(data.cod_chofer);    
  }

  guardarInformacion()
  {
    if(this.userDispForm.get('cod_chofer')?.value != 0 || typeof(this.userDispForm.get('cod_chofer')?.value) != undefined)
    {
      if(this.num_opcion == 0)
      {
        let nom = this.lstUsDisponibles.find(x => x.cod_chofer == this.userDispForm.get('cod_chofer')?.value)?.nombre_completo
        this.userDispForm.get('nombre_completo')?.setValue(typeof(nom) == undefined ? '':nom);

        this.addUserDispMovil(this.userDispForm.get('cod_chofer')?.value);
      }
      else
      {
        this.editarInformacion(this.userDispForm.get('cod_chofer')?.value);
      }
    } 
    else{
      this.mostrarDialogoInformativoSinInformacion('Favor de Seleccionar un Chofer para continuar.')
    }  
  }

  addUserDispMovil(cod_chofer: number) {
    this.loading = true;
    this.usersDispMovService.addUsrChofer(cod_chofer,this.user.cod  != undefined ? this.user.cod.toString():'0999').subscribe( resp => { 
      setTimeout(() => {
        this.num_opcion = 1;
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: 'Se registro Correctamente el usuario.' });
        this.cargarChofer(cod_chofer);
        this.cargarInformacionUsuariosDispositivos();
      }, 800);      
    },(error) =>{
      console.log(error);
      this.mostrarDialogoInformativoSinInformacion(error.error.message);
      this.cancelarMostrarAgregarUsuario();
    });
    this.loading = false;
  }

  cargarChofer(cod_chofer: number)
  {
    this.loading = true;
    this.usersDispMovService.getUsrChofer(cod_chofer).subscribe( resp => { 
      setTimeout(() => {
        this.userDispForm.get('nom_usuario')?.setValue(resp.data.username);
        this.userDispForm.get('pass')?.setValue(resp.data.pass); 
        // this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: '' });
        this.loading = false;
      }, 800);   
    },(error) =>{
      console.log(error);
      this.mostrarDialogoInformativoSinInformacion(error.error.message);
      this.cancelarMostrarAgregarUsuario();
      this.loading = false;
    });
    
  }

  editarInformacion(cod_chofer: number) {
    this.loading = true;
    this.usersDispMovService.editUserChofer(cod_chofer,this.user.cod  != undefined ? this.user.cod.toString():'0999').subscribe( resp => { 
      setTimeout(() => {
        this.loading = false;
        this.userDispForm.get('nom_usuario')?.setValue(resp.username);
        this.cargarInformacionUsuariosDispositivos();
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Confirmación', detail: resp.message });
      }, 800);   
    },(error) =>{
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion(error.error.message);
      this.cancelarMostrarAgregarUsuario();
    });
  }

  reiniciar(){
    this.texto_operacion='';
    this.loading=false;
    this.num_opcion = 0;
    this.userDispForm.reset();
  }

  editarEstatus(data: IUserDispMovil) {
    let estatus_original = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea ' + texto_desactivar + ' el Usuario del chofer <b>' + data.nombre_completo + '</b> ?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.cambiarEstatusUserDispMovil(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo = estatus_original;
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  cambiarEstatusUserDispMovil(data:IUserDispMovil){
    this.usersDispMovService.chageStatusDispMovil(data.cod_chofer,this.user.cod  != undefined ? this.user.cod.toString():'0999').subscribe((resp) => {
      this.resultado = resp;
      setTimeout(() => {
        this.cargarInformacionUsuariosDispositivos();
        if(resp.clv_estatus <=0)
        {
          this.messageService.add({ key: 'msj',severity: 'warn', summary: 'Cambio de Estatus', detail: resp.messageDetails });
        }
        else {
          this.messageService.add({ key: 'msj',severity: 'success', summary: 'Cambio de Estatus Exitoso', detail: resp.messageDetails });
        }
      }, 800);
    }, (error) => {
      this.messageService.add({ key: 'msj',severity: 'error', summary: 'Error en <Cambiar Estatus>', detail: 'Contacte al Administrador del Sitio' });
    });
  }


  // exportarExcel() {
  //   if (!this.lsUsuarios.length || typeof this.lsUsuarios === 'undefined') {
  //     this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
  //   } else {
  //     import("xlsx").then(xlsx => {
  //       const worksheet = xlsx.utils.json_to_sheet(this.lsUsuarios);
  //       const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //       const excelBuffer: any = xlsx.write(workbook, {
  //         bookType: "xlsx",
  //         type: "array"
  //       });
  //       this.saveAsExcelFile(excelBuffer, "usuarios");
  //     });
  //   }
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   //var FileSaver = require('file-saver');
  //   var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  //   var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
  //   var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
  //   var dateFormat = getYear + "-" + getMonth + "-" + getDay;
  //   let EXCEL_TYPE =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   let EXCEL_EXTENSION = ".xlsx";
  //   const data: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);

  // }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  exportarRptUsuariosDispositivosMoviles(){
    let rep: any = []
    this.usersDispMovService.getRptUsuariosDispositivosMoviles().subscribe((resp) => {
      rep = resp
      if (rep.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(rep);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "ReporteUsuariosDispositivosMoviles");
        });
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);

  }
}
